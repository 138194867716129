function Efficiency(props: any) {
  let volitality = props.volitality;
  let ratios = props.ratios;
  let factSheetTwoYears = props.factSheetTwoYears;
  let factSheetFiveYears = props.factSheetFiveYears;
  let type = props.type;
  let factSheetChinaPeriod = props.factSheetChinaPeriod;
  let periods = props.periods;
  return (
    <div className="col-12 fact-sheet-sub-container">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats-1">
        <tbody>
          <tr className="sticky-top table-header">
            <th>Efficiency</th>
            {periods.map((period: string, index: string) => (
              <th>{period}</th>
            ))}
          </tr>
          <tr className="table-body fact-sheet-row ">
            <td className="fact-sheet-col-grey">Positive Months (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.volitality[type].positiveAnnualVolitality.numOfMonths * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.volitality[type].positiveAnnualVolitality.numOfMonths * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.volitality[type].positiveAnnualVolitality.numOfMonths * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(volitality.positiveAnnualVolitality.numOfMonths * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
          <tr className="table-body fact-sheet-row">
            <td className="fact-sheet-col-grey">Negative Months (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.volitality[type].negativeAnnualVolitality.numOfMonths * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.volitality[type].negativeAnnualVolitality.numOfMonths * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.volitality[type].negativeAnnualVolitality.numOfMonths * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(volitality.negativeAnnualVolitality.numOfMonths * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>

          <tr className="table-body fact-sheet-row ">
            <td className="fact-sheet-col-grey">Average Monthly Gain (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.volitality[type].positiveAnnualVolitality.mean * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.volitality[type].positiveAnnualVolitality.mean * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.volitality[type].positiveAnnualVolitality.mean * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(volitality.positiveAnnualVolitality.mean * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
          <tr className="table-body fact-sheet-row ">
            <td className="fact-sheet-col-grey">Average Monthly Loss (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.volitality[type].negativeAnnualVolitality.mean * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.volitality[type].negativeAnnualVolitality.mean * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.volitality[type].negativeAnnualVolitality.mean * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(volitality.negativeAnnualVolitality.mean * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
          <tr className="table-body fact-sheet-row">
            <td className="fact-sheet-col-grey">Profit/ Loss Ratio</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.ratios[type].plRatio * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.ratios[type].plRatio * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.ratios[type].plRatio * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(ratios.plRatio * 100) / 100).toFixed(2)}</td>
          </tr>
          <tr className="table-body fact-sheet-row ">
            <td className="fact-sheet-col-grey">Gain/ Loss Ratio</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.ratios[type].glRatio * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.ratios[type].glRatio * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.ratios[type].glRatio * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(ratios.glRatio * 100) / 100).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default Efficiency;
