import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

const LineChart = ({ cumulativeReturnsHashTable, title }: any) => {
  // Helper function to extract data from the nested structure
  let unnecessary = ["cumulative", "min", "max", "cumulativeSwitch"];
  const extractData = (obj: any) => {
    return Object.keys(obj).reduce((acc: any, key: any) => {
      if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
        acc[key] = extractData(obj[key]);
      } else {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  };
  let [legatruuIndex, setLegatruuIndex] = useState("LEGATRUU Index");
  let [emustruuIndex, setEmustruuIndex] = useState("EMUSTRUU Index");
  let [beuctruuIndex, setBeuctruuIndex] = useState("BEUCTRUU Index");
  let [beuytruuIndex, setBeuytruuIndex] = useState("BEUYTRUU Index");
  // let [bebgtruu, setBebgtruu] = useState("BEBGTRUU Index");
  let [PIMGLBA, setPIMGLBA] = useState("PIMGLBA ID Equity");
  let [FIDITBD, setFIDITBD] = useState("FIDITBD LX Equity");

  let [triadaIndex, setTriadaIndex] = useState("triada");

  // Extracting data for all indices
  const legatruuData = extractData(cumulativeReturnsHashTable[legatruuIndex] || {});
  const emustruuData = extractData(cumulativeReturnsHashTable[emustruuIndex] || {});
  const beuctruuData = extractData(cumulativeReturnsHashTable[beuctruuIndex] || {});
  // const beuytruuData = extractData(cumulativeReturnsHashTable[beuytruuIndex] || {});

  const PIMGLBAData = extractData(cumulativeReturnsHashTable[PIMGLBA] || {});
  const FIDITBDData = extractData(cumulativeReturnsHashTable[FIDITBD] || {});

  const triadaData = extractData(cumulativeReturnsHashTable[triadaIndex] || {});

  // Prepare datasets for Chart.js
  const datasets = [
    {
      label: "Triada Fund",
      data: Object.values(triadaData),
      fill: false,
      borderColor: "#76bf75",
      tension: 0.4,
    },
    {
      label: "BBG Global Aggregate",
      data: Object.values(legatruuData),
      fill: false,
      borderColor: "#1f77b4",
      tension: 0.4,
    },
    {
      label: "BBG EM Aggregate",
      data: Object.values(emustruuData),
      fill: false,
      borderColor: "#2459E4",
      tension: 0.4,
    },
    {
      label: "BBG EM Asia",
      data: Object.values(beuctruuData),
      fill: false,
      borderColor: "#516DB9",
      tension: 0.4,
    },
    // {
    //   label: "BBG EM Asia HY",
    //   data: Object.values(beuytruuData),
    //   fill: false,
    //   borderColor: "#7B9CF1",
    //   tension: 0.4,
    // },
    {
      label: "Pimco Global Bond",
      data: Object.values(PIMGLBAData),
      fill: false,
      borderColor: "#e09b04",
      tension: 0.4,
    },
    {
      label: "Fidelity Global Bond",
      data: Object.values(FIDITBDData),
      fill: false,
      borderColor: "#ff7f0e",
      tension: 0.4,
    },

    // {
    //   label: "BBG EM HY",
    //   data: Object.values(bebgtruuData),
    //   fill: false,
    //   borderColor: "rgba(153,102,255,1)",
    //   tension: 0.4,
    // },
  ];

  // Extract common labels (assuming all datasets share the same date keys)
  const labels = Object.keys(extractData(cumulativeReturnsHashTable["triada"])); // Using triadaData for labels, assuming similarity

  // Chart.js data structure
  const data = {
    labels: labels,
    datasets: datasets,
  };

  // Chart.js options
  const options: any = {
    // responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Fund Data Over Time (${title})`,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
        // grid: {
        //   // Changed from gridLines to grid
        //   color: "rgba(0, 0, 0, 0)", // Transparent grid lines
        //   borderColor: "rgba(0, 0, 0, 0.1)", // Optional: set border color if needed
        // },
      },
      y: {
        title: {
          display: true,
          text: "Performance %",
        },
        // grid: {
        //   // Changed from gridLines to grid
        //   color: "rgba(0, 0, 0, 0)", // Transparent grid lines
        //   borderColor: "rgba(0, 0, 0, 0.1)", // Optional: set border color if needed
        // },
      },
    },
  };

  return (
    <div className="overflow-container">
      <div className="table-responsive">
        <div>
          {" "}
          {/* <div className=" log-container">
          <button className={`btn btn-trades btn-edit-logs ${legatruuIndex == "NA" ? "fact-sheet-selected" : ""}`} onClick={(event: any) => setLegatruuIndex(legatruuIndex == "NA" ? "LEGATRUU Index" : "NA")}>
            BBG Global Aggregate
          </button>
          <button className={`btn btn-trades btn-edit-logs ${emustruuIndex == "NA"?"fact-sheet-selected":""}`} onClick={(event: any) => setEmustruuIndex(emustruuIndex == "NA" ? "EMUSTRUU Index" : "NA")}>
            BBG EM Aggregate
          </button>
          <button className={`btn btn-trades btn-edit-logs ${lg30truuIndex == "NA"?"fact-sheet-selected":""}`} onClick={(event: any) => setLg30truuIndex(lg30truuIndex == "NA" ? "LG30TRUU Index" : "NA")}>
            BBG EM Asia
          </button>
          <button className={`btn btn-trades btn-edit-logs ${beuctruuIndex == "NA"?"fact-sheet-selected":""}`} onClick={(event: any) => setBeuctruuIndex(beuctruuIndex == "NA" ? "BEUCTRUU Index" : "NA")}>
            BBG EM Asia HY
          </button>
          <button className={`btn btn-trades btn-edit-logs ${beuytruuIndex == "NA"?"fact-sheet-selected":""}`} onClick={(event: any) => setBeuytruuIndex(beuytruuIndex == "NA" ? "BEUYTRUU Index" : "NA")}>
            BBG Global HY
          </button>
          <button className={`btn btn-trades btn-edit-logs ${triadaIndex == "NA"?"fact-sheet-selected":""}`} onClick={(event: any) => setTriadaIndex(triadaIndex == "NA" ? "triada" : "NA")}>
            Triada
          </button>
        </div> */}
        </div>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default LineChart;
