import { CloseIcon } from "../../common/functions";

function ChangesView(props: any) {
  let changesLogs = props.changesLogs || [];


  return (
    <div className="position-container logs-changes-container">
      <div onClick={() => props.setChangesDisplay("none")} className="close-icon-container-edit-position close-icon-position-override">
        <CloseIcon/>
      </div>
      {changesLogs.map((change: any, index: any) => (
        <p key={index}>
          {change}
          <br />
        </p>
      ))}
    </div>
  );
}

export default ChangesView;
