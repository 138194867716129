import { useState, useEffect } from "react";
import Loader from "../../common/loader";
import NavBar from "../../common/navbar";
import { handleAuth, postRequestOptions, getRequestOptions, baseUrl } from "../../common/cookie";
import axios from "axios";

function UpdatePreviousPrices() {
  let [error, setError] = useState<any>({});
  let [errorText, setErrorText] = useState<any>("");

  let url: any = baseUrl + `previous-collections`;
  let [dates, setCollectionDates]: any = useState([]);
  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setCollectionDates(data);
      });
  }, []);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setRequestStatus(true);

    // handle file processing here
    const form: any = document.getElementById("update-previous-prices");
    const formData = new FormData(form);

    try {
      setError({});
      setErrorText("");
      let url = baseUrl + "update-previous-prices";
      let action: any = await axios.post(url, formData, postRequestOptions);
      handleAuth(action);
      if (action.data.error) {
        if (typeof action.data.error == "string") {
          setErrorText(action.data.error);
        } else {
          setError(action.data.error);
        }

        setRequestStatus(false);
      } else {
        setRequestStatus(false);

        window.alert("Success!");
      }
    } catch (error) {
      console.error("Error:", error);

      setRequestStatus(false);
    }
  }

  const [request, setRequestStatus] = useState(false);

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div className="title">
        <h1>Update Previous Prices</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form onSubmit={(event) => handleSubmit(event)} encType="multipart/form-data" id="update-previous-prices">
          <div>
            <h3>Select a collection date</h3>
          </div>
          <select className="select-collection-date" name="collectionDate">
            {dates.map((date: any, index: any) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select>
          <div>
            <h3>Select pricing sheet type</h3>
          </div>
          <select className="select-collection-date" name="collectionType">
            <option>MUFG</option>
            <option>Bloomberg</option>
          </select>
          <div>
            <h3>Input Bloomberg/MUFG Pricing Excel csv/xlsx file</h3>
          </div>
          <div className="drop-container">
            <input multiple={true} className="drop-box-input" placeholder="Upload or drop file" type="file" name="file" accept=".xlsx, .csv" required />
          </div>
          <button type="submit" className="btn upload-btn">
            Upload
          </button>
        </form>
      </div>
      <h4 className="error-text">
        {errorText
          ? errorText
          : Object.keys(error).map((issue: any, index: any) => {
              return issue.toString().includes("Bid") || issue.toString().includes("Ask") ? (
                <p>{error[issue].message}</p>
              ) : (
                <p>
                  {issue} with location {error[issue].location} did not update. Notional = {error[issue].notional}{" "}
                </p>
              );
            })}
      </h4>
    </div>
  );
}

export default UpdatePreviousPrices;
