import { useState, useEffect } from "react";
import Loader from "../../common/loader";
import NavBar from "../../common/navbar";
import { handleAuth, postRequestOptions, getRequestOptions, baseUrl } from "../../common/cookie";
import axios from "axios";

function FXPosition() {
  let [error, setError] = useState<any>(null);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setRequestStatus(true);

    // handle file processing here
    const form: any = document.getElementById("fx-position");
    const formData = new FormData(form);
    try {
      setError(null);
      let url = baseUrl + "fx-add-position";
      let action: any = await axios.post(url, formData, postRequestOptions);
      handleAuth(action);
      if (action.data.error) {
        setError(action.data.error);

        setRequestStatus(false);
      } else {
        setRequestStatus(false);
        window.alert("Success!");
      }
    } catch (error) {
      console.error("Error:", error);

      setRequestStatus(false);
    }
  }

  const [request, setRequestStatus] = useState(false);

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div className="title">
        <h1>Add FX Position</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form onSubmit={(event) => handleSubmit(event)} encType="multipart/form-data" id="fx-position">
          <div>
            <h3>Input FX Name (Same As BBG)</h3>
          </div>
          <input type="text" className="formTextInput form-number-input" name="Code"></input>

          <div>
            <h3>Input FX Location</h3>
          </div>
          <input type="text" name="Location" className="formTextInput form-number-input"></input>
          <div>
            <h3>Input FX Amount</h3>
          </div>
          <input type="number" className="formTextInput form-number-input" name="Notional Amount"></input>
          <div>
            <h3>Date (Caution)</h3>
          </div>
          <input type="date" className="formTextInput form-number-input" name="date"></input>

          <button type="submit" className="btn upload-btn">
            Add
          </button>
        </form>
      </div>
      <h4 className="error-text">{error}</h4>
    </div>
  );
}

export default FXPosition;
