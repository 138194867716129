import { useState } from "react";
import Loader from "../../common/loader";
import NavBar from "../../common/navbar";
import { baseUrl, handleAuth, postRequestOptions } from "../../common/cookie";
import axios from "axios";

function UpdatePrices() {
  let [error, setError] = useState<any>({});
  let [errorText, setErrorText] = useState<any>("");

  async function handleSubmit(event: any) {
    event.preventDefault();
    setRequestStatus(true);

    // handle file processing here

    try {
      setError({});
      setErrorText("");
      const form: any = document.getElementById("update-prices");
      const formData = new FormData(form);
      let url = baseUrl + "update-prices";
      let action: any = await axios.post(url, formData, postRequestOptions);

      handleAuth(action);
      if (action.data.error) {
        if (typeof action.data.error == "string") {
          setErrorText(action.data.error);
        } else {
          setError(action.data.error);
        }

        setRequestStatus(false);
      } else {
        setRequestStatus(false);
        window.alert("Success!");
      }
      console.log(action.data.error);
    } catch (error) {
      console.error("Error:", error);

      setRequestStatus(false);
    }
  }

  const [request, setRequestStatus] = useState(false);

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div className="title">
        <h1>Update Prices</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form onSubmit={(event) => handleSubmit(event)} encType="multipart/form-data" id="update-prices">
          <div>
            <h3>Input Bloomberg Pricing Excel csv/xlsx file</h3>
          </div>

          <div className="drop-container">
            <input multiple={true} className="drop-box-input" placeholder="Upload or drop file" type="file" name="pricing" accept=".xlsx, .csv" required />
          </div>
          <button type="submit" className="btn upload-btn">
            Upload
          </button>
        </form>
      </div>
      <h4 className="error-text">
        {errorText
          ? errorText
          : Object.keys(error).map((issue: any, index: any) => {
              return issue.toString().includes("Bid") || issue.toString().includes("Ask") ? (
                <p>{error[issue].message}</p>
              ) : (
                <p>
                  {issue} with location {error[issue].location} did not update. Notional = {error[issue].notional}{" "}
                </p>
              );
            })}
      </h4>
    </div>
  );
}

export default UpdatePrices;
