import { useState } from "react";
import Loader from "../../common/loader";
import NavBar from "../../common/navbar";
import { handleAuth, postRequestOptions, baseUrl } from "../../common/cookie";
import axios from "axios";


function UploadTrades() {
  
  let [error, setError] = useState<any>(null);
  async function handleSubmit(event: any) {
    event.preventDefault();
    setRequestStatus(true);
    try {
      setError(null);
      let url = baseUrl + "upload-trades";

      const form: any = document.getElementById("upload-trades");
      const formData = new FormData(form);

      let action: any = await axios.post(url, formData, postRequestOptions);

      handleAuth(action);
      if (action.data.error) {
        setError(action.data.error);

        setRequestStatus(false);
      } else {
        setRequestStatus(false);
        window.alert("Success!");
      }
    } catch (error) {
      console.error("Error:", error);

      setRequestStatus(false);
    }
  }

  const [request, setRequestStatus] = useState(false);


  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div className="title">
        <h1>Upload Trades</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form onSubmit={(event) => handleSubmit(event)} encType="multipart/form-data" id="upload-trades">
          <h3>Centralized E-BLOT</h3>

          <div className="drop-container">
            <input multiple={true} className="drop-box-input" placeholder="Upload or drop file" type="file" name="bbg" accept=".xlsx, .csv" required />
          </div>

          <button type="submit" className="btn upload-btn">
            Upload
          </button>
        </form>
      </div>
      <h4 className="error-text">{error}</h4>
    </div>
  );
}

export default UploadTrades;
