import { CopyableCell } from "../../../../common/functions";
import { TableFrontOffice } from "../../tools/tool";
function RVPairComponent(props: any) {
  let table: any = props.analysis;
  let param = props.param;
  let title = props.title;
  let tableData = new TableFrontOffice(param + " Aggregated");
  let allTotalSum: any = tableData.calculateTotal(table, "", "white", "Group", title);
  let newTable = [allTotalSum, ...table[param]];

  for (let index = 0; index < newTable.length; index++) {
    let position = newTable[index];
    if (!(position["L/S"] == "Short" || position["L/S"] == "Long")) {
      position["L/S"] = "";
    } else {
      position["Group"] = "";
    }
  }

  return (
    <>
      {newTable.map((position: any, index: number) => (
        <tr
          key={index}
          className={"table-body " + (index == 0 ? "sum-row " : "") + (!(position["L/S"] == "Short" || position["L/S"] == "Long" || position["subTotal"] == "true") ? "borders" : "")}
          style={{ backgroundColor: `${position["Color"]}` }}
          onDoubleClick={(event) => (!(position["L/S"] == "Short" || position["L/S"] == "Long") ? "" : props.getTrades(event, position))}
          id={props.prefixId + "_" + index}
        >
          {props.titles.map((title: any, titleIndex: any) => (
            <CopyableCell key={titleIndex} text={isFinite(position[title]) && position[title] ? position[title].toLocaleString() : position[title]} index={titleIndex} columns={5} type={"summary"} color={position["Color"]} disabled={false} />
          ))}
        </tr>
      ))}
    </>
  );
}
export default RVPairComponent;
