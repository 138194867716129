import { useEffect, useState } from "react";
import { baseUrl, getRequestOptions, handleAuth, postRequestOptions } from "../../common/cookie";
import { CopyableCellSimple } from "../../common/functions";
import NavBar, { navigate } from "../../common/navbar";
import axios from "axios";
import Loader from "../../common/loader";
import { useLocation } from "react-router-dom";
function User() {
  let [user, setUser] = useState<any>({});
  let [files, setUserFiles] = useState<any>([]);
  let query = new URLSearchParams(useLocation().search);

  let selected = query.get("selected") || "";

  const [request, setRequestStatus] = useState(false);

  let tableTitles = ["Name", "Email", "Access Role", "Last Accessed Time", "Share ClassName", "Account Created On"];
  let tableTitlesFiles = ["File", "Purpose", "Uploaded On"];

  let tableTitlesHeaders = ["name", "email", "access_role_instance", "last_time_accessed", "share_class", "created_on"];

  function navigate(event: any, page: string) {
    window.location.href = page;
  }
  let url: any = baseUrl + `user?selected=${selected}`;

  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setUser(data);
        setUserFiles(data.files || []);
      });
  }, []);
  async function handleSubmitPublic(event: any) {
    event.preventDefault();
    setRequestStatus(true);

    // handle file processing here

    try {
      const form: any = document.getElementById("upload-files");
      const formData = new FormData(form);
      let uploadUrl = baseUrl + "user-upload-files";
      let action: any = await axios.post(uploadUrl, formData, postRequestOptions);

      handleAuth(action);
      if (action.data.error) {
        window.alert(action.data.error);
        navigate(event, "/user");
        setRequestStatus(false);
      } else {
        window.alert("Success!");
        navigate(event, "/user");
        setRequestStatus(false);
      }
    } catch (error) {
      console.error("Error:", error);

      setRequestStatus(false);
    }
  }
  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div>
        <div className="auth-name-container">
          <h1>{selected == "" ? "My Account" : user.name + "'s Account"}</h1>
        </div>
        <div className="trades-inputs">
          <div
            style={{
              width: "100%",
              // overflowX: "auto",
            }}
            className="table-container-custom"
          >
            <table
              style={{
                width: "100%",
              }}
              className="table table-hover table-portfolio table-striped table-trades user-table"
            >
              <tbody>
                <tr className="sticky-top table-header">
                  {tableTitles.map((title: string, index: number) => (
                    <td key={index}>{title}</td>
                  ))}
                </tr>

                <tr className="table-body">
                  {tableTitlesHeaders.map((title: string, index: number) => (
                    <CopyableCellSimple text={user[title]} />
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button id="download-btn" onClick={(event) => navigate(event, "/reset-password")} className="btn upload-btn">
          Reset Password
        </button>
        <div className="auth-name-container">
          <h1>{selected == "" ? "My Files" : user.name + "'s Files"}</h1>
        </div>
        <div className="trades-inputs">
          <div
            style={{
              width: "100%",
              // overflowX: "auto",
            }}
            className="table-container-custom"
          >
            <table
              style={{
                width: "100%",
              }}
              className="table table-hover table-portfolio table-striped table-trades user-table"
            >
              <tbody>
                <tr className="sticky-top table-header">
                  {tableTitlesFiles.map((title: string, index: number) => (
                    <td key={index}>{title}</td>
                  ))}
                </tr>

                {files.map((file: any, index: any) => (
                  <tr className="table-body">
                    <td className="link" onClick={(event: any) => navigate(event, file.link)}>
                      {file.name}
                    </td>
                    <td>{file.purpose}</td>
                    <td>{file.createdOn}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {selected == "" ? (
          <>
            <div className="auth-name-container">
              <h1>Upload Files</h1>
            </div>
            <div className="card-body card row auth-card">
              <form onSubmit={(event) => handleSubmitPublic(event)} encType="multipart/form-data" id="upload-files">
                <div>
                  <h3>Supported Files xlsx, csv, pdf, docx, doc</h3>
                </div>
                <div>
                  <p>Input purpose of the file/s</p>
                </div>
                <input type="text" className="formTextInput form-number-input" name="files-purpose" required />

                <div>
                  <p>Upload file/s from your device or drop them in the box</p>
                </div>
                <div className="drop-container">
                  <input multiple={true} className="drop-box-input" placeholder="Upload or drop file" type="file" name="files" accept=".xlsx, .csv, .pdf, .docx, .doc " required />
                </div>
                <button type="submit" className="btn upload-btn">
                  Upload
                </button>
              </form>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default User;
