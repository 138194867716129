import { AdvancedTooltip, Comment, returnAlignedMaxed, returnMultipliedBy100AndAligned } from "../../../common/functions";

function YearlyStatsCompare(props: any) {
  let yearsReversed = props.years;
  let data = props.compare;
  let ratio = props.ratio;
  let functionToAlign = ratio ? returnAlignedMaxed : returnMultipliedBy100AndAligned;
  let map: any = {
    Triada: "#76bf75",
    "BBG EM Asia": "#f9c393",
    "BBG EM Aggregate": "#92c1e3",
    "BBG Global Aggregate": "#f2add8",
    "Pimco Global Bond": "#f28989",
    "Fidelity Global Bond": "#b9a0ec",
  };

  return (
    <div>
      <p className="fact-sheet-sub-header">{props.title}</p>
      <p className="fact-sheet-sub-des">{props.description}</p>

      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-monthly-returns table-fact-sheet-compare">
        <tbody>
          <tr className="sticky-top table-header">
            {yearsReversed.map((year: string, index: number) => (
              <th key={index} style={{ fontSize: "15px" }}>
                {year}{" "}
              </th>
            ))}
          </tr>
          {Object.keys(data[yearsReversed[0]]).map((fund: any, indexBig: number) => (
            <tr className="table-body">
              {yearsReversed.map((year: string, indexSmall: number) => {
                let newFund = Object.keys(data[yearsReversed[indexSmall]])[indexBig];

                return (
                  <td key={indexSmall} style={{ backgroundColor: map[newFund] }}>
                    {newFund}
                    <br /> {functionToAlign(data[year][newFund]) + (ratio ? "" : " %")}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default YearlyStatsCompare;
