import { useState, useEffect, useCallback, useRef } from "react";
import NavBar, { navigate } from "../../common/navbar";
import { baseUrl, getRequestOptions, handleAuth, postRequestOptions } from "../../common/cookie";
import Loader from "../../common/loader";
import { useLocation } from "react-router-dom";
import { Add, CopyableCell, getDateTimeInMongoDBCollectionFormat } from "../../common/functions";
import axios from "axios";
import EditContact from "./tools/editContact";
import AddContact from "./tools/addContact";

function ViewContacts() {
  let tableTitlesPeople = ["first_name", "last_name", "city", "company_name", "country", "description", "email", "job_title", "phone_number", "website_url", "associated_company", "associated_note", "id"];
  let tableTitlesCompanies = [
    "company_name",
    "associated_contact",
    "city",
    "company_domain_name",
    "country_region",
    "linkedin_bio",
    "phone_number",
    "description",
    "annual_revenue",
    "linkedin_company_page",
    "number_of_employees",
    "postal_code",
    "state_region",
    "street_address",
    "time_zone",
    "website_url",
    "contact_with_primary_company",
    "id",
  ];

  let [contacts, setContacts] = useState([]);
  let [contactsSearched, setSearchedContacts] = useState([]);
  let query = new URLSearchParams(useLocation().search);
  let [type, setType] = useState(query.get("type") || "people");
  let [tableTitles, setTableTitles] = useState(type == "people" ? tableTitlesPeople : tableTitlesCompanies);

  let [selectedContact, setSelectedContact] = useState<any>({});
  let [userInfoDisplay, setContactInfoDisplay] = useState("none");
  let [authStatus, setAuthStatus] = useState("");
  let [addContactInfoDisplay, setAddContactInfoDisplay] = useState("none");

  let unEditableParams = ["id"];

  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const contextMenuRef: any = useRef(null);

  const handleContextMenu = useCallback((event: any, user: any) => {
    event.preventDefault();

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the position of the context menu accounting for the scroll
    const x = event.clientX + scrollX;
    const y = event.clientY + scrollY;

    setSelectedContact(user);
    setContextMenuState({
      visible: true,
      x: x,
      y: y,
    });
  }, []);

  const handleClick = useCallback((event: any) => {
    // Hide context menu if clicking outside
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenuState({ visible: false, x: 0, y: 0 });
    }
  }, []);

  const [request, setRequestStatus] = useState(false);
  let url: any = baseUrl + `contacts?type=${type}`;

  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setContacts(data);
        setSearchedContacts(data);
      });
  }, [url]);

  function cancelContactInfo(event: any) {
    setContactInfoDisplay("none");
    setAuthStatus("");
  }
  async function handleEditContact(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData(event.target);
    formData.append("type", type);

    try {
      let auth: any = await axios.post(baseUrl + "edit-contact", formData, postRequestOptions);

      if (!auth.data.error) {
        window.location.href = "/contacts?type=" + type;
      } else {
        setAuthStatus(auth.data.error);
        setRequestStatus(false);
      }
    } catch (error) {
      setAuthStatus("error");
    }
  }
  async function handleDeleteContact(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData();

    // Append the date to the FormData object with the desired key
    console.log({ selectedContact });
    formData.append("id", selectedContact["id"]);
    formData.append("type", type);

    let confirm = window.confirm("Confirm delete contact " + selectedContact["first_name"] || selectedContact["company_name"] + " ?");
    if (confirm) {
      try {
        let auth: any = await axios.post(baseUrl + "delete-contact", formData, postRequestOptions);

        if (auth.data.error) {
          setRequestStatus(false);
          window.alert(`${auth.data.error}`);
        } else {
          setTimeout(() => 2000);
          window.location.href = "/contacts?type=" + type;

          setRequestStatus(false);
        }
      } catch (error) {
        setAuthStatus("error");
      }
    }
    setRequestStatus(false);
  }
  async function handleAddContact(event: any, url: string, props: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData = new FormData(event.target);
    formData.append("type", type);

    try {
      let auth = await axios.post(baseUrl + "add-contact", formData, postRequestOptions);

      if (auth.data.error) {
        setAuthStatus(auth.data.error);
      } else {
        setTimeout(() => 2000);
        window.location.href = "/contacts?type=" + type;
      }
    } catch (error) {
      setAuthStatus("error");
    }

    setRequestStatus(false);
  }
  function onChangeTradeFilter(event: any) {
    let searchWords = event.target.value.toLowerCase().split(" ");

    // Filter the trades based on the search words
    let filtered = contacts.filter((user: any) => {
      // Convert trade fields to lowercase strings for comparison
      let email = (user["email"] || "").toString().toLowerCase();
      let name = (user["name"] || "").toString().toLowerCase();

      // Check if all search words are included in any of the trade fields
      return searchWords.every((word: any) => email.includes(word) || name.includes(word));
    });

    setSearchedContacts(filtered);
  }
  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <div className="log-container">
        <button
          className="btn btn-trades btn-edit-logs btn-share-class"
          onClick={(event: any) => {
            setTableTitles(tableTitlesPeople);
            setType("people");
          }}
        >
          People
        </button>
        <button
          className="btn btn-trades btn-edit-logs btn-share-class"
          onClick={(event: any) => {
            setTableTitles(tableTitlesCompanies);
            setType("companies");
          }}
        >
          Companies
        </button>
      </div>
      <div className="col-4 search-container">
        <input
          className="form-control mr-sm-2 filter-trades-search-bar"
          type="search"
          placeholder="Search Account by name or email"
          aria-label="Search"
          onChange={(event) => {
            onChangeTradeFilter(event);
          }}
        />
      </div>

      <div className="trades-inputs contacts-container">
        <div
          style={{
            width: "100%",
            // overflowX: "auto",
          }}
          className="table-container-custom"
          onClick={handleClick}
        >
          <table
            id="table-id"
            style={{
              width: "130%",
            }}
            className="table table-hover table-portfolio table-striped table-trades"
          >
            <tbody>
              <tr className="sticky-top table-header">
                {tableTitles.map((title: string, index: number) => (
                  <td key={index} style={{ width: title == "email" || title == "contact_with_primary_company" ? "100px" : "" }}>
                    {title}
                  </td>
                ))}
              </tr>

              {contactsSearched.map((user: any, index: number) => (
                <tr key={index} className="table-body" onContextMenu={(event) => handleContextMenu(event, user)} onDoubleClick={(event) => navigate(event, `/user?selected=${user.email}`)}>
                  {tableTitles.map((title: string, index: number) => (
                    <CopyableCell key={index} text={isFinite(user[tableTitles[index]]) && user[tableTitles[index]] != undefined ? user[tableTitles[index]].toLocaleString() : user[tableTitles[index]]} classNameParam="word-break" />
                  ))}
                </tr>
              ))}
              {contextMenuState.visible && (
                <div
                  ref={contextMenuRef}
                  style={{
                    position: "absolute",
                    top: contextMenuState.y,
                    left: contextMenuState.x,
                  }}
                  className="context-menu-container"
                >
                  <div className="context-menu">
                    <div className="context-menue-row">
                      <p className="context-menu-text" onClick={(event) => setContactInfoDisplay("block")}>
                        Edit Contact
                      </p>
                    </div>
                  </div>
                  <hr className="hr" />
                  <div className="context-menu">
                    <div className="context-menue-row">
                      <p className="context-menu-text" onClick={(event) => handleDeleteContact(event)}>
                        Delete Contact
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="edit-info-container-1" style={{ display: userInfoDisplay }}>
        <EditContact user={selectedContact} authStatus={authStatus} unEditableParams={unEditableParams} handleEditContact={handleEditContact} cancelContactInfo={cancelContactInfo} editTableTitles={tableTitles} />
      </div>
      <div className="edit-info-container-1" style={{ display: addContactInfoDisplay }}>
        <AddContact url={"add-user"} handleAddContact={handleAddContact} authStatus={authStatus} unEditableParams={unEditableParams} tableTitles={tableTitles} setDisplay={setAddContactInfoDisplay} />
      </div>
      <div onClick={(event) => setAddContactInfoDisplay("block")}>
        <Add />
      </div>
    </div>
  );
}
export default ViewContacts;
