import { useState, useEffect, useCallback, useRef } from "react";
import NavBar from "../../common/navbar";
import { baseUrl, getRequestOptions, handleAuth, postRequestOptions } from "../../common/cookie";
import Loader from "../../common/loader";
import axios from "axios";
import { CloseIcon, getDateTimeInMongoDBCollectionFormat } from "../../common/functions";
import * as XLSX from "xlsx";
import { Add } from "../../common/functions";

let tableTitles = ["Month", "a2", "a3", "a4", "a5", "a6", "TB03 Govt", "LEGATRUU Index", "EMUSTRUU Index", "BEUCTRUU Index", "BEUYTRUU Index", "LG30TRUU Index", "BEBGTRUU Index", "ma2", "ma3", "ma4", "ma6", "PIMGLBA ID Equity", "FIDITBD LX Equity"];
let innerTableTitles: any = ["a2", "a3", "a4", "a5", "a6", "3 Month Treasury", "LEGATRUU Index", "EMUSTRUU Index", "BEUCTRUU Index", "BEUYTRUU Index", "LG30TRUU Index", "BEBGTRUU Index", "ma2", "ma3", "ma4", "ma6", "PIMGLBA ID Equity", "FIDITBD LX Equity"];

function FactSheetData() {
  let [factSheetData, setFactSheetData] = useState<any>({});
  let [factSheetDataMonths, setFactSheetDataMonths] = useState<any>([]);

  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const contextMenuRef: any = useRef(null);
  let [authStatus, setAuthStatus] = useState("");
  let [type, setType] = useState("a2");

  const [request, setRequestStatus] = useState(false);
  let [monthInfoDisplay, setMonthInfoDisplay] = useState("none");
  let [monthInfo, setMonthInfoEdit] = useState<any>({});

  let [addMonthInfoDisplay, setAddMonthInfoDisplay] = useState("none");
  let [disabled, setDisabled] = useState(true);

  const handleContextMenu = useCallback((event: any, monthInfo: any) => {
    event.preventDefault();
    console.log({ monthInfo });

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the position of the context menu accounting for the scroll
    const x = event.clientX + scrollX;
    const y = event.clientY + scrollY;

    setMonthInfoEdit(monthInfo);
    setContextMenuState({
      visible: true,
      x: x,
      y: y,
    });
  }, []);
  const handleClick = useCallback((event: any) => {
    // Hide context menu if clicking outside
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenuState({ visible: false, x: 0, y: 0 });
    }
  }, []);
  let today: any = getDateTimeInMongoDBCollectionFormat(new Date());
  let url: any = baseUrl + `fact-sheet-data?type=${type}`;

  useEffect(() => {
    setRequestStatus(true);
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setFactSheetData(data.formmated);
        setDisabled(data.display);
        setFactSheetDataMonths(data.formmated.months);
        setRequestStatus(false);
      });
  }, []);

  if (factSheetData == null || request == true) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  function downloadCSV() {
    let table = document.getElementById("table-id");

    // Use XLSX.utils.table_to_book to convert the table to a workbook
    let wb = XLSX.utils.table_to_book(table, { sheet: "Sheet 1" });

    // Write the workbook to a file
    XLSX.writeFile(wb, `fact_sheet_${type}.xlsx`);
  }

  async function handleEditAddMonth(event: any, url: string, props: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData = new FormData(event.target);
    try {
      let auth = await axios.post(baseUrl + url, formData, postRequestOptions);

      if (auth.data.error) {
        setAuthStatus(auth.data.error);
      } else {
        setTimeout(() => 2000);
        window.location.reload();
      }
    } catch (error) {
      setAuthStatus("error");
    }

    setRequestStatus(false);
  }

  function ShowMonthInfo(props: any) {
    return (
      <div className="position-container edit-info-container-2">
        <div onClick={(event) => props.setDisplay("none")} className="close-icon-container-edit-position">
          <CloseIcon />
        </div>
        <form
          onSubmit={(event) => handleEditAddMonth(event, props.url, props)}
          style={{
            overflowX: "auto",
          }}
        >
          <table id="table-id" className="table table-hover table-portfolio table-striped table-fund-details table-fact-sheet-table">
            <tbody>
              <tr className="sticky-top">
                <td>Month</td>
                <td>Name</td>

                <td>Price</td>

                <td>Id</td>
              </tr>
              {props.url == "add-fact-sheet" ? (
                <tr className="">
                  <td>
                    <input name={"month"}></input>
                  </td>
                  <td>
                    <input name="name" value={"a2"} className="paramDisabled"></input>
                  </td>
                  <td>
                    <input name={"price"}></input>
                  </td>
                  <td>
                    <input name={"id"} placeholder={""} value={""} className="paramDisabled"></input>
                  </td>
                </tr>
              ) : (
                <tr className="">
                  <td>
                    <input name={"month"} placeholder={props.monthInfo ? props.monthInfo["month"] : ""} value={props.monthInfo ? props.monthInfo["month"] : ""} className="paramDisabled"></input>
                  </td>
                  <td>
                    <input name={"name"} placeholder={props.monthInfo ? props.monthInfo["name"] : ""} value={props.monthInfo ? props.monthInfo["name"] : ""} className="paramDisabled"></input>
                  </td>
                  <td>
                    <input name={"price"} placeholder={props.monthInfo ? props.monthInfo.price : ""}></input>
                  </td>
                  <td>
                    <input name={"id"} placeholder={props.monthInfo ? props.monthInfo["id"] : ""} value={props.monthInfo ? props.monthInfo["id"] : ""} className="paramDisabled"></input>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="edit-info-edit-container">
            <button type="submit" className="btn upload-btn position-info-edit">
              {" "}
              {props.monthInfo ? "Edit" : "Add"}
            </button>
          </div>
        </form>
        {authStatus && <h4 className="error error-position-info">{authStatus}</h4>}
      </div>
    );
  }

  async function handleDeleteMonth(event: any) {
    setRequestStatus(true);

    let action = window.confirm("Confirm delete price for month: " + monthInfo.month + " " + monthInfo.name);
    try {
      if (action) {
        let auth = await axios.post(baseUrl + "delete-fact-sheet-data", monthInfo, postRequestOptions);

        if (auth.status == 200) {
          window.location.href = "/view-fact-sheet-data";
          setRequestStatus(false);
        } else {
          setAuthStatus(auth.data.error);
          setRequestStatus(false);
        }
      }
      setRequestStatus(false);
    } catch (error) {
      setAuthStatus("error");
    }

    setRequestStatus(false);
  }

  async function handleEditFactSheetView(event: any, disabled: any) {
    setRequestStatus(true);

    let action = window.confirm(`Confirm ${disabled ? "Disable" : "Enable"} Fact Sheet View?`);
    try {
      if (action) {
        let auth = await axios.post(baseUrl + "edit-fact-sheet-view", { command: "view", disabled: disabled }, postRequestOptions);
        window.location.href = "view-fact-sheet-data";
      }
    } catch (error) {
      setAuthStatus("error");
    }

    setRequestStatus(false);
  }
  return (
    <div>
      <NavBar />{" "}
      <div className=" log-container">
        <button className={"btn btn-trades btn-edit-logs " + (!disabled ? "tab-log-selected" : "")} onClick={(event: any) => handleEditFactSheetView(event, false)}>
          Enable FactSheet
        </button>
        <button className={"btn btn-trades btn-edit-logs " + (disabled ? "tab-log-selected" : "")} onClick={(event: any) => handleEditFactSheetView(event, true)}>
          Disable FactSheet
        </button>
      </div>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
        className="table-container-custom"
      >
        <table id="table-id" onClick={handleClick} className="table table-portfolio table-hover table-striped table-fund-details">
          <tbody>
            <tr className="sticky-top">
              {tableTitles.map((title: string, index: number) => (
                <td key={index}>{title}</td>
              ))}
            </tr>
            {factSheetDataMonths.map((month: any, index: number) => (
              <tr>
                <td key={index}>{month}</td>

                {innerTableTitles.map((title: string, index: number) => (
                  <td key={index} onContextMenu={(event) => handleContextMenu(event, factSheetData.formmated[title][month] || { month: month, name: title })}>
                    {factSheetData.formmated[title][month] ? Math.round(factSheetData.formmated[title][month].price * 100) / 100 : ""}
                  </td>
                ))}
              </tr>
            ))}
            {contextMenuState.visible && (
              <div
                ref={contextMenuRef}
                style={{
                  position: "absolute",
                  top: contextMenuState.y,
                  left: contextMenuState.x,
                }}
                className="context-menu-container"
              >
                <div className="context-menu">
                  <div className="context-menue-row">
                    <p className="context-menu-text" onClick={(event) => setMonthInfoDisplay("block")}>
                      Edit Details
                    </p>
                  </div>
                </div>
                <hr className="hr" />
                <div className="context-menu">
                  <div className="context-menue-row">
                    <p className="context-menu-text" onClick={(event) => handleDeleteMonth(event)}>
                      Delete Details
                    </p>
                  </div>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
      <div onClick={(event) => setAddMonthInfoDisplay("block")}>
        <Add />
      </div>
      <button id="download-btn" onClick={downloadCSV} className="btn upload-btn">
        Download CSV
      </button>
      <div className="edit-info-container-1" style={{ display: monthInfoDisplay }} onDoubleClick={(event: any) => setMonthInfoDisplay("none")}>
        <ShowMonthInfo url={"edit-fact-sheet"} monthInfo={monthInfo} setDisplay={setMonthInfoDisplay} />
      </div>
      <div className="edit-info-container-1" style={{ display: addMonthInfoDisplay }} onDoubleClick={(event: any) => setAddMonthInfoDisplay("none")}>
        <ShowMonthInfo url={"add-fact-sheet"} setDisplay={setAddMonthInfoDisplay} />
      </div>
    </div>
  );
}
export default FactSheetData;
