import { useState, useEffect, useCallback, useRef } from "react";
import NavBar, { navigate } from "../../common/navbar";
import { baseUrl, getRequestOptions, handleAuth, postRequestOptions } from "../../common/cookie";
import Loader from "../../common/loader";
import { useLocation } from "react-router-dom";
import { Add, CopyableCell, getDateTimeInMongoDBCollectionFormat } from "../../common/functions";
import axios from "axios";
import EditUser from "./tools/editUser";
import AddUser from "./tools/addUser";
import * as XLSX from "xlsx";

function ViewUsers() {
  let tableTitles = ["email", "name", "access_role_instance", "access_role_portfolio", "share_class", "last_time_accessed", "reset_password", "created_on"];

  let url: any = baseUrl + `users`;
  let [users, setUsers] = useState([]);
  let [usersSearched, setSearchedUsers] = useState([]);

  let [selectedUser, setSelectedUser] = useState<any>({});
  let [userInfoDisplay, setUserInfoDisplay] = useState("none");
  let [authStatus, setAuthStatus] = useState("");
  let [addUserInfoDisplay, setAddUserInfoDisplay] = useState("none");

  let unEditableParams = ["id", "last_time_accessed", "created_on", "reset_password", "email"];

  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const contextMenuRef: any = useRef(null);

  const handleContextMenu = useCallback((event: any, user: any) => {
    event.preventDefault();

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the position of the context menu accounting for the scroll
    const x = event.clientX + scrollX;
    const y = event.clientY + scrollY;

    setSelectedUser(user);
    setContextMenuState({
      visible: true,
      x: x,
      y: y,
    });
  }, []);

  const handleClick = useCallback((event: any) => {
    // Hide context menu if clicking outside
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenuState({ visible: false, x: 0, y: 0 });
    }
  }, []);

  const [request, setRequestStatus] = useState(false);

  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setUsers(data);
        setSearchedUsers(data);
      });
  }, []);

  function cancelUserInfo(event: any) {
    setUserInfoDisplay("none");
    setAuthStatus("");
  }
  async function handleEditUser(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData(event.target);

    try {
      let auth: any = await axios.post(baseUrl + "edit-user", formData, postRequestOptions);

      if (!auth.data.error) {
        window.location.href = "/users";
      } else {
        setAuthStatus(auth.data.error);
        setRequestStatus(false);
      }
    } catch (error) {
      setAuthStatus("error");
    }
  }
  async function handleDeleteUser(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData();

    // Append the date to the FormData object with the desired key
    console.log({ selectedUser });
    formData.append("id", selectedUser["id"]);
    formData.append("name", selectedUser["name"]);

    formData.append("email", selectedUser["email"]);

    let confirm = window.confirm("Confirm delete User " + selectedUser["name"] + " ?");
    if (confirm) {
      try {
        let auth: any = await axios.post(baseUrl + "delete-user", formData, postRequestOptions);

        if (auth.data.error) {
          setRequestStatus(false);
          window.alert(`${auth.data.error}`);
        } else {
          setTimeout(() => 2000);
          window.location.reload();
          setRequestStatus(false);
        }
      } catch (error) {
        setAuthStatus("error");
      }
    }
    setRequestStatus(false);
  }
  async function handleAddUser(event: any, url: string, props: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData = new FormData(event.target);
    try {
      let auth = await axios.post(baseUrl + url, formData, postRequestOptions);

      if (auth.data.error) {
        setAuthStatus(auth.data.error);
      } else {
        setTimeout(() => 2000);
        window.location.reload();
      }
    } catch (error) {
      setAuthStatus("error");
    }

    setRequestStatus(false);
  }
  function onChangeTradeFilter(event: any) {
    let searchWords = event.target.value.toLowerCase().split(" ");

    // Filter the trades based on the search words
    let filtered = users.filter((user: any) => {
      // Convert trade fields to lowercase strings for comparison
      let email = user["email"].toString().toLowerCase();
      let name = user["name"].toString().toLowerCase();

      // Check if all search words are included in any of the trade fields
      return searchWords.every((word: any) => email.includes(word) || name.includes(word));
    });

    setSearchedUsers(filtered);
  }
  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <div className="col-4 search-container">
        <input
          className="form-control mr-sm-2 filter-trades-search-bar"
          type="search"
          placeholder="Search Account by name or email"
          aria-label="Search"
          onChange={(event) => {
            onChangeTradeFilter(event);
          }}
        />
      </div>
      <div className="trades-inputs users-container">
        <div
          style={{
            width: "100%",
            // overflowX: "auto",
          }}
          className="table-container-custom"
          onClick={handleClick}
        >
          <table
            id="table-id"
            style={{
              width: "100%",
            }}
            className="table table-hover table-portfolio table-striped table-trades"
          >
            <tbody>
              <tr className="sticky-top table-header">
                {tableTitles.map((title: string, index: number) => (
                  <td key={index}>{title}</td>
                ))}
              </tr>

              {usersSearched.map((user: any, index: number) => (
                <tr key={index} className="table-body" onContextMenu={(event) => handleContextMenu(event, user)} onDoubleClick={(event) => navigate(event, `/user?selected=${user.email}`)}>
                  {tableTitles.map((title: string, index: number) => (
                    <CopyableCell key={index} text={isFinite(user[tableTitles[index]]) && user[tableTitles[index]] != undefined ? user[tableTitles[index]].toLocaleString() : user[tableTitles[index]]} />
                  ))}
                </tr>
              ))}
              {contextMenuState.visible && (
                <div
                  ref={contextMenuRef}
                  style={{
                    position: "absolute",
                    top: contextMenuState.y,
                    left: contextMenuState.x,
                  }}
                  className="context-menu-container"
                >
                  <div className="context-menu">
                    <div className="context-menue-row">
                      <p className="context-menu-text" onClick={(event) => setUserInfoDisplay("block")}>
                        Edit User
                      </p>
                    </div>
                  </div>
                  <hr className="hr" />
                  <div className="context-menu">
                    <div className="context-menue-row">
                      <p className="context-menu-text" onClick={(event) => handleDeleteUser(event)}>
                        Delete User
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="edit-info-container-1" style={{ display: userInfoDisplay }}>
        <EditUser user={selectedUser} authStatus={authStatus} unEditableParams={unEditableParams} handleEditUser={handleEditUser} cancelUserInfo={cancelUserInfo} editTableTitles={tableTitles} />
      </div>
      <div className="edit-info-container-1" style={{ display: addUserInfoDisplay }}>
        <AddUser url={"add-user"} handleAddUser={handleAddUser} authStatus={authStatus} unEditableParams={unEditableParams} tableTitles={tableTitles} setDisplay={setAddUserInfoDisplay} />
      </div>
      <div onClick={(event) => setAddUserInfoDisplay("block")}>
        <Add />
      </div>
    </div>
  );
}
export default ViewUsers;
