function MonthlyReturns(props: any) {
  let minus = "- ";
  let positive = "+ ";
  return (
    <div>
      <p className="fact-sheet-sub-header">Monthly Performance Net of Fees (in percentage) for Triada Asia Credit Opportunities Fund Ltd. (“TACO”)</p>
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-monthly-returns">
        <tbody>
          <tr className="sticky-top table-header">
            <th></th>
            {props.years.map((year: string, index: number) => (
              <th key={index}>{year}</th>
            ))}
          </tr>

       
          {props.months.map((month: string, index: number) => (
            <tr key={index} className="table-body">
              <td key={index} className="fact-sheet-green-col">
                {month}
              </td>
              {props.years.map((year: string, indexYear: number) => (
                <td key={indexYear}>{props.monthlyReturns[year][props.monthsThatMatchDB[index]] || props.monthlyReturns[year][props.monthsThatMatchDB[index]] == 0 ? (props.monthlyReturns[year][props.monthsThatMatchDB[index]] >= 0 ? positive : minus) + Math.abs(Math.round(props.monthlyReturns[year][props.monthsThatMatchDB[index]] * 10000) / 100).toFixed(2) : ""}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default MonthlyReturns;
