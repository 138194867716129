export let wording: any = {
  a2: "",
  a3: "Triada started on June 1 2015.  A3 share class was created on June 1, 2016. For the purpose of showing Triada track record since inception, Fund NAV of share class A2 was used between June 1 2015 and June 1 2016.",
  a4: "Triada started on June 1 2015.  A4 share class was created on January 1, 2022. For the purpose of showing Triada track record since inception, Fund NAV of share class A2 was used between June 1 2015 and December 31 2021.",
  a5: "Triada started on June 1 2015.  A5 share class was created on January 1, 2022. For the purpose of showing Triada track record since inception, Fund NAV of share class A2 was used between June 1 2015 and June 1 2016 and Fund NAV of share class A3 was used between June 1 2016 and December 31 2021.",
  a6: "Triada started on June 1 2015.  A6 share class was created on January 1, 2022. For the purpose of showing Triada track record since inception, Fund NAV of share class A2 was used between June 1 2015 and June 1 2016 and Fund NAV of share class A3 was used between June 1 2016 and December 31 2021.",

  ma3: "Triada started on June 1 2015.  MA3 share class was created on March 1, 2016. For the purpose of showing Triada track record since inception, Fund NAV of share class MA2 was used between June 1 2015 and March 1 2016.",
  ma4: "Triada started on June 1 2015.  MA4 share class was created on Jan 1, 2022. For the purpose of showing Triada track record since inception, Fund NAV of share class MA2 was used between June 1 2015 and December 31 2021.",
  ma6: "Triada started on June 1 2015.  MA6 share class was created on January 1, 2022. For the purpose of showing Triada track record since inception, Fund NAV of share class MA2 was used between June 1 2015 and March 1 2016 and Fund NAV of share class MA3 was used between March 1 2016 and December 31 2021.",
};
export let fundInformation: any = {
  a2: { redemption: "Quarterly upon 90 day-notice after expiration of hard or soft lock (All Share Classes).", managementFee: "1%", performanceFee: "10%", lockUp: "2 year initial hard lock" },
  a3: { redemption: "Quarterly upon 90 day-notice after expiration of hard or soft lock (All Share Classes).", managementFee: "1.75%", performanceFee: "17.5%", lockUp: "1 year initial soft lock" },
  a4: {
    redemption: "Quarterly upon 90 day-notice after expiration of hard or soft lock (All Share Classes).",
    managementFee: "0.75%",
    performanceFee: "10%",
    lockUp: "2 year initial hard lock with option to roll to a new 2 year hard lock with same fee schedule. If no roll of hard lock then quarterly redemption with 90 days notification with 1% management fees and 10% performance fees.",
  },
  a5: {
    redemption: "Quarterly upon 90 day-notice after expiration of hard or soft lock (All Share Classes).",
    managementFee: "1.5%",
    performanceFee: "15%",
    lockUp: "1 year initial hard lock with option to roll to a new 1 year hard lock with same fee schedule. If no roll of hard lock then quarterly redemption with 90 days notification with 1.75% management fees and 17.5% performance fees.",
  },
  a6: {
    redemption: "Quarterly upon 90 day-notice after expiration of hard or soft lock (All Share Classes).",
    managementFee: "1.25%",
    performanceFee: "12.5%",
    lockUp: "2 year initial hard lock with option to roll to a new 2 year hard lock with same fee schedule. If no roll of hard lock then quarterly redemption with 90 days notification with 1.5% management fees and 15% performance fees.",
  },
  ma2: { redemption: "Quarterly upon 90 day-notice after expiration of hard or soft lock (All Share Classes).", managementFee: "1%", performanceFee: "10%", lockUp: "2 year initial hard lock" },
  ma3: { redemption: "Quarterly upon 90 day-notice after expiration of hard or soft lock (All Share Classes).", managementFee: "1.75%", performanceFee: "17.5%", lockUp: "1 year initial soft lock" },
  ma4: {
    redemption: "Quarterly upon 90 day-notice after expiration of hard or soft lock (All Share Classes).",
    managementFee: "0.75%",
    performanceFee: "10%",
    lockUp: "2 year initial hard lock with option to roll to a new 2 year hard lock with same fee schedule. If no roll of hard lock then quarterly redemption with 90 days notification with 1% management fees and 10% performance fees.",
  },
  ma6: {
    redemption: "Quarterly upon 90 day-notice after expiration of hard or soft lock (All Share Classes).",
    managementFee: "1.25%",
    performanceFee: "12.5%",
    lockUp: "2 year initial hard lock with option to roll to a new 2 year hard lock with same fee schedule. If no roll of hard lock then quarterly redemption with 90 days notification with 1.5% management fees and 15% performance fees.",
  },
};

// export let wording: any = {
//   a2: "",
//   a3: "",
//   a4: "",
//   a5: "",
//   a6: "",
// };
