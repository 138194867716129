function OutPerformance(props: any) {
  let outPerformanceHeaders = props.outPerformanceHeaders;
  let outPerformance = props.outPerformance;
  let annulizedReturnBenchMarks = props.annulizedReturnBenchMarks;
  let cumulativeReturnsBenchMarks = props.cumulativeReturnsBenchMarks;
  let benchmarksNaming = props.benchmarksNaming;

  let years = Object.keys(outPerformance).reverse();
  return (
    <div className="col-12 fact-sheet-sub-container">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats-1 fact-sheet-outperformance-table">
        <tbody>
          <tr className="sticky-top table-header">
            {outPerformanceHeaders.map((title: string, index: number) => (
              <th className={title == "Fund" ? "fact-sheet-long-title" : ""} key={index}>
                {benchmarksNaming[title] || "Fund"}
              </th>
            ))}
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Cumulative Return - Triada Outperformance (%)</td>
            <td></td>

            <td>{(Math.round(cumulativeReturnsBenchMarks[outPerformanceHeaders[2]].outPerformance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(cumulativeReturnsBenchMarks[outPerformanceHeaders[3]].outPerformance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(cumulativeReturnsBenchMarks[outPerformanceHeaders[4]].outPerformance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(cumulativeReturnsBenchMarks[outPerformanceHeaders[5]].outPerformance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(cumulativeReturnsBenchMarks[outPerformanceHeaders[6]].outPerformance * 10000) / 100).toFixed(2)}</td>
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Cumulative return (%)</td>
            <td>{(Math.round(cumulativeReturnsBenchMarks[outPerformanceHeaders[1]].performance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(cumulativeReturnsBenchMarks[outPerformanceHeaders[2]].performance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(cumulativeReturnsBenchMarks[outPerformanceHeaders[3]].performance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(cumulativeReturnsBenchMarks[outPerformanceHeaders[4]].performance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(cumulativeReturnsBenchMarks[outPerformanceHeaders[5]].performance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(cumulativeReturnsBenchMarks[outPerformanceHeaders[6]].performance * 10000) / 100).toFixed(2)}</td>
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Annualised return - Triada Outperformance (%)</td>
            <td></td>
            <td>{(Math.round(annulizedReturnBenchMarks[outPerformanceHeaders[2]].outPerformance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(annulizedReturnBenchMarks[outPerformanceHeaders[3]].outPerformance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(annulizedReturnBenchMarks[outPerformanceHeaders[4]].outPerformance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(annulizedReturnBenchMarks[outPerformanceHeaders[5]].outPerformance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(annulizedReturnBenchMarks[outPerformanceHeaders[6]].outPerformance * 10000) / 100).toFixed(2)}</td>
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Annualised return (%)</td>
            <td>{(Math.round(annulizedReturnBenchMarks[outPerformanceHeaders[1]].performance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(annulizedReturnBenchMarks[outPerformanceHeaders[2]].performance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(annulizedReturnBenchMarks[outPerformanceHeaders[3]].performance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(annulizedReturnBenchMarks[outPerformanceHeaders[4]].performance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(annulizedReturnBenchMarks[outPerformanceHeaders[5]].performance * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(annulizedReturnBenchMarks[outPerformanceHeaders[6]].performance * 10000) / 100).toFixed(2)}</td>
          </tr>
          {years.map((year: string) => (
            <>
              <tr className="fact-sheet-row">
                <td className="fact-sheet-col-grey">YTD {year} - Triada Outperformance (%)</td>
                <td></td>
                <td>{(Math.round(outPerformance[year][outPerformanceHeaders[2]].outPerformance * 10000) / 100).toFixed(2)}</td>
                <td>{(Math.round(outPerformance[year][outPerformanceHeaders[3]].outPerformance * 10000) / 100).toFixed(2)}</td>
                <td>{(Math.round(outPerformance[year][outPerformanceHeaders[4]].outPerformance * 10000) / 100).toFixed(2)}</td>
                <td>{(Math.round(outPerformance[year][outPerformanceHeaders[5]].outPerformance * 10000) / 100).toFixed(2)}</td>
                <td>{(Math.round(outPerformance[year][outPerformanceHeaders[6]].outPerformance * 10000) / 100).toFixed(2)}</td>
              </tr>
              <tr className="fact-sheet-row">
                <td className="fact-sheet-col-grey">YTD {year} (%)</td>
                <td>{(Math.round(outPerformance[year][outPerformanceHeaders[1]].performance * 10000) / 100).toFixed(2)}</td>
                <td>{(Math.round(outPerformance[year][outPerformanceHeaders[2]].performance * 10000) / 100).toFixed(2)}</td>
                <td>{(Math.round(outPerformance[year][outPerformanceHeaders[3]].performance * 10000) / 100).toFixed(2)}</td>
                <td>{(Math.round(outPerformance[year][outPerformanceHeaders[4]].performance * 10000) / 100).toFixed(2)}</td>
                <td>{(Math.round(outPerformance[year][outPerformanceHeaders[5]].performance * 10000) / 100).toFixed(2)}</td>
                <td>{(Math.round(outPerformance[year][outPerformanceHeaders[6]].performance * 10000) / 100).toFixed(2)}</td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default OutPerformance;
