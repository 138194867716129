import { baseUrl, postRequestOptions } from "../../common/cookie";
import Loader from "../../common/loader";
import { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

function ResetPassword() {
  const [userVerificationCode, setUserVerificationCode] = useState("");
  const [userNewPassword, setUserNewPassword] = useState("");
  const [userNewPasswordConfrim, setUserNewPasswordConfrim] = useState("");
  const [requestStatus, setRequestStatus] = useState(false);
  let query = new URLSearchParams(useLocation().search);
  const [resetRequest, setResetRequest] = useState(query.get("sent") || "block");
  const [authStatus, setAuthStatus] = useState(resetRequest == "none" ? "Reset code has been sent to your email!" : "");
  let [email, setEmail] = useState(query.get("email") || "");

  localStorage.removeItem("token");

  async function handleSendResetCode(event: any) {
    try {
      event.preventDefault();
      setRequestStatus(true);
      let auth = await axios.post(baseUrl + "send-reset-code", { email: email }, postRequestOptions);
      if (auth.data.status == 200) {
        setAuthStatus(auth.data.message);
        setResetRequest("none");
      } else {
        // console.log(auth.data.message);
        setAuthStatus(auth.data.message);
      }
      setRequestStatus(false);
    } catch (error) {
      setAuthStatus("Error: unauthorized");
    }
    setRequestStatus(false);
  }

  async function handleResetPassword(event: any) {
    try {
      event.preventDefault();
      setRequestStatus(true);
      if (checkPasswords()) {
        let auth = await axios.post(
          baseUrl + "reset-password",
          {
            email: email,
            code: userVerificationCode,
            password: userNewPassword,
          },
          postRequestOptions
        );

        if (auth.data.status == 200) {
          setAuthStatus(auth.data.message);
          setResetRequest("block");
          localStorage.setItem("token", "");
          localStorage.setItem("email", auth.data.email);
          window.location.href = "/login";
        } else {
          // console.log(auth.data.message);
          setAuthStatus(auth.data.message);
          setRequestStatus(false);
        }
        setRequestStatus(false);
      }
      setRequestStatus(false);
    } catch (error) {
      setAuthStatus("Error: unauthorized");
    }
    setRequestStatus(false);
  }

  function handleChange(event: any) {
    const { name, value } = event.target;
    switch (name) {
      case "userVerificationCode":
        setUserVerificationCode(value);
        break;

      case "userNewPassword":
        setUserNewPassword(value);
        break;
      case "userNewPasswordConfrim":
        setUserNewPasswordConfrim(value);
        break;
      default:
        break;
    }
  }

  function checkPasswords() {
    if (userNewPassword === userNewPasswordConfrim) {
      return true;
    } else {
      // Passwords don't match
      setAuthStatus("Passwords do not match");
    }
  }

  if (requestStatus) {
    return <Loader />
  }
  return (
    <div>
      <div className="container mt-5">
        <h1>Reset Password</h1>
      </div>

      <div className="row">
        <form className="col-10 login-card container mt-5" id="loginForm" style={{ display: resetRequest }}>
          <div>
            <h4>
              <b>Email</b>
            </h4>
            <input type="email" className="formTextInput" title="email" name="email" onChange={(event) => setEmail(event.target.value)} />
          </div>

          {authStatus && <h4 className="error">{authStatus}</h4>}
          <button type="submit" onClick={(event) => handleSendResetCode(event)} className="btn upload-btn">
            Send verification code
          </button>
        </form>
        <form onSubmit={(event) => handleResetPassword(event)} className="col-10 login-card container mt-5" style={{ display: resetRequest == "block" ? "none" : "block" }}>
          <p>Email: {email}</p>
          <h4 className="name">
            <b>Enter your new Password:</b>
          </h4>
          <input type="password" name="userNewPassword" value={userNewPassword} onChange={handleChange} className="formTextInput " required />
          <h4 className="name">
            <b>Confirm your new Password:</b>
          </h4>
          <input type="password" name="userNewPasswordConfrim" value={userNewPasswordConfrim} onChange={handleChange} className="formTextInput " required />
          <h4 className="name">
            <b>Enter Verification Code:</b>
          </h4>
          <input type="password" name="userVerificationCode" value={userVerificationCode} onChange={handleChange} className="formTextInput " required />
          {authStatus && <p className="error">{authStatus}</p>}
          <button type="submit" className="btn upload-btn">
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
}
export default ResetPassword;
