import React, { useState, useEffect } from "react";

function ViewDisclaimers() {
  return (
    <div className="">
      <div className="fact-sheet disclaimer" id="fact-sheet">
        <div className="row disclaimer-border">
          <div className="col-lg-6">
            <p className="fact-sheet-title">Triada Capital Limited</p>
            <p className="fact-sheet-title">Triada Asia Credit Opportunities Fund</p>
          </div>{" "}
          <div className="col-lg-6 fact-sheet-photo-container">
            <img src={"/photos/triada-logo.png"} className="header-logo fact-sheet-photo" alt="logo-triada" />
          </div>
        </div>
        <hr className="fact-sheet-hr"></hr>

        <div className="row disclaimers-container">
          <div className="col-12 disclaimers-title">Disclaimers</div>
          <div className="col-12 fact-sheet-footer disclaimers-text">
            <b>
              This document is a draft for discussion purposes only; it is highly confidential, the property of Triada Capital Limited (“Triada”), an entity registered with the Hong Kong Securities and Futures Commission, and should not be transmitted to any person other than its original
              addressee(s) without prior written consent of Triada.
            </b>
            <br />
            <br />
            This document has been prepared for general information purposes only and has not been delivered for registration in any jurisdiction, nor has its content been reviewed by any regulatory authority in any jurisdiction. The information contained herein does not constitute: (i) a binding
            legal agreement; (ii) legal, regulatory, tax, accounting or other advice; (iii) an offer, recommendation or solicitation to buy or sell shares in any fund or any security, commodity, financial instrument or derivative linked to, or otherwise included in a portfolio managed or advised by
            Triada; or (iv) an offer to enter into any other transaction whatsoever, (each a “Transaction”).
            <br />
            <br />
            Although information herein has been obtained from, and /or is based upon sources that Triada believes to be reliable, Triada does not guarantee its accuracy, completeness or fairness. Past performance and simulations are not representative of future results. Information may be changed
            or withdrawn without notice.
            <br />
            <br />
            <b>
              Triada shall not be liable for any decision taken on the basis of the information disclosed herein and no advice (including relating to financial services) is given herein by Triada or any of its affiliated parties. Should you require internal and/or external advice that you estimate
              necessary or desirable to obtain, you should contact your financial, legal or tax advisor, or any other specialist, in order to confirm that the transaction complies with your objectives and constraints and obtain an independent evaluation of the transaction, its benefits and risks
              factors.
            </b>
            <br />
            <br />
            <b>To U.S. Readers:</b> This document and any attachments thereto are neither an offer to sell, purchase or subscribe to neither any investment, nor a solicitation for any such offer. This document is intended for the use of institutional and professional customers of Triada and is not
            intended for the use of any private customer(s) of any company (-ies) cited herein. This document is intended for distribution in the United States solely to “major US institutional investors”, as defined in Rule 15a-6 of the US Securities Exchange Act of 1934, and may not be furnished
            to any other person(s) in the United States. Each major US institutional investor that receives This document, by such an act, agrees that it shall not distribute or provide with a copy of the document to any other person(s) or entity (-ies).
            <br />
            <br />
            <b>To Readers in Other Countries:</b> The securities discussed in This document and any attachments thereto, may not be eligible for distribution or sale in all countries or to certain categories of investors. Generally, This document and any attachments thereto must only be distributed
            to professional and institutional investors. This document is not intended for distribution to, or use by, the public or any person or entity in any jurisdiction where such use is prohibited by law or regulation. In accepting receipt of this information, you represent and warrant that
            you have not been solicited, directly or indirectly, by Triada and are receiving this
          </div>
        </div>
      </div>
    </div>
  );
}
export default ViewDisclaimers;
