type TableParam = {
  [key: string]: {
    "Day P&L (USD)": string;
    "MTD P&L (USD)": string;
    "USD Market Value": string;
    OAS: string;
    "Z Spread": string;
    "OAS W Change": string;
    "Notional Amount": string;
    DV01: string;
    "DV01 Dollar Value Impact": string;
    "DV01 Dollar Value Impact Test": string;
    "DV01 Dollar Value Impact Test Color": string;
    "Value (BC) % of Nav": string;
    "Value (BC) % of GMV": string;
    "Value (BC) Test": string;
    "Capital Gain/ Loss since Inception (Live Position)": string;
    "% of Capital Gain/ Loss since Inception (Live Position)": string;
    "Accrued Int. Since Inception (BC)": string;
    "Total Gain/ Loss (USD)": string;
    "% of Total Gain/ Loss since Inception (Live Position)": string;
    "Value (BC) Test Color": string;
    "Coupon Rate": string;
    Currency: string;
    Group: string;
    //risk
    "Duration(Mkt)": string;
    "BB Ticker": string;
    CR01: string;
    "CR01 Dollar Value Impact": string;
    "CR01 Dollar Value Impact Test": string;
    "CR01 Dollar Value Impact Test Color": string;
  };
};

type possibleTableParams =
  | "Day P&L (USD)"
  | "MTD P&L (USD)"
  | "USD Market Value"
  | "OAS"
  | "Z Spread"
  | "OAS W Change"
  | "Notional Amount"
  | "DV01"
  | "DV01 Dollar Value Impact"
  | "DV01 Dollar Value Impact Test"
  | "DV01 Dollar Value Impact Test Color"
  | "Value (BC) % of Nav"
  | "Value (BC) % of GMV"
  | "Value (BC) Test"
  | "Capital Gain/ Loss since Inception (Live Position)"
  | "% of Capital Gain/ Loss since Inception (Live Position)"
  | "Accrued Int. Since Inception (BC)"
  | "Total Gain/ Loss (USD)"
  | "% of Total Gain/ Loss since Inception (Live Position)"
  | "Value (BC) Test Color"
  | "Coupon Rate"
  | "Currency"
  | "Group"
  | "BB Ticker"
  | "Duration(Mkt)"
  | null
  | "CR01"
  | "CR01 Dollar Value Impact"
  | "CR01 Dollar Value Impact Test"
  | "CR01 Dollar Value Impact Test Color";

export class TableFrontOffice {
  private totalKey: string;

  constructor(totalKey: string) {
    this.totalKey = totalKey;
  }

  calculateTotal(table: TableParam, total: string, color: string, specialKey: possibleTableParams, specialKeyValue = "") {
    const totalData = table[this.totalKey];
    let object: any = {
      Total: total,
      "Day P&L (USD)": parseFloat(totalData["Day P&L (USD)"]).toLocaleString(),
      "MTD P&L (USD)": parseFloat(totalData["MTD P&L (USD)"]).toLocaleString(),
      "USD Market Value": parseFloat(totalData["USD Market Value"]).toLocaleString(),
      OAS: parseFloat(totalData["OAS"]).toLocaleString(),
      "OAS W Change": parseFloat(totalData["OAS W Change"]).toLocaleString(),
      "Z Spread": parseFloat(totalData["Z Spread"]).toLocaleString(),
      "Notional Amount": parseFloat(totalData["Notional Amount"]).toLocaleString(),
      DV01: parseFloat(totalData["DV01"]).toLocaleString(),
      "DV01 Dollar Value Impact": parseFloat(totalData["DV01 Dollar Value Impact"]).toLocaleString(),
      "DV01 Dollar Value Impact Test": totalData["DV01 Dollar Value Impact Test"],
      "DV01 Dollar Value Impact Test Color": totalData["DV01 Dollar Value Impact Test Color"],
      "Value (BC) % of Nav": parseFloat(totalData["Value (BC) % of Nav"]).toLocaleString() + " %",
      "Value (BC) % of GMV": parseFloat(totalData["Value (BC) % of GMV"]).toLocaleString() + " %",
      "Value (BC) Test": totalData["Value (BC) Test"],
      "Capital Gain/ Loss since Inception (Live Position)": parseFloat(totalData["Capital Gain/ Loss since Inception (Live Position)"]).toLocaleString(),
      "% of Capital Gain/ Loss since Inception (Live Position)": parseFloat(totalData["% of Capital Gain/ Loss since Inception (Live Position)"]).toLocaleString() + " %",
      "Accrued Int. Since Inception (BC)": parseFloat(totalData["Accrued Int. Since Inception (BC)"]).toLocaleString(),
      "Total Gain/ Loss (USD)": parseFloat(totalData["Total Gain/ Loss (USD)"]).toLocaleString(),
      "% of Total Gain/ Loss since Inception (Live Position)": parseFloat(totalData["% of Total Gain/ Loss since Inception (Live Position)"]).toLocaleString() + " %",
      "Value (BC) Test Color": totalData["Value (BC) Test Color"],
      Color: color, //"#F9F4D2",
      CR01: parseFloat(totalData["CR01"]).toLocaleString(),
      "CR01 Dollar Value Impact": parseFloat(totalData["CR01 Dollar Value Impact"]).toLocaleString(),
      "CR01 Dollar Value Impact Test": totalData["CR01 Dollar Value Impact Test"],
      "CR01 Dollar Value Impact Test Color": totalData["CR01 Dollar Value Impact Test Color"],
    };
    if (specialKey) {
      if (specialKeyValue != "") {
        object[specialKey] = specialKeyValue;
      } else {
        object[specialKey] = totalData[specialKey];
      }
    }
    return object;
  }
}

export function reactSelectFormat(params: any) {
  let options = [];
  for (let index = 0; index < params.length; index++) {
    let value = params[index];
    let object = { value: value, label: value };
    options.push(object);
  }
  return options;
}

export function passConditions(params: any) {
  let parse = "";
  for (let index = 0; index < params.length; index++) {
    if (params[index].value != "") {
      let name = params[index].value + "@";
      parse += name;
    }
  }
  return parse;
}
