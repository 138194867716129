import { useEffect, useState } from "react";

function PlTable(props: any) {
  let fundDetails = props.fundDetails;
  let analysis = props.analysis;
  let [currencies, setCurrencies] = useState<any>({});
  let [currenciesNames, setCurrenciesNames] = useState<any>([]);

  useEffect(() => {
    // Assuming props.summary.analysis.currencies is the object you want to start with
    let analysisCurrencies = props.analysis.currencies;
    let newCurrencies = { ...analysisCurrencies }; // Create a shallow copy

    delete newCurrencies["USD"]; // Remove "USD" key
    let currencyNames = Object.keys(newCurrencies);

    let updatedCurrencies: any = {};

    for (let index = 0; index < currencyNames.length; index++) {
      let currency = currencyNames[index];
      let currencyRate = newCurrencies[currency];

      if (currency === "HKD" || currency === "SGD") {
        // Convert HKD to USD/HKD rate and update the key
        updatedCurrencies["USD/" + currency] = 1 / currencyRate;
      } else {
        // Just update the key
        updatedCurrencies[currency + "/USD"] = currencyRate;
      }
    }

    // Now set the currencies state with the updated values
    setCurrencies(updatedCurrencies);
    setCurrenciesNames(Object.keys(updatedCurrencies));
  }, [props.analysis.currencies]);

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
      }}
      className=" fadeIn"
    >
      <table id={props.tableId} className="table table-striped fund-table">
        <tbody>
          <tr className="sticky-top">
            <td style={{ borderRight: "1px solid grey" }}>Starting NAV</td>
            <td style={{ borderRight: "1px solid grey" }}>{fundDetails.nav.toLocaleString()}</td>
            <td>100 %</td>
          </tr>

          <tr className="sticky-top">
            <td style={{ borderRight: "1px solid grey" }}>LMV</td>
            <td style={{ borderRight: "1px solid grey" }}>{fundDetails.lmv.toLocaleString()}</td>

            <td style={{ borderRight: "1px solid grey" }}>{fundDetails.lmvOfNav.toLocaleString()} %</td>
          </tr>
          <tr className="sticky-top">
            <td style={{ borderRight: "1px solid grey" }}>SMV</td>
            <td style={{ borderRight: "1px solid grey" }}>{fundDetails.smv.toLocaleString()}</td>
            <td style={{ borderRight: "1px solid grey" }}>{fundDetails.smvOfNav.toLocaleString()} %</td>
          </tr>
          <tr className="sticky-top">
            <td style={{ borderRight: "1px solid grey" }}>GMV</td>
            <td style={{ borderRight: "1px solid grey" }}>{fundDetails.gmv.toLocaleString()}</td>
            <td style={{ borderRight: "1px solid grey" }}>{fundDetails.gmvOfNav.toLocaleString()} %</td>
          </tr>
          <tr className="sticky-top">
            <td style={{ borderRight: "1px solid grey" }}>NMV</td>
            <td style={{ borderRight: "1px solid grey" }}>{fundDetails.nmv.toLocaleString()}</td>
            <td style={{ borderRight: "1px solid grey" }}>{fundDetails.nmvOfNav.toLocaleString()} %</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PlTable;
