function Exposure(props: any) {
  let exposureHeaders = props.exposureHeaders;
  let exposure = props.exposure;
  return (
    <div className="col-12 fact-sheet-sub-container fact-sheet-sub-container-2">
      <p className="fact-sheet-sub-header">Exposure (% NAV)</p>

      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats-1 fact-sheet-sub-container-exposure">
        <tbody>
          <tr className="sticky-top table-header">
            {exposureHeaders.map((title: string, index: number) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
          <tr className="table-body fact-sheet-row">
            <td className="fact-sheet-col-white">{(Math.round(exposure.lmv * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(exposure.gmv * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(exposure.smv * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(exposure.nmv * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default Exposure;
