import PaginationArrows from "./historyNavBar";

function NavbarFactSheetMember(props: any) {
  return (
    <div>
      <nav className="navbar navbar-expand-lg  navbar-custom">
        <div className="container-fluid">
          <a onClick={(event) => props.navigate(event, "/view-fact-sheet")}>
            <img src={"/photos/triada-logo.png"} className="header-logo" alt="logo-triada" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 header-items">
              <li className="nav-item dropdown">
                <a className="nav-link active header-item" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Reports
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "/view-fact-sheet")}>
                      Fact Sheet
                    </a>
                  </li>
                  {Object.keys(props.investorReports).map((key: any, index: number) => (
                    <li>
                      <a className="dropdown-item" onClick={(event) => props.navigate(event, props.investorReports[key])}>
                        Investor Report {key.toString().toUpperCase()}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link active header-item" onClick={(event) => props.navigate(event, "/media")} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Triada Media
                </a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link active header-item" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Account
                </a>

                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" onClick={(event) => props.navigate(event, "/user")}>
                      My Account
                    </a>
                  </li>

                  <li>
                    <button className="dropdown-item" aria-current="page" onClick={(event) => props.handleLogOut(event)}>
                      Log Out
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavbarFactSheetMember;
