import { CloseIcon } from "../../../common/functions";

function AddUser(props: any) {
  return (
    <div className="position-container edit-info-container-2">
      <div onClick={(event) => props.setDisplay("none")} className="close-icon-container-edit-position">
        <CloseIcon />
      </div>
      <form
        onSubmit={(event) => props.handleAddUser(event, props.url, props)}
        style={{
          overflowX: "auto",
        }}
      >
        <table id="table-id" className="table table-hover table-portfolio table-striped table-fund-details table-add-user">
          <tbody>
            <tr className="sticky-top">
              {props.tableTitles.map((title: string, index: number) => (
                <td key={index}>{title}</td>
              ))}
            </tr>
            <tr className="">
              {props.tableTitles.map((title: string, index: number) => (
                <td key={index}>
                  {!props.unEditableParams.includes(title) || title == "email" ? (
                    title == "access_role_instance" ? (
                      <td>
                        <select name={title}>
                          <option>admin</option>
                          <option>member (risk report)</option>
                          <option>member (factsheet report)</option>
                        </select>
                      </td>
                    ) : (
                      <input name={title}></input>
                    )
                  ) : (
                    ""
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="edit-info-edit-container">
          <button type="submit" className="btn upload-btn position-info-edit">
            {" "}
            Add
          </button>
        </div>
      </form>
      {props.authStatus && <h4 className="error error-position-info">{props.authStatus}</h4>}
    </div>
  );
}

export default AddUser;
