function Regression(props: any) {
  let benchmarksHeaders = props.benchmarksHeaders;
  let benchmarksNaming = props.benchmarksNaming;

  let correlationAndRegresion = props.correlationAndRegresion;

  return (
    <div className="col-12 fact-sheet-sub-container">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats-1 fact-sheet-outperformance-table">
        <tbody>
          <tr className="sticky-top table-header">
            {benchmarksHeaders.map((title: string, index: number) => (
              <th className={title == "Fund" ? "fact-sheet-long-title" : ""} key={index}>
                {benchmarksNaming[title] || "Correlation"}
              </th>
            ))}
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Upside Correlation</td>
            <td></td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[2]]["Upside Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[3]]["Upside Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[4]]["Upside Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[5]]["Upside Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[6]]["Upside Correlation"] * 10000) / 10000).toFixed(2)}</td>
          
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Information Ratio</td>
            <td></td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[2]]["Information Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[3]]["Information Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[4]]["Information Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[5]]["Information Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[6]]["Information Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Downside Correlation</td>
            <td></td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[2]]["Downside Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[3]]["Downside Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[4]]["Downside Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[5]]["Downside Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[6]]["Downside Correlation"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Correlation</td>
            <td></td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[2]]["Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[3]]["Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[4]]["Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[5]]["Correlation"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[6]]["Correlation"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Beta</td>
            <td></td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[2]]["Beta"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[3]]["Beta"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[4]]["Beta"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[5]]["Beta"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[6]]["Beta"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
        </tbody>
      </table>
    </div>
  );
}
export default Regression;
