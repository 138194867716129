function SkewnessKurtosis(props: any) {
  let normal = props.normal;
  let variance = props.variance;
  let factSheetTwoYears = props.factSheetTwoYears;
  let factSheetFiveYears = props.factSheetFiveYears;
  let type = props.type;
  let factSheetChinaPeriod = props.factSheetChinaPeriod;
  let periods = props.periods;
  return (
    <div className="col-12 fact-sheet-sub-container">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats-1">
        <tbody>
          <tr className="sticky-top table-header">
            <th>Tail Risk</th>
            {periods.map((period: string, index: string) => (
              <th>{period}</th>
            ))}
          </tr>
          <tr className="table-body fact-sheet-row">
            <td className="fact-sheet-col-grey">Skewness</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.normal[type].skewness * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.normal[type].skewness * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.normal[type].skewness * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(normal.skewness * 100) / 100).toFixed(2) || "0"} </td>
          </tr>
          <tr className="table-body fact-sheet-row ">
            <td className="fact-sheet-col-grey">Kurtosis</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.normal[type].kurtosis * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.normal[type].kurtosis * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.normal[type].kurtosis * 100) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(normal.kurtosis * 100) / 100).toFixed(2) || "0"} </td>
          </tr>
          <tr className="table-body fact-sheet-row">
            <td className="fact-sheet-col-grey">Variance</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.variance[type] * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.variance[type] * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.variance[type] * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(variance * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default SkewnessKurtosis;
