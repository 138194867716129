import { CloseIcon } from "../../../common/functions";

function EditContact(props: any) {
  let editTableTitles = props.editTableTitles;
    return (
      <div className="position-container edit-info-container-2">
        <div onClick={(event) => props.cancelContactInfo(event)} className="close-icon-container-edit-position">
          <CloseIcon />
        </div>
        <form
          onSubmit={(event) => props.handleEditContact(event)}
          style={{
            overflowX: "auto",
          }}
        >
          <table
            id="table-id"
            className="table table-hover table-portfolio table-trades table-edit table-add-user"
            style={
              {
                // width: "300%",
              }
            }
          >
            <tbody>
              <tr className="sticky-top">
                {editTableTitles.map((title: any, titleIndex: any) => {
                  return (
                    <td key={titleIndex} className="medium-wide-table-cell">
                      {title}
                    </td>
                  );
                })}
              </tr>

              <tr>
                {editTableTitles.map((title: string, index: number) => {
                  return props.unEditableParams.includes(title) ? (
                    <td key={index} className="extra-wide-table-cell">
                      <input name={title} type="text" value={props.user[title]} className="paramDisabled" />
                    </td>
                  ) : title == "Edit Note" ? (
                    <td>
                      <input type="text" name={title} placeholder={props.user[title]} required />
                    </td>
                  ) : title == "access_role_instance" ? (
                    <td>
                      <select name={title}>
                        <option value="" disabled selected>
                          Select your option
                        </option>
                        <option>admin</option>
                        <option>member (risk report)</option>
                        <option>member (factsheet report)</option>
                      </select>
                    </td>
                  ) : (
                    <td>
                      <input type="text" name={title} placeholder={props.user[title]} />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
          <div className="edit-info-edit-container">
            <button type="submit" className="btn upload-btn position-info-edit">
              {" "}
              Edit
            </button>
          </div>
        </form>
        {props.authStatus && <h4 className="error error-position-info">{props.authStatus}</h4>}
      </div>
    );
 
}
export default EditContact;
