import { useState, useEffect, useCallback, useRef } from "react";
import NavBar from "../../common/navbar";
import { baseUrl, getRequestOptions, handleAuth, postRequestOptions } from "../../common/cookie";
import Loader from "../../common/loader";
import axios from "axios";
import { CloseIcon, CopyableCellWithDifferentText, getDateTimeInMongoDBCollectionFormat } from "../../common/functions";
import * as XLSX from "xlsx";
import { Add } from "../../common/functions";

let tableTitles: any = ["Link", "Name", "Created On", "Email", "Expiration", "Last Time Accessed", "route", "Access Right"];
let tableTitlesSql: any = ["link", "name", "created_on", "email", "expiration", "last_time_accessed", "route", "share_class"];

let tableTitlesUneditable: any = ["link", "createdOn", "email", "expiration", "lastAccessedTime", "accessRight"];
let tableTitlesAdd: any = ["link", "created_on", "expiration", "last_time_accessed"];

function Links() {
  let [info, setInfo] = useState<any>([]);
  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const contextMenuRef: any = useRef(null);
  let [authStatus, setAuthStatus] = useState("");
  const [request, setRequestStatus] = useState(false);
  let [infoDisplay, setInfoDisplay] = useState("none");
  let [infoEdit, setInfoEdit] = useState<any>({});

  let [addInfoDisplay, setAddInfoDisplay] = useState("none");

  const handleContextMenu = useCallback((event: any, info: any) => {
    event.preventDefault();

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the position of the context menu accounting for the scroll
    const x = event.clientX + scrollX;
    const y = event.clientY + scrollY;

    setInfoEdit(info);
    setContextMenuState({
      visible: true,
      x: x,
      y: y,
    });
  }, []);
  const handleClick = useCallback((event: any) => {
    // Hide context menu if clicking outside
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenuState({ visible: false, x: 0, y: 0 });
    }
  }, []);
  let url: any = baseUrl + `links`;

  useEffect(() => {
    setRequestStatus(true);
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setInfo(data);
        setRequestStatus(false);
      });
  }, []);

  if (info == null || request == true) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  async function handleEditAdd(event: any, url: string) {
    setRequestStatus(true);
    event.preventDefault();

    let formData = new FormData(event.target);
    try {
      let auth = await axios.post(baseUrl + url, formData, postRequestOptions);

      if (auth.data.error) {
        setAuthStatus(auth.data.error);
      } else {
        setTimeout(() => 2000);
        window.location.reload();
      }
    } catch (error) {
      setAuthStatus("error");
    }

    setRequestStatus(false);
  }

  function ShowInfo(props: any) {
    let url = props.url ? props.url : "";
    let titlesToConsider = url.toString().includes("add") ? tableTitlesAdd : tableTitlesUneditable;
    return (
      <div className="position-container edit-info-container-2">
        <div onClick={(event) => props.setDisplay("none")} className="close-icon-container-edit-position">
          <CloseIcon />
        </div>
        <form
          onSubmit={(event) => handleEditAdd(event, props.url)}
          style={{
            overflowX: "auto",
          }}
        >
          <table id="table-id" style={{ width: "120%" }} className="table table-hover table-portfolio table-striped table-fund-details">
            <tbody>
              <tr className="sticky-top">
                {tableTitles.map((title: string, index: number) => (
                  <td key={index}>{title}</td>
                ))}
              </tr>
              <tr className="">
                {tableTitlesSql.map((title: string, index: number) => (
                  <td key={index}>
                    {props.info && titlesToConsider.includes(title) ? (
                      <input name={title} placeholder={props.info[title]} value={props.info[title]} className="paramDisabled"></input>
                    ) : titlesToConsider.includes(title) ? (
                      <input name={title} placeholder={""} value={""} className="paramDisabled"></input>
                    ) : title == "accessRole" ? (
                      <select name={title}>
                        <option>member (risk report)</option>
                        <option>member (factsheet report)</option>
                      </select>
                    ) : title == "route" ? (
                      <select name={title}>
                        <option value="">factsheet</option>
                        <option value={"-media"}>media</option>
                      </select>
                    ) : (
                      <input name={title} placeholder={props.info ? props.info[title] : ""} required></input>
                    )}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <div className="edit-info-edit-container">
            <button type="submit" className="btn upload-btn position-info-edit">
              {" "}
              {props.info ? "Edit" : "Add"}
            </button>
          </div>
        </form>
        {authStatus && <h4 className="error error-position-info">{authStatus}</h4>}
      </div>
    );
  }

  async function handleDelete(event: any, title: any, url: any, redirect: any) {
    setRequestStatus(true);

    let action = window.confirm(`Confirm delete ${title}?`);
    try {
      if (action) {
        let auth = await axios.post(baseUrl + url, infoEdit, postRequestOptions);

        if (auth.status == 200) {
          window.location.href = redirect;
        } else {
          setAuthStatus(auth.data.error);
        }
      }
    } catch (error) {
      setAuthStatus("error");
    }

    setRequestStatus(false);
  }

  return (
    <div>
      <NavBar />

      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
        className="table-container-custom"
      >
        <table id="table-id" onClick={handleClick} className="table table-portfolio  table-striped table-fund-details">
          <tbody>
            <tr className="sticky-top">
              {tableTitles.map((title: string, index: number) => (
                <td key={index}>{title}</td>
              ))}
            </tr>
            {info.map((details: any, index: number) => (
              <tr onContextMenu={(event) => handleContextMenu(event, details)} key={index}>
                {tableTitlesSql.map((title: string, index: number) => (title == "link" ? <CopyableCellWithDifferentText text="Link" value={details[tableTitlesSql[index]]} /> : <td>{details[tableTitlesSql[index]]}</td>))}
              </tr>
            ))}
            {contextMenuState.visible && (
              <div
                ref={contextMenuRef}
                style={{
                  position: "absolute",
                  top: contextMenuState.y,
                  left: contextMenuState.x,
                }}
                className="context-menu-container"
              >
                <div className="context-menu">
                  <div className="context-menue-row">
                    <p className="context-menu-text" onClick={(event) => handleDelete(event, `link ${infoEdit.name + " " + infoEdit.share_class}`, "delete-link", "/links")}>
                      Delete Details
                    </p>
                  </div>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
      <div onClick={(event) => setAddInfoDisplay("block")}>
        <Add />
      </div>

      <div className="edit-info-container-1" style={{ display: addInfoDisplay }} onDoubleClick={(event: any) => setAddInfoDisplay("none")}>
        <ShowInfo url={"add-link"} setDisplay={setAddInfoDisplay} />
      </div>
    </div>
  );
}
export default Links;
