import axios from "axios";
import { baseUrl, postRequestOptions } from "../../../common/cookie";
import { CopyableCell, RedStar, WhiteStar } from "../../../common/functions";
import { PinnedPosition, Position } from "../../../models/position";
import { longTitlesBO, mediumTitlesBO, smallTitlesBO } from "../../reports/tools/titlesPortfolio";
import { longTitlesFO, mediumTitlesFO, smallTitlesFO } from "../tools/titlesSummary";
import { width } from "../portfolio/viewSummary";
import { widthMobile, widthScreen } from "../../reports/portfolio/viewPortfolio";

function TopWorst(props: any) {
  let topWorstParam = props.topWorst;
  async function handlePinPosition(event: any, position: Position) {
    event.preventDefault();
    position["Pin"] = position["Pin"] == "pinned" ? "not_pinned" : "pinned";
    let data: PinnedPosition = { ISIN: position["ISIN"], Location: position["Location"], Pin: position["Pin"], ticker: position["BB Ticker"], id: position["id"], portfolio_id: "portfolio_main", Date: props.date };

    try {
      let auth = await axios.post(baseUrl + "pin-position", data, postRequestOptions);

      if (!auth.data.error) {
        window.location.href = "/view-performers";
      } else {
        window.alert(auth.data.error);
      }
    } catch (error) {}
  }
  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
      }}
      className=" fadeIn table-container-custom"
    >
      <table id="" className="table table-hover table-summary">
        <tbody>
          <tr className="sticky-top">
            {props.view == "front office" ? (
              <td className={"copied-text higher-sticky-row small-table-cell"} style={{ left: props.screenWidth < 1000 ? width[0] : "" }}>
                Pin
              </td>
            ) : (
              ""
            )}
            {props.titles.map((title: any, titleIndex: any) => {
              // Check if the title is one of the specified strings

              // If you need to keep the table structure consistent, render an empty <td>
              return (
                <td
                  key={titleIndex}
                  className={
                    props.view == "front office"
                      ? "copied-text " +
                        (titleIndex < 6 ? " higher-sticky-row " : " lower-sticky-row ") +
                        (longTitlesFO.includes(title) ? " wider-table-cell" : "") +
                        (titleIndex < 6 ? "higher-sticky-row" : "lower-sticky-row") +
                        (longTitlesFO.includes(title) ? " wider-table-cell" : "") +
                        (smallTitlesFO.includes(title) ? " small-table-cell" : "") +
                        (mediumTitlesFO.includes(title) ? " medium-table-cell" : "")
                      : "copied-text " +
                        (titleIndex < 10 ? " higher-sticky-row " : " lower-sticky-row ") +
                        (titleIndex < 10 ? " higher-sticky-portfolio-row  " : "") +
                        (longTitlesBO.includes(title) ? " wider-table-cell" : "") +
                        (longTitlesBO.includes(title) ? " wider-table-cell" : "") +
                        (smallTitlesBO.includes(title) ? " small-table-cell" : "") +
                        (mediumTitlesBO.includes(title) ? " medium-table-cell" : "")
                  }
                  style={{ left: props.view == "front office" ? (props.screenWidth < 1000 ? width[titleIndex + 1] : "") : props.screenWidth < 1000 ? widthMobile[titleIndex] : widthScreen[titleIndex] }}
                >
                  {title}
                </td>
              );
            })}
          </tr>
          {topWorstParam.map((group: any, index: number) =>
            group.data.map((position: any, positionIndex: any) => (
              <tr
                key={positionIndex}
                className="table-body"
                style={{ backgroundColor: `${position["Color"]}`, borderTop: position["top"] ? "2px solid grey" : "", borderBottom: position["bottom"] ? "2px solid grey" : "" }}
                onDoubleClick={(event) => props.getTrades(event, position)}
                id={"content" + "_" + props.idPrefix + "_" + index + "_" + positionIndex}
              >
                {props.view == "front office" ? (
                  <td className="sticky-summary" style={{ cursor: "pointer", position: "relative", backgroundColor: position["Color"], left: props.screenWidth < 1000 ? width[0] : "" }} onClick={(event) => handlePinPosition(event, position)}>
                    {position["Pin"] == "pinned" ? <RedStar /> : <WhiteStar />}
                  </td>
                ) : (
                  ""
                )}

                {props.titles.map((title: any, titleIndex: any) =>
                  props.view == "front office" ? (
                    <CopyableCell
                      key={titleIndex}
                      text={isFinite(position[title]) && position[title] ? position[title].toLocaleString() : position[title]}
                      index={titleIndex}
                      columns={6}
                      type={"summary"}
                      color={position["Color"]}
                      disabled={false}
                      left={props.screenWidth < 1000 ? width[titleIndex + 1] : ""}
                    />
                  ) : (
                    <CopyableCell
                      key={titleIndex}
                      text={isFinite(position[title]) && position[title] ? position[title].toLocaleString() : position[title]}
                      index={titleIndex}
                      columns={10}
                      type={"portfolio"}
                      color={position["Color"]}
                      left={props.screenWidth < 1000 ? widthMobile[titleIndex] : widthScreen[titleIndex]}
                    />
                  )
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TopWorst;
