import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../../../common/navbar";
import { baseUrl, getAxiosRequestOptions, getRequestOptions, handleAuth } from "../../../common/cookie";
import Loader from "../../../common/loader";
import axios from "axios";
import { getDateTimeInMongoDBCollectionFormat, getCurrentDateFormatted, getDurationName } from "../../../common/functions";

import { formatDate } from "../../../common/functions";
import { hiddenTitlesFO } from "../tools/titlesSummary";
import ViewPerformersTables from "./viewPerformersTables";
import ViewSummaryFund from "../portfolio/moreInfoTable";
import FilterCard from "../tools/filterCard";
import { tableTitlesBackOffice } from "../../reports/tools/titlesPortfolio";

function ViewPerformers() {
  let [portfolio, setPortfolio] = useState<any>();
  let [summary, setSummary] = useState<any>({});
  let [lastPriceUpdate, setPriceLastUpdate] = useState("");
  let [lastTradeUpload, setTradeLastUpload] = useState("");
  let [positionInfo, setPositionInfo] = useState([]);
  let [filterCardDisplay, setFilterCardDisplay] = useState("none");
  let [filterText, setFilterText] = useState<any>([]);

  const [request, setRequestStatus] = useState(false);

  let today: any = getCurrentDateFormatted(new Date());

  let [date, setDate] = useState(today);

  let [titles, setTitles] = useState([]);
  let query = new URLSearchParams(useLocation().search);
  let country = query.get("country");
  let sector = query.get("sector");
  let typeQuery = query.get("type");
  let viewQuery = query.get("view");
  let [performersType, setPerformersType] = useState(typeQuery || "pl");
  let [view, setView] = useState<string>(viewQuery || "front office");
  let strategy = query.get("strategy");
  let durationStart: any = query.get("duration_start");
  let durationEnd: any = query.get("duration_end");
  let currency: any = query.get("currency");
  let issuer: any = query.get("issuer");
  let longShort = query.get("longShort");
  let ticker: any = query.get("ticker");
  let [yieldParam, setYieldParam] = useState(query.get("yield"));
  let [rating, setRating] = useState(query.get("rating"));
  let [marketType, setMarketType] = useState(query.get("marketType"));
  let [region, setRegion] = useState(query.get("region"));
  let [assetClass, setAssetClass] = useState(query.get("assetClass"));
  let [coupon, setCoupon] = useState(query.get("coupon"));
  const [searchText, setSearchText] = useState("");

  const handleSearch = (text: any) => {
    let initialIds = ["1", "2", "3", "4"];
    if (!text) {
      initialIds.forEach((num) => {
        for (let i = 0; i < 10; i++) {
          for (let j = 0; j < 2; j++) {
            let id = "content_" + num + "_" + i + "_" + j;
            const content = document.getElementById(id);
            if (content) {
              const elements = content.querySelectorAll("*");
              elements.forEach((element: any) => {
                element.classList.remove("highlight");
              });
            }
          }
        }
      });
      return;
    } else {
      initialIds.forEach((num) => {
        for (let i = 0; i < 10; i++) {
          for (let j = 0; j < 2; j++) {
            let id = "content_" + num + "_" + i + "_" + j;
            const content = document.getElementById(id);
            if (content) {
              const elements = content.querySelectorAll("*");

              // Remove all highlights first
              elements.forEach((element: any) => {
                element.classList.remove("highlight");
              });

              // Add highlights to matched elements
              elements.forEach((element: any) => {
                if (element.textContent.toLowerCase().includes(text.toLowerCase())) {
                  element.classList.add("highlight");
                  element.scrollIntoView({ behavior: "smooth", block: "center" });
                }
              });
            }
          }
        }
      });
    }
  };

  let url: any = baseUrl + `performers-portfolio?date=${today}&sort=order&sign=1&type=${performersType}&view=${view}`;
  if (country) {
    url += "&country=" + country;
  }
  if (sector) {
    url += "&sector=" + sector;
  }
  if (strategy) {
    url += "&strategy=" + strategy;
  }
  if (durationStart) {
    url += "&durationStart=" + durationStart;
  }
  if (durationEnd) {
    url += "&durationEnd=" + durationEnd;
  }
  if (currency) {
    url += "&currency=" + currency;
  }
  if (issuer) {
    url += "&issuer=" + issuer;
  }
  if (longShort) {
    url += "&longShort=" + longShort;
  }
  if (ticker) {
    url += "&ticker=" + ticker;
  }
  if (assetClass) {
    url += "&assetClass=" + assetClass;
  }
  if (coupon) {
    url += "&coupon=" + coupon;
  }
  if (yieldParam !== "") {
    url += "&yield=" + yieldParam;
  }

  if (rating) {
    url += "&rating=" + rating;
  }
  if (region) {
    url += "&region=" + region;
  }
  if (marketType) {
    url += "&marketType=" + marketType;
  }

  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setPortfolio(data.portfolio);
        setTradeLastUpload(formatDate(data.uploadTradesDate));

        let text = [];
        if (country) {
          text.push("Country: " + country.replace(/@/g, " & "));
        }

        if (sector) {
          text.push("Sector: " + sector.replace(/@/g, " & "));
        }
        if (strategy) {
          text.push("Strategy: " + strategy.replace(/@/g, " & "));
        }
        if (durationStart) {
          if (!durationEnd) {
            durationEnd = 100;
          }
          durationStart = getDurationName(durationStart);
          durationEnd = getDurationName(durationEnd);

          text.push("Duration: " + durationStart + " - " + durationEnd);
        }

        if (issuer) {
          text.push("Issuer: " + issuer.replace(/@/g, " & "));
        }
        if (ticker) {
          text.push("BB Ticker: " + ticker.replace(/@/g, " & "));
        }
        if (currency) {
          text.push("Currency: " + currency.replace(/@/g, " & "));
        }
        if (assetClass) {
          text.push("Asset Class: " + assetClass.replace(/@/g, " & "));
        }
        if (coupon) {
          text.push("Coupon: > " + coupon.replace(/@/g, " & "));
        }
        if (yieldParam) {
          text.push("Yield To Maturity: > " + yieldParam);
        }
        if (rating) {
          text.push("Rating: " + rating.replace(/@/g, " & "));
        }
        if (region) {
          text.push("Region: " + region.replace(/@/g, " & "));
        }
        if (marketType) {
          text.push("Market Class: " + marketType.replace(/@/g, " & "));
        }

        setFilterText(text);

        setSummary(data);
        if (view == "front office") {
          if (data.portfolio[0]) {
            let titles: any = Object.keys(data.portfolio[0]).filter((title: string, index: number) => !hiddenTitlesFO.includes(title));
            let index = 0;
            while (titles.length < 15 && index < data.portfolio.length) {
              titles = Object.keys(data.portfolio[index]).filter((title: string, index: number) => !hiddenTitlesFO.includes(title));
              index++;
            }
            setTitles(titles);
          }
        } else if (view == "back office") {
          setTitles(tableTitlesBackOffice);
        }
        if (data.portfolio.length > 0) {
          try {
            setPriceLastUpdate(formatDate(data.updatePriceDate));
          } catch (error) {
            setPriceLastUpdate("");
          }
        }
      });
  }, []);

  if (portfolio == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (portfolio.length == 0) {
    return (
      <div>
        <NavBar />
        <div>
          <input title="date" type="date" className="input-calendar" value={date} onChange={(event) => queryPortfolio(event, "order", 1, "", true, null, view)} />
        </div>
        <div className="title">
          <h4>No Record</h4>
        </div>
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  async function queryPortfolio(event: any, sort: string, newSign: number, title: string, calendar: boolean, performersTypeInput: string | null, viewInput: string | null) {
    let type = performersType;
    let viewUrl: string = view;
    if (performersTypeInput) {
      setPerformersType(performersTypeInput);
      type = performersTypeInput;
    }
    if (viewInput) {
      setView(viewInput);
      viewUrl = viewInput;
    }
    let newDate = getDateTimeInMongoDBCollectionFormat(date);
    if (calendar) {
      setDate(event.target.value);

      newDate = getDateTimeInMongoDBCollectionFormat(event.target.value);
    }

    setRequestStatus(true);

    url = baseUrl + `performers-portfolio?date=${newDate}&sort=${sort}&sign=${newSign}&type=${type}&view=${viewUrl}`;
    if (country) {
      url += "&country=" + country;
    }
    if (sector) {
      url += "&sector=" + sector;
    }
    if (strategy) {
      url += "&strategy=" + strategy;
    }
    if (durationStart) {
      url += "&durationStart=" + durationStart;
    }
    if (durationEnd) {
      url += "&durationEnd=" + durationEnd;
    }
    if (currency) {
      url += "&currency=" + currency;
    }
    if (issuer) {
      url += "&issuer=" + issuer;
    }
    if (longShort) {
      url += "&longShort=" + longShort;
    }
    if (ticker) {
      url += "&ticker=" + ticker;
    }
    if (assetClass) {
      url += "&assetClass=" + assetClass;
    }
    if (coupon) {
      url += "&coupon=" + coupon;
    }
    if (yieldParam !== "") {
      url += "&yield=" + yieldParam;
    }

    if (rating) {
      url += "&rating=" + rating;
    }
    if (region) {
      url += "&region=" + region;
    }
    if (marketType) {
      url += "&marketType=" + marketType;
    }

    let action: any = await axios(url, getAxiosRequestOptions);
    setPortfolio(action.data.portfolio);
    setTradeLastUpload(formatDate(action.data.uploadTradesDate));

    setSummary(action.data);
    if (viewUrl == "front office") {
      let titles: any = Object.keys(action.data.portfolio[0]).filter((title: string, index: number) => !hiddenTitlesFO.includes(title));
      let index = 0;
      while (titles.length < 15 && index < action.data.portfolio.length) {
        titles = Object.keys(action.data.portfolio[index]).filter((title: string, index: number) => !hiddenTitlesFO.includes(title));
        index++;
      }
      setTitles(titles);
    } else if (viewUrl == "back office") {
      setTitles(tableTitlesBackOffice);
    }
    if (action.data.portfolio.length > 0) {
      try {
        setPriceLastUpdate(formatDate(action.data.updatePriceDate));
      } catch (error) {
        setPriceLastUpdate("");
      }
      setRequestStatus(false);
    }
  }
  function reRouteSummary(event: any, sort: string, newSign: number, title: string) {
    let location = `/?sort=${sort}&newSign=${newSign}&title=${encodeURIComponent(title)}&dateSort=${date}`;

    window.location.href = location;
  }

  function getTrades(event: any, position: any) {
    let issue = position["BB Ticker"];
    let isin = position["ISIN"];
    let location = position["Location"];
    let tradeType = "vcons";
    let assetClass = position["Type"];

    let identifier = isin ? isin : issue;
    if (isin.includes("IB")) {
      tradeType = "ib";
    } else if (issue.includes("1393")) {
      tradeType = "emsx";
    } else if (assetClass.includes("CDS")) {
      tradeType = "gs";
    }
    const jsonString = JSON.stringify(position);
    const encodedData = encodeURIComponent(jsonString);
    window.location.href = `/view-trades?identifier=${identifier}&location=${location}&tradeType=${tradeType}&position=${encodedData}`;
  }

  return (
    <div>
      <NavBar />

      <div className="portfolio-range-updates-container">
        <div>
          <h4 className="lastUpdateText">Last Prices Update: {lastPriceUpdate}</h4>
          <h4 className="lastUpdateText">Last Trade Upload: {lastTradeUpload}</h4>
        </div>
        <div className="portfolio-range-picker-container input-search-calendar-container">
          <div className="search-container portfolio-search-container">
            <input
              className="form-control mr-sm-2 filter-trades-search-bar filter-portfolio-search-bar"
              type="search"
              placeholder="Search Position By BB Ticker, ISIN, or Location code"
              aria-label="Search"
              onChange={(e) => {
                handleSearch(e.target.value);
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </div>
          <input title="date" type="date" className="input-calendar" value={date} onChange={(event) => queryPortfolio(event, "order", 1, "", true, null, view)} />
        </div>
      </div>
      {portfolio == null || portfolio.length == 0 ? "" : <ViewSummaryFund url={"/view-performers?"} summary={summary} titles={titles} getTrades={getTrades} queryPortfolio={queryPortfolio} setFilterCardDisplay={setFilterCardDisplay} date={date} statistics={true} filter={true} />}
      <div className=" log-container">
        <button className={"btn btn-trades btn-edit-logs " + (view == "front office" ? "tab-log-selected-front-office" : "tab-log-selected-back-office")} onClick={(event) => queryPortfolio(event, "order", 1, "", false, performersType, view == "front office" ? "back office" : "front office")}>
          {view == "front office" ? "Front Office View" : "Back Office View"}
        </button>
        <button className={"btn btn-trades btn-edit-logs " + (performersType == "pl" ? "tab-log-selected" : "")} onClick={(event) => queryPortfolio(event, "order", 1, "", false, "pl", view)}>
          By PL
        </button>
        <button className={"btn btn-trades btn-edit-logs " + (performersType == "price move" ? "tab-log-selected" : "")} onClick={(event) => queryPortfolio(event, "groupDayPriceMoveSum", 1, "", false, "price move", view)}>
          By Price Move
        </button>{" "}
      </div>
      {filterText.length ? (
        <div className="filter-params">
          <p className="title-unassigned">Filtered</p>{" "}
          <button className="btn upload-btn no-flex btn-clear" onClick={(event) => (window.location.href = "/view-performers")}>
            Clear
          </button>
        </div>
      ) : (
        ""
      )}
      <ul className="list-filter">
        {filterText.map((text: any, index: any) => (
          <li>{text}</li>
        ))}
      </ul>
      <div style={{ display: filterCardDisplay }} onDoubleClick={(event) => setFilterCardDisplay("none")}>
        {portfolio == null || portfolio.length == 0 ? (
          ""
        ) : (
          <FilterCard
            countries={summary.analysis.countryNAVPercentage}
            sectors={summary.analysis.sectorNAVPercentage}
            currencies={summary.analysis.currencies}
            strategies={summary.analysis.strategyNAVPercentage}
            issuers={summary.analysis.issuerNAVPercentage}
            tickerTable={summary.analysis.tickerTable}
            ratings={summary.analysis.ratingNAVPercentage}
            regions={summary.analysis.regionNAVPercentage}
            marketTypes={summary.analysis.marketTypeNAVPercentage}
            setFilterCardDisplay={setFilterCardDisplay}
            performersType={performersType}
            view={view}
            url={"/view-performers?"}
          />
        )}
      </div>
      <ViewPerformersTables performersType={performersType} summary={summary} titles={titles} getTrades={getTrades} reRouteSummary={reRouteSummary} positionInfo={positionInfo} view={view} date={date} />
    </div>
  );
}
export default ViewPerformers;
