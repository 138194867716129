import { useState, useEffect, useCallback, useRef } from "react";
import NavBar from "../../common/navbar";
import { baseUrl, getRequestOptions, handleAuth, postRequestOptions } from "../../common/cookie";
import Loader from "../../common/loader";
import axios from "axios";
import { CloseIcon, getDateTimeInMongoDBCollectionFormat } from "../../common/functions";
import * as XLSX from "xlsx";
import { Add } from "../../common/functions";

let tableTitles: any = ["month", "nav", "holdback_ratio", "share_price", "borrowing_amount", "expenses", "id"];

function FundDetails() {
  let [fundDetails, setFundDetails] = useState<any>([]);
  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const contextMenuRef: any = useRef(null);
  let [authStatus, setAuthStatus] = useState("");
  const [request, setRequestStatus] = useState(false);
  let [fundInfoDisplay, setFundInfoDisplay] = useState("none");
  let [fundDetailsEdit, setFundDetailsEdit] = useState<any>({});

  let [addFundInfoDisplay, setAddFundInfoDisplay] = useState("none");

  const handleContextMenu = useCallback((event: any, fundDetails: any) => {
    event.preventDefault();

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the position of the context menu accounting for the scroll
    const x = event.clientX + scrollX;
    const y = event.clientY + scrollY;

    setFundDetailsEdit(fundDetails);
    setContextMenuState({
      visible: true,
      x: x,
      y: y,
    });
  }, []);
  const handleClick = useCallback((event: any) => {
    // Hide context menu if clicking outside
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenuState({ visible: false, x: 0, y: 0 });
    }
  }, []);
  let today: any = getDateTimeInMongoDBCollectionFormat(new Date());
  let url: any = baseUrl + `fund-details?date=${today}`;

  useEffect(() => {
    setRequestStatus(true);
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setFundDetails(data);
        setRequestStatus(false);
      });
  }, []);

  if (fundDetails == null || request == true) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  function downloadCSV() {
    let table = document.getElementById("table-id");

    // Use XLSX.utils.table_to_book to convert the table to a workbook
    let wb = XLSX.utils.table_to_book(table, { sheet: "Sheet 1" });

    // Write the workbook to a file
    XLSX.writeFile(wb, `fund_details_${today}.xlsx`);
  }

  async function handleEditAddFund(event: any, url: string, props: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData = new FormData(event.target);
    try {
      let auth = await axios.post(baseUrl + url, formData, postRequestOptions);

      if (auth.data.error) {
        setAuthStatus(auth.data.error);
      } else {
        setTimeout(() => 2000);
        window.location.reload();
      }
    } catch (error) {
      setAuthStatus("error");
    }

    setRequestStatus(false);
  }

  function ShowFundInfo(props: any) {
    return (
      <div className="position-container edit-info-container-2">
        <div onClick={(event) => props.setDisplay("none")} className="close-icon-container-edit-position">
          <CloseIcon />
        </div>
        <form
          onSubmit={(event) => handleEditAddFund(event, props.url, props)}
          style={{
            overflowX: "auto",
          }}
        >
          <table id="table-id" className="table table-hover table-portfolio table-striped table-fund-details">
            <tbody>
              <tr className="sticky-top">
                {tableTitles.map((title: string, index: number) => (
                  <td key={index}>{title}</td>
                ))}
              </tr>
              <tr className="">
                {tableTitles.map((title: string, index: number) => (
                  <td key={index}>
                    {props.fundDetails && title == "id" ? (
                      <input name={title} placeholder={props.fundDetails[title]} value={props.fundDetails[title]} className="paramDisabled"></input>
                    ) : title != "id" ? (
                      <input name={title} placeholder={props.fundDetails ? props.fundDetails[title] : ""}></input>
                    ) : (
                      ""
                    )}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <div className="edit-info-edit-container">
            <button type="submit" className="btn upload-btn position-info-edit">
              {" "}
              {props.fundDetails ? "Edit" : "Add"}
            </button>
          </div>
        </form>
        {authStatus && <h4 className="error error-position-info">{authStatus}</h4>}
      </div>
    );
  }

  async function handleDeleteFund(event: any) {
    setRequestStatus(true);

    let action = window.confirm("Confirm delete fund for month: " + fundDetailsEdit.month);
    try {
      if (action) {
        let auth = await axios.post(baseUrl + "delete-fund", fundDetailsEdit, postRequestOptions);

        if (auth.status == 200) {
          window.location.href = "/fund-details";
        } else {
          setAuthStatus(auth.data.error);
        }
      }
    } catch (error) {
      setAuthStatus("error");
    }

    setRequestStatus(false);
  }

  return (
    <div>
      <NavBar />

      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
        className="table-container-custom"
      >
        <table id="table-id" onClick={handleClick} className="table table-portfolio  table-striped table-fund-details">
          <tbody>
            <tr className="sticky-top">
              {tableTitles.map((title: string, index: number) => (
                <td key={index}>{title}</td>
              ))}
            </tr>
            {fundDetails.map((details: any, index: number) => (
              <tr onContextMenu={(event) => handleContextMenu(event, details)} key={index}>
                {tableTitles.map((title: string, index: number) => (
                  <td key={index}>{details[tableTitles[index]]}</td>
                ))}
              </tr>
            ))}
            {contextMenuState.visible && (
              <div
                ref={contextMenuRef}
                style={{
                  position: "absolute",
                  top: contextMenuState.y,
                  left: contextMenuState.x,
                }}
                className="context-menu-container"
              >
                <div className="context-menu">
                  <div className="context-menue-row">
                    <p className="context-menu-text" onClick={(event) => setFundInfoDisplay("block")}>
                      Edit Details
                    </p>
                  </div>
                </div>
                <hr className="hr" />
                <div className="context-menu">
                  <div className="context-menue-row">
                    <p className="context-menu-text" onClick={(event) => handleDeleteFund(event)}>
                      Delete Details
                    </p>
                  </div>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
      <div onClick={(event) => setAddFundInfoDisplay("block")}>
        <Add />
      </div>
      <button id="download-btn" onClick={downloadCSV} className="btn upload-btn">
        Download CSV
      </button>
      <div className="edit-info-container-1" style={{ display: fundInfoDisplay }} onDoubleClick={(event: any) => setFundInfoDisplay("none")}>
        <ShowFundInfo url={"edit-fund"} fundDetails={fundDetailsEdit} setDisplay={setFundInfoDisplay} />
      </div>
      <div className="edit-info-container-1" style={{ display: addFundInfoDisplay }} onDoubleClick={(event: any) => setAddFundInfoDisplay("none")}>
        <ShowFundInfo url={"add-fund"} setDisplay={setAddFundInfoDisplay} />
      </div>
    </div>
  );
}
export default FundDetails;
