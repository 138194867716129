import React, { useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import "chart.js/auto";
import { formatDateTitle } from "../../../common/functions";

function getValue(documents: any, keys: any, param: any): number[] {
  let final = [];
  for (let index = 0; index < keys.length; index++) {
    let key = keys[index];
    let data = documents[key][param];
    final.push(data);
  }
  return final;
}

const Compare = ({ analytics, start, end, parameters, yTitle }: any) => {
  // Prepare datasets for Chart.js
  let keys = Object.keys(analytics.final);
  let colors = ["#8B000080", "#00640080", "#00008B80", "#FF8C0080", "#4B008280"];
  let paramsKeys = Object.keys(parameters);
  const [copyKeys, setCopyKeys] = useState([...paramsKeys]);

  const toggleParameter = (index: number) => {
    setCopyKeys((prevCopyKeys) => {
      const newCopyKeys = [...prevCopyKeys];
      newCopyKeys[index] = newCopyKeys[index] === "NA" ? paramsKeys[index] : "NA";
      return newCopyKeys;
    });
  };

  const datasets = copyKeys
    .map((key, index) => {
      if (key !== "NA") {
        return {
          label: parameters[key],
          data: getValue(analytics.final, keys, key),
          fill: true,
          borderColor: colors[index],
          backgroundColor: colors[index],
          tension: 0.4,
        };
      }
      return null;
    })
    .filter((dataset) => dataset !== null);

  const labels = keys;

  const data: any = {
    labels: labels,
    datasets: datasets,
  };

  const options: any = {
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Fund Performance (${formatDateTitle(start) + " - " + formatDateTitle(end)})`,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: yTitle,
        },
        ticks: {
          callback: function (value: any, index: any, values: any) {
            return (yTitle || "").toString().includes("%") ? Math.round(value * 100) / 100 + "%" : value.toLocaleString();
          },
        },
      },
    },
  };

  return (
    <div className="overflow-container">
      <div className="table-responsive">
        <div>
          <div className="log-container">
            {paramsKeys.map((paramKey, index) => (
              <button key={index} className={`btn btn-trades btn-edit-logs ${copyKeys[index] === "NA" ? "fact-sheet-selected" : ""}`} onClick={() => toggleParameter(index)}>
                {parameters[paramKey]}
              </button>
            ))}
          </div>
        </div>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default Compare;
