function AbsoluteReturn(props: any) {
  let annulizedReturn = props.annulizedReturn;
  let factSheetTwoYears = props.factSheetTwoYears;
  let factSheetChinaPeriod = props.factSheetChinaPeriod;
  let periods = props.periods;
  let factSheetFiveYears = props.factSheetFiveYears;
  let treasuryAnnualRate = props.treasuryAnnualRate;
  let type = props.type;

  return (
    <div className="col-12 fact-sheet-sub-container">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats-1">
        <tbody>
          <tr className="sticky-top table-header">
            <th>Absolute Return Statistic</th>
            {periods.map((period: string, index: string) => (
              <th>{period}</th>
            ))}
          </tr>
          <tr className="table-body fact-sheet-row">
            <td className="fact-sheet-col-grey">Annualised Return (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.annulizedReturn[type].annualPer * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.annulizedReturn[type].annualPer * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.annulizedReturn[type].annualPer * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(annulizedReturn.annualPer * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
          <tr className="table-body fact-sheet-row">
            <td className="fact-sheet-col-grey">Annualised Risk Free Return (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.treasuryAnnualRate * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.treasuryAnnualRate * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.treasuryAnnualRate * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(treasuryAnnualRate * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
          <tr className="table-body fact-sheet-row ">
            <td className="fact-sheet-col-grey">Best Month (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.annulizedReturn[type].bestMonth * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.annulizedReturn[type].bestMonth * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.annulizedReturn[type].bestMonth * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(annulizedReturn.bestMonth * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
          <tr className="table-body fact-sheet-row ">
            <td className="fact-sheet-col-grey">Worst Month (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.annulizedReturn[type].worstMonth * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.annulizedReturn[type].worstMonth * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.annulizedReturn[type].worstMonth * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(annulizedReturn.worstMonth * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default AbsoluteReturn;
