import { useState, useEffect } from "react";
import NavBar from "../../../common/navbar";
import { baseUrl, getAxiosRequestOptions, getRequestOptions, handleAuth } from "../../../common/cookie";
import Loader from "../../../common/loader";
import axios from "axios";
import { getDateTimeInMongoDBCollectionFormat, getCurrentDateFormatted } from "../../../common/functions";
import ViewRiskTables from "./viewRiskTables";
import { formatDate } from "../../../common/functions";
import { cr01Titles, defaultTitlesBO, dv01Titles, stopLossTitlesBO } from "../tools/titlesPortfolio";
import { useLocation } from "react-router-dom";
import Statistics from "../portfolio/statistics";

function ViewRiskReport() {
  let [portfolio, setPortfolio] = useState<any>();

  let [summary, setSummary] = useState<any>({});
  let [lastPriceUpdate, setPriceLastUpdate] = useState("");
  let [lastTradeUpload, setTradeLastUpload] = useState("");
  let [positionInfo, setPositionInfo] = useState([]);

  const [request, setRequestStatus] = useState(false);

  let today: any = getCurrentDateFormatted(new Date());

  let [date, setDate] = useState(today);
  let url: any = baseUrl + `risk-report?date=${today}&sort=order&sign=1`;
  let query = new URLSearchParams(useLocation().search);
  let type = query.get("type") || "DV01 Stress Test";
  let titles;
  if (type == "DV01 Stress Test") {
    titles = dv01Titles;
  } else if (type == "Default Shock Stress Test") {
    titles = defaultTitlesBO;
  } else if (type == "Stop Loss") {
    titles = stopLossTitlesBO;
  }else if (type == "CR01 Stress Test") {
    titles = cr01Titles;
  }

  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setPortfolio(data.portfolio);
        setTradeLastUpload(formatDate(data.uploadTradesDate));

        setSummary(data);

        if (data.portfolio.length > 0) {
          try {
            setPriceLastUpdate(formatDate(data.updatePriceDate));
          } catch (error) {
            setPriceLastUpdate("");
          }
        }
      });
  }, []);

  async function queryPortfolio(event: any, sort: string, newSign: number, title: string, calendar: boolean) {
    let newDate = getDateTimeInMongoDBCollectionFormat(date);
    if (calendar) {
      setDate(event.target.value);

      newDate = getDateTimeInMongoDBCollectionFormat(event.target.value);
    }

    setRequestStatus(true);

    url = baseUrl + `risk-report?date=${newDate}&sort=${sort}&sign=${newSign}`;
    // console.log(url);
    let action = await axios(url, getAxiosRequestOptions);
    setTradeLastUpload(formatDate(action.data.uploadTradesDate));
    setPortfolio(action.data.portfolio);

    setSummary(action.data);
    if (action.data.portfolio.length > 0) {
      try {
        setPriceLastUpdate(formatDate(action.data.updatePriceDate));
      } catch (error) {
        setPriceLastUpdate("");
      }
    }
    setRequestStatus(false);
  }

  function getTrades(event: any, position: any) {
    let issue = position["BB Ticker"];
    let isin = position["ISIN"];
    let location = position["Location"];
    let tradeType = "vcons";
    let identifier = isin ? isin : issue;
    let assetClass = position["Asset Class"];

    if (issue.includes("IB")) {
      tradeType = "ib";
    } else if (issue.includes("1393")) {
      tradeType = "emsx";
    } else if (assetClass.includes("gs")) {
      tradeType = "gs";
    }
    window.location.href = `/view-trades?identifier=${identifier}&location=${location}&tradeType=${tradeType}`;
  }

  function changeTestType(event: any, type: any) {
    if (type == "DV01 Stress Test") {
      window.location.href = "/view-risk-report?type=" + type;
    } else if (type == "Default Shock Stress Test") {
      window.location.href = "/view-risk-report?type=" + type;
    } else if (type == "Stop Loss") {
      window.location.href = "/view-risk-report?type=" + type;
    }else if (type == "CR01 Stress Test") {
      window.location.href = "/view-risk-report?type=" + type;
    }
  }

  if (portfolio == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (portfolio.length == 0) {
    return (
      <div>
        <NavBar />
        <div>
          <input title="date" type="date" className="input-calendar" value={date} onChange={(event) => queryPortfolio(event, "order", 1, "", true)} />
        </div>
        <div className="title">
          <h4>No Record</h4>
        </div>
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <NavBar />

      <div className="portfolio-range-updates-container">
        <div>
          <h4 className="lastUpdateText">Last Update: {lastPriceUpdate}</h4>
        </div>
        <div className="portfolio-range-picker-container input-search-calendar-container">
          <div className="search-container portfolio-search-container"></div>
          <input title="date" type="date" className="input-calendar" value={date} onChange={(event) => queryPortfolio(event, "order", 1, "", true)} />
        </div>
      </div>
      <div className="row">
        <div className=" log-container ">
          <button className={"btn btn-trades btn-edit-logs " + (type == "DV01 Stress Test" ? "tab-log-selected" : "")} onClick={(event) => changeTestType(event, "DV01 Stress Test")}>
            DV01 Stress Test
          </button>
          <button className={"btn btn-trades btn-edit-logs " + (type == "CR01 Stress Test" ? "tab-log-selected" : "")} onClick={(event) => changeTestType(event, "CR01 Stress Test")}>
            CR01 Stress Test
          </button>

          <button className={"btn btn-trades btn-edit-logs " + (type == "Default Shock Stress Test" ? "tab-log-selected" : "")} onClick={(event) => changeTestType(event, "Default Shock Stress Test")}>
            Default Shock Stress Test
          </button>

          <button className={"btn btn-trades btn-edit-logs " + (type == "Stop Loss" ? "tab-log-selected" : "")} onClick={(event) => changeTestType(event, "Stop Loss")}>
            Stop Loss
          </button>
        </div>
      </div>
      <ViewRiskTables summary={summary} titles={titles} getTrades={getTrades} queryPortfolio={queryPortfolio} positionInfo={positionInfo} type={type} analysis={summary.analysis} url={url} date={date} />
    </div>
  );
}
export default ViewRiskReport;
