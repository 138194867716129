function RiskRatios(props: any) {
  let benchmarksHeaders = props.benchmarksHeaders;
  let benchmarksNaming = props.benchmarksNaming;
  let riskRatios = props.riskRatios

  return (
    <div className="col-12 fact-sheet-sub-container">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats-1 fact-sheet-outperformance-table">
        <tbody>
          <tr className="sticky-top table-header">
            {benchmarksHeaders.map((title: string, index: number) => (
              <th className={title == "Fund" ? "fact-sheet-long-title" : ""} key={index}>
                {benchmarksNaming[title] || "Risk-adjusted Statistics"}
              </th>
            ))}
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Calmar Ratio</td>

            <td>{(Math.round(riskRatios[benchmarksHeaders[1]]["Calmar Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[2]]["Calmar Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[3]]["Calmar Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[4]]["Calmar Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[5]]["Calmar Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[6]]["Calmar Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Sharpe Ratio</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[1]]["Sharpe Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[2]]["Sharpe Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[3]]["Sharpe Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[4]]["Sharpe Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[5]]["Sharpe Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[6]]["Sharpe Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Sortino Ratio</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[1]]["Sortino Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[2]]["Sortino Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[3]]["Sortino Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[4]]["Sortino Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[5]]["Sortino Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[6]]["Sortino Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Sterling Ratio</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[1]]["Sterling Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[2]]["Sterling Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[3]]["Sterling Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[4]]["Sterling Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[5]]["Sterling Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[6]]["Sterling Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Treynor Ratio</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[1]]["Treynor Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[2]]["Treynor Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[3]]["Treynor Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[4]]["Treynor Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[5]]["Treynor Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios[benchmarksHeaders[6]]["Treynor Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
        </tbody>
      </table>
    </div>
  );
}
export default RiskRatios;
