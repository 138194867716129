import { title } from "process";

let tableTitlesBackOffice: any = [
  "Type",
  "Strategy",
  "Location",
  "Currency",
  "BB Ticker",
  "Maturity",
  "Call Date",
  "Notional Amount",

  "Issuer",

  "Value (BC)",
  "Value (LC)",
  "Average Cost MTD",
  "Broker",

  "MTD Mark",
  "Previous Mark",
  "Mid",
  "Bid",
  "Ask",
  "Day Price Move",
  "MTD Price Move",
  "3-Day Price Move",
  "Bloomberg Mid BGN",
  "MTD P&L (BC)",
  "MTD Rlzd (BC)",
  "MTD URlzd (BC)",
  "MTD Int. (BC)",

  "Day P&L (BC)",
  "Day Rlzd (BC)",
  // "DV01",
  // "Z Spread",
  // "DV01 Cal",

  "Day URlzd (BC)",
  "Day Int. (BC)",

  "YTD Int. (BC)",

  "MTD P&L (LC)",
  "MTD Rlzd (LC)",
  "MTD URlzd (LC)",
  "MTD Int. (LC)",

  "Day P&L (LC)",
  "Day Rlzd (LC)",
  "Day URlzd (LC)",
  "Day Int. (LC)",

  "YTD Int. (LC)",
  "Entry Price",

  "FX Rate",
  "MTD FX",
  "Previous FX",
  "Day P&L FX",
  "MTD P&L FX",

  "Cost MTD (LC)",
  "Cost (LC)",
  "Duration",
  "YTM",

  "Last Day Since Realizd",
  "Total Gain/ Loss (USD)",
  // "BB Ticker",
  "Sector",
  "Country",
  "Issuer's Country",

  "Region",
  "Market Type",
  "Quantity",
  "Original Face",
  "Coupon Rate",
  "Coupon Duration",
  "Coupon Frequency",

  "Day URlzd",
  "Day Rlzd",
  "Asset Class",

  "DV01",
  "OAS",
  "Spread Change",
  "OAS W Change",

  "Z Spread",
  "Average Cost",
  "ISIN",
  "id",
  "Rating Score",
  "BBG Composite Rating",
  "S&P Bond Rating",
  "S&P Outlook",
  "Moody's Bond Rating",
  "Moody's Outlook",
  "Fitch Bond Rating",
  "Fitch Outlook",
  "Entry Yield",
  "CUSIP",
  "Bloomberg ID",
  "Bloomberg Mid BGN",

  "YTW",
  "Entry Price",

  "MTD Int. (BC)",
  "YTD Int. (BC)",
  "Accrued Int. Since Inception (BC)",

  "Principal",
  "MTD Notional",

  "Base LTV",
  "LTV",
  "Borrow Capacity",
  "CR01",
  "Previous Settle Date",
  "Next Settle Date",

  "Notes",
  "Security Description",
];
export { tableTitlesBackOffice };

export let editTitlesBO = [...tableTitlesBackOffice, "Event Type", "Factor Date (if any)", "Edit Note"];
export let longTitlesBO = ["id", "BB Ticker", "Long Security Name", "Security", "Notes", "Issuer", "Borrow Capacity"];

export let smallTitlesBO = ["Type", "Strategy", "Location", "Currency"];
export let mediumTitlesBO = ["USD Market Value", "Notional Amount", "Call Date", "Maturity"];
export let unEditableTitlesBO = [
  "Rating Score",
  "Value",
  "Duration",
  "Base LTV",
  "MTD Notional",
  "LTV",
  "MTD Notional",
  "MTD Mark",
  "Previous Mark",
  "Entry Price",

  "Day P&L (BC)",
  "MTD Rlzd (BC)",
  "MTD URlzd (BC)",
  "MTD Int.Income (BC)",
  "MTD P&L (BC)",
  "Cost (LC)",
  "Day Accrual",
  "id",

  "Day Price Move",
  "Value (BC)",
  "Value (LC)",
  "MTD Int. (BC)",
  "Day URlzd (BC)",
  "Day Rlzd (BC)",
  "Day Int. (LC)",
  "Day Accrual (LC)",
  "Cost MTD (LC)",
  "Quantity",
  "Day Int. (BC)",

  "Borrow Capacity",
  "Margin",
  "Day P&L FX",
  "MTD P&L FX",
  "S&P Bond Rating",
  "MTD FX",
  "Day URlzd",

  "Day P&L (LC)",
  "MTD Rlzd (LC)",
  "MTD URlzd (LC)",
  "MTD Int.Income (LC)",
  "MTD P&L (LC)",
  "Previous FX",
  "Day Rlzd",
  "Spread Change",
  "OAS W Change",
  "Last Day Since Realizd",
  "Day Rlzd (LC)",
  "Day URlzd (LC)",
  "MTD Int. (LC)",
  "Currency)	Day Int. (LC)",
  "YTD P&L (LC)",
  "YTD Int. (LC)",

  "YTD URlzd (BC)",
  "YTD Int. (BC)",
  "Total Gain/ Loss (USD)",

  "Accrued Int. Since Inception (BC)",
  "Notes",
  "MTD Price Move",
  "Average Cost MTD",
  "3-Day Price Move",
  "Market Type",
  "Region",
  "Principal",
  "Average Cost MTD",
  "3-Day Price Move",
  "Market Type",
  "Principal",
];

export let dv01Titles: any = [
  "BB Ticker",
  "Spread Change",
  "USD Market Value",
  "Notional Amount",
  "OAS",
  "OAS W Change",
  "DV01",
  "DV01 Dollar Value Impact",
  "DV01 Dollar Value Impact % of Nav",
  "DV01 Dollar Value Impact Limit % of Nav",
  "DV01 Dollar Value Impact Utilization % of Nav",

  "DV01 Dollar Value Impact Test",
  "Rating Score",
];
export let cr01Titles: any = [
  "BB Ticker",
  "Spread Change",
  "USD Market Value",
  "Notional Amount",
  "OAS",
  "OAS W Change",
  "CR01",
  "CR01 Dollar Value Impact",
  "CR01 Dollar Value Impact % of Nav",
  "CR01 Dollar Value Impact Limit % of Nav",
  "CR01 Dollar Value Impact Utilization % of Nav",

  "CR01 Dollar Value Impact Test",
  "Rating Score",
];

export let stopLossTitlesBO: any = [
  "BB Ticker",
  "Spread Change",
  "USD Market Value",
  "Notional Amount",
  "OAS",
  "OAS W Change",
  "DV01",

  "Capital Gain/ Loss since Inception (Live Position)",
  "% of Capital Gain/ Loss since Inception (Live Position)",
  "Accrued Int. Since Inception (BC)",
  "Total Gain/ Loss (USD)",
  "% of Total Gain/ Loss since Inception (Live Position)",
  "Rating Score",
];

export let defaultTitlesBO: any = ["BB Ticker", "Spread Change", "USD Market Value", "Notional Amount", "OAS", "OAS W Change", "DV01", "Value (BC) % of Nav", "Value (BC) % of GMV", "Value (BC) Limit % of Nav", "Value (BC) Utilization % of Nav", "Value (BC) Test", "Rating Score"];

export let tableTitlesTrades: any = [
  "B/S",
  "BB Ticker",
  "Location",
  "Trade Date",
  "Trade Time",
  "Settle Date",
  "Price",
  "Notional Amount",
  "Settlement Amount",
  "Principal",
  "Counter Party",
  "Triada Trade Id",
  "Seq No",
  "ISIN",
  "Cuisp",
  "Currency",
  "Yield",
  "Accrued Interest",
  "Original Face",
  "Comm/Fee",
  "Trade Type",
  // "Trade App Status",
  "Updated Notional",
];
