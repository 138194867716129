import { useState, useEffect, useCallback, useRef } from "react";
import NavBar from "../../../common/navbar";
import { baseUrl, getRequestOptions, handleAuth, postRequestOptions } from "../../../common/cookie";
import Loader from "../../../common/loader";
import { CloseIcon, CopyableCell, getDateTimeInMongoDBCollectionFormat } from "../../../common/functions";
import * as XLSX from "xlsx";

function ViewRlzdTrades(props: any) {
  let [trades, setTrades] = useState<any>();
  let tableTitlesTrades: any = ["B/S", "BB Ticker", "Location", "Trade Date", "Trade Time", "Settle Date", "Price", "Notional Amount", "Settlement Amount", "Accrued Interest", "Triada Trade Id", "Price Diff", "Updated Notional", "Rlzd", "Rlzd P&L Amount", "Average Cost MTD"];

  const [request, setRequestStatus] = useState(false);
  let tradeType = "vcons";
  let identifier = props.positionInfo["ISIN"];
  if (identifier) {
    let cdsCheck = props.positionInfo["Type"];
    if (identifier.includes("IB")) {
      tradeType = "ib";
    } else if (identifier.includes("1393")) {
      tradeType = "emsx";
    } else if (cdsCheck.includes("CDS")) {
      tradeType = "cds_gs";
    }
  }

  function downloadCSV() {
    let table = document.getElementById("rlzd-trades");

    // Use XLSX.utils.table_to_book to convert the table to a workbook
    let wb = XLSX.utils.table_to_book(table, { sheet: "Sheet 1" });

    // Write the workbook to a file
    XLSX.writeFile(wb, `mtd_rlzd_trades_${props.positionInfo["BB Ticker"]}.xlsx`);
  }
  console.log(props.date);
  let url: any =
    baseUrl +
    `rlzd-trades?tradeType=${tradeType}&isin=${props.positionInfo["ISIN"]}&location=${props.positionInfo["Location"]}&date=${props.date}&now=${new Date()}&mtdMark=${props.positionInfo["MTD Mark"]}&mtdNotional=${props.positionInfo["MTD Notional"]}&type=${props.positionInfo["Type"]}&ticker=${
      props.positionInfo["BB Ticker"]
    }`;
  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setTrades(data);
      });
  }, [url]);
  if (trades == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  return (
    <div className="edit-info-container-1">
      <div onClick={(event) => props.setRlzdTradesCardDisplay("none")}>
        <CloseIcon classNameInput="rlzd-close-icon" />
      </div>

      <div className="trades-inputs position-container rlzd-trades-info-container-2 rlzd-trades-card">
        <div className="rlzd-trades-titles">
          <p>
            BB Ticker: {props.positionInfo["BB Ticker"]}, ISIN: {props.positionInfo["ISIN"]}, Location: {props.positionInfo["Location"]}, Current Notional: {props.positionInfo["Notional Amount"]}, MTD Mark: {props.positionInfo["MTD Mark"]}, MTD Notional: {props.positionInfo["MTD Notional"] || 0}{" "}
          </p>
        </div>
        <div
          style={{
            width: "100%",
            // overflowX: "auto",
          }}
          className="table-container-custom"
        >
          <table
            id="rlzd-trades"
            style={{
              width: "100%",
            }}
            className="table table-hover table-portfolio table-striped table-trades"
          >
            <tbody>
              <tr className="sticky-top table-header">
                {tableTitlesTrades.map((title: string, index: number) => (
                  <td key={index}>{title}</td>
                ))}
              </tr>

              {trades.map((trade: any[], index: number) => (
                <tr key={index} className="table-body">
                  {tableTitlesTrades.map((title: string, index: number) => (
                    <CopyableCell key={index} text={isFinite(trade[tableTitlesTrades[index]]) && trade[tableTitlesTrades[index]] ? trade[tableTitlesTrades[index]].toLocaleString() : trade[tableTitlesTrades[index]]} />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <button id="download-btn" onClick={downloadCSV} className="btn upload-btn">
            Download CSV
          </button>
        </div>
      </div>
    </div>
  );
}
export default ViewRlzdTrades;
