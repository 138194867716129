function RegionsNAV(props: any) {
  let regionsNAV = props.regionsNAV;
  delete regionsNAV["Total"];
  let regionsNames = Object.keys(regionsNAV).sort((a, b) => {
    return parseFloat(regionsNAV[b]["percentage"]) - parseFloat(regionsNAV[a]["percentage"]); // For all other cases, sort alphabetically
  });

  return (
    <div className="col-12 fact-sheet-sub-container fact-sheet-sub-container-sectors">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats">
        <tbody>
          <tr className="sticky-top table-header">
            <th>Region</th>
            <th>NAV %</th>
          </tr>
          {regionsNames.map((region: string, index: any) => (
            <tr className="fact-sheet-row">
              <td className="fact-sheet-col-grey">{region}</td>
              <td className="fact-sheet-col-white">{(Math.round(regionsNAV[region].percentage * 100) / 100).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default RegionsNAV;
