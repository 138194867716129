import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../../../common/navbar";
import { baseUrl, getAxiosRequestOptions, getRequestOptions, handleAuth, postRequestOptions } from "../../../common/cookie";
import Loader from "../../../common/loader";
import axios from "axios";
import { getDateTimeInMongoDBCollectionFormat, CopyableCell, getCurrentDateFormatted, Filter, RedStar, WhiteStar, getDurationName } from "../../../common/functions";
import * as XLSX from "xlsx";
import ViewSummaryFund from "./moreInfoTable";
import { formatDate } from "../../../common/functions";
import { TriangleUp, TriangleDown } from "../../../common/functions";
import FilterCard from "../tools/filterCard";
import { hiddenTitlesFO, groupSortTitles, groupSortTitlesAssignBorderKeys, longTitlesFO, smallTitlesFO, mediumTitlesFO, rightBorderTitlesFo, leftBorderTitlesFo } from "../tools/titlesSummary";
import { PinnedPosition, Position } from "../../../models/position";
export let width: any = {
  0: 0,
  1: "17px",
  2: "34px",
  3: "51px",
  4: "68px",
  5: "108px",
  6: "148px",
  // 7: "178px",
};
function ViewSummary() {
  let [portfolio, setPortfolio] = useState<any>();
  let [filteredPortfolio, setFilteredPortfolio] = useState<any>();
  let [summary, setSummary] = useState<any>({});
  let [lastPriceUpdate, setPriceLastUpdate] = useState("");
  let [lastTradeUpload, setTradeLastUpload] = useState("");
  let [positionInfo, setPositionInfo] = useState([]);
  let [filterCardDisplay, setFilterCardDisplay] = useState("none");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let searchKeys = ["BB Ticker", "Issuer", "Strategy", "Currency", "Country", "Sector"];

  const [request, setRequestStatus] = useState(false);

  let today: any = getCurrentDateFormatted(new Date());

  let [date, setDate] = useState(today);
  let [url, setUrl] = useState(baseUrl + `summary-portfolio?date=${today}&sort=order&sign=1`);
  let [reportName, setReportName] = useState(today);
  let [fullScreen, setFullScreen] = useState(false);

  let [titles, setTitles] = useState([]);
  let query = new URLSearchParams(useLocation().search);
  let [country, setCountry] = useState(query.get("country"));

  let [sector, setSector] = useState(query.get("sector"));
  let [strategy, setStrategy] = useState(query.get("strategy"));
  let [coupon, setCoupon] = useState(query.get("coupon"));
  let [yieldParam, setYieldParam] = useState(query.get("yield"));

  let [assetClass, setAssetClass] = useState(query.get("assetClass"));
  let [rating, setRating] = useState(query.get("rating"));
  let [marketType, setMarketType] = useState(query.get("marketType"));
  let [region, setRegion] = useState(query.get("region"));

  let [durationStart, setDurationStart]: any = useState(query.get("duration_start"));
  let [durationEnd, setDurationEnd]: any = useState(query.get("duration_end"));
  let [currency, setCurrency]: any = useState(query.get("currency"));
  let [issuer, setIssuer]: any = useState(query.get("issuer"));
  let longShort = query.get("longShort");
  let [filterText, setFilterText] = useState<any>([]);
  let [sort, setSort] = useState(query.get("sort"));
  let newSign: any = query.get("newSign");
  let title: any = query.get("title");
  let ticker: any = query.get("ticker");
  let dateSort: any = query.get("dateSort");
  let [pressedTitle, setPressedTitle] = useState(title || "");
  let [sign, setSign] = useState(parseInt(newSign) || 0);

  if (country) {
    url += "&country=" + country;
  }
  if (sector) {
    url += "&sector=" + sector;
  }
  if (strategy) {
    url += "&strategy=" + strategy;
  }
  if (durationStart) {
    url += "&durationStart=" + durationStart;
  }
  if (durationEnd) {
    url += "&durationEnd=" + durationEnd;
  }
  if (currency) {
    url += "&currency=" + currency;
  }
  if (issuer) {
    url += "&issuer=" + issuer;
  }
  if (longShort) {
    url += "&longShort=" + longShort;
  }
  if (ticker) {
    url += "&ticker=" + ticker;
  }
  if (assetClass) {
    url += "&assetClass=" + assetClass;
  }
  if (coupon) {
    url += "&coupon=" + coupon;
  }
  if (yieldParam !== "") {
    url += "&yield=" + yieldParam;
  }
  if (rating) {
    url += "&rating=" + rating;
  }
  if (region) {
    url += "&region=" + region;
  }
  if (marketType) {
    url += "&marketType=" + marketType;
  }

  useEffect(() => {
    if (sort) {
      today = getCurrentDateFormatted(new Date(dateSort));
      setPressedTitle(title);

      url = baseUrl + `summary-portfolio?date=${today}&sort=${sort}&sign=${newSign}`;
    }
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setPortfolio(data.portfolio);
        setTradeLastUpload(formatDate(data.uploadTradesDate));
        let filtered = data.portfolio;

        let text = [];
        if (country) {
          text.push("Country: " + country.replace(/@/g, " & "));
        }

        if (sector) {
          text.push("Sector: " + sector.replace(/@/g, " & "));
        }
        if (strategy) {
          text.push("Strategy: " + strategy.replace(/@/g, " & "));
        }
        if (durationStart) {
          if (!durationEnd) {
            durationEnd = "100 years";
          }
          durationStart = getDurationName(durationStart);
          durationEnd = getDurationName(durationEnd);

          text.push("Duration: " + durationStart + " - " + durationEnd);
        }

        if (issuer) {
          text.push("Issuer: " + issuer.replace(/@/g, " & "));
        }
        if (ticker) {
          text.push("BB Ticker: " + ticker.replace(/@/g, " & "));
        }
        if (currency) {
          text.push("Currency: " + currency.replace(/@/g, " & "));
        }
        if (assetClass) {
          text.push("Asset Class: " + assetClass.replace(/@/g, " & "));
        }
        if (coupon) {
          text.push("Coupon: > " + coupon);
        }
        if (yieldParam) {
          text.push("Yield To Maturity: > " + yieldParam);
        }
        if (rating) {
          text.push("Rating: " + rating.replace(/@/g, " & "));
        }
        if (region) {
          text.push("Region: " + region.replace(/@/g, " & "));
        }
        if (marketType) {
          text.push("Market Class: " + marketType.replace(/@/g, " & "));
        }

        if (data.portfolio.length > 0) {
          let titles: any = Object.keys(data.portfolio[0]).filter((title: string, index: number) => !hiddenTitlesFO.includes(title));
          let index = 0;
          while (titles.length < 20 && index < data.portfolio.length) {
            titles = Object.keys(data.portfolio[index]).filter((title: string, index: number) => !hiddenTitlesFO.includes(title));
            index++;
          }
          setFilterText(text);
          setFilteredPortfolio(filtered);
          setSummary(data);

          setTitles(titles);
          try {
            setPriceLastUpdate(formatDate(data.updatePriceDate));
          } catch (error) {
            setPriceLastUpdate("");
          }
        }
      });
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function queryPortfolio(event: any, sort: string, newSign: number, title: string, calendar: boolean) {
    let newDate = getDateTimeInMongoDBCollectionFormat(date);
    setSort(null);
    if (calendar) {
      setDate(event.target.value);

      newDate = getDateTimeInMongoDBCollectionFormat(event.target.value);
    }

    setPressedTitle(title);
    setRequestStatus(true);
    setReportName(newDate);

    url = baseUrl + `summary-portfolio?date=${newDate}&sort=${sort}&sign=${newSign}`;

    let action = await axios(url, getAxiosRequestOptions);

    if (action.data.portfolio) {
      if (action.data.portfolio.length > 0) {
        try {
          setPriceLastUpdate(formatDate(action.data.updatePriceDate));
          setTradeLastUpload(formatDate(action.data.uploadTradesDate));
          setPortfolio(action.data.portfolio);
          let titles: any = Object.keys(action.data.portfolio[0]).filter((title: string, index: number) => !hiddenTitlesFO.includes(title));
          let index = 0;
          while (titles.length < 15 && index < action.data.portfolio.length) {
            titles = Object.keys(action.data.portfolio[index]).filter((title: string, index: number) => !hiddenTitlesFO.includes(title));
            index++;
          }
          setTitles(titles);
          setFilteredPortfolio(action.data.portfolio);
          setSummary(action.data);
        } catch (error) {
          setPriceLastUpdate("");
        }
      }
      setRequestStatus(false);
      if (sort != "order") {
        setSign(newSign);
      } else {
        setSign(0);
      }
    } else {
      setPortfolio([]);
      setRequestStatus(false);
    }
  }
  function downloadCSV() {
    let table = document.getElementById("table-id");

    // Use XLSX.utils.table_to_book to convert the table to a workbook
    let wb = XLSX.utils.table_to_book(table, { sheet: "Sheet 1" });

    // Write the workbook to a file
    XLSX.writeFile(wb, `portfolio_${reportName}.xlsx`);
  }

  function getTrades(event: any, position: any) {
    let issue = position["BB Ticker"];
    let isin = position["ISIN"];
    let location = position["Location"];
    let tradeType = "vcons";
    let assetClass = position["Type"];

    let identifier = isin ? isin : issue;
    if (isin.includes("IB")) {
      tradeType = "ib";
    } else if (issue.includes("1393")) {
      tradeType = "emsx";
    } else if (assetClass.includes("CDS")) {
      tradeType = "gs";
    }
    const jsonString = JSON.stringify(position);
    const encodedData = encodeURIComponent(jsonString);
    window.location.href = `/view-trades?identifier=${identifier}&location=${location}&tradeType=${tradeType}&position=${encodedData}`;
  }

  function onChangePositionFilter(event: any) {
    let searchTerms = event.target.value.toLowerCase().split(" ");
    let matchedLocationCodes = new Set();

    // Step 1: Filter positions based on search terms and collect Location codes
    portfolio.forEach((position: any) => {
      if (!position) return;

      // Check if each property exists and includes any of the searchTerms
      let conditions = searchTerms.every((searchTerm: any) => searchKeys.some((key) => position[key]?.toString().toLowerCase().includes(searchTerm)));

      // If conditions are met, add the Location to matchedLocationCodes
      if (conditions) {
        matchedLocationCodes.add(position["Location"]);
      }
    });

    // Step 2: Include positions that match the search terms or share a Location with the matched positions
    let filteredAndIncludedPositions = portfolio.filter((position: any) => {
      if (!position) return false;

      // Check if this position's Location is in the set of matched Location codes
      let locationMatch = matchedLocationCodes.has(position["Location"]);

      // If the position matches the search terms or shares a Location, include it
      return locationMatch;
    });

    // filteredAndIncludedPositions now contains the filtered positions in the original order

    setFilteredPortfolio(filteredAndIncludedPositions);
  }

  async function handlePinPosition(event: any, position: Position) {
    setRequestStatus(true);
    event.preventDefault();

    position["Pin"] = position["Pin"] == "pinned" ? "not_pinned" : "pinned";
    let data: PinnedPosition = { ISIN: position["ISIN"], Location: position["Location"], Pin: position["Pin"], ticker: position["BB Ticker"], id: position["id"], portfolio_id: "portfolio_main", Date: date };

    try {
      let auth = await axios.post(baseUrl + "pin-position", data, postRequestOptions);

      if (!auth.data.error) {
        window.location.href = "/?";
      } else {
        window.alert(auth.data.error);
        setRequestStatus(false);
      }
    } catch (error) {}
  }

  if (portfolio == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (portfolio.length == 0) {
    return (
      <div>
        <NavBar />
        <div>
          <input title="date" type="date" className="input-calendar" value={date} onChange={(event) => queryPortfolio(event, "order", 1, "", true)} />
        </div>
        <div className="title">
          <h4>No Record</h4>
        </div>
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <NavBar />

      <div className="portfolio-range-updates-container">
        <div>
          <h4 className="lastUpdateText">Last Prices Update: {lastPriceUpdate}</h4>
          <h4 className="lastUpdateText">Last Trade Upload: {lastTradeUpload}</h4>
        </div>
        <div className="portfolio-range-picker-container input-search-calendar-container">
          <div className="search-container portfolio-search-container">
            <input
              className="form-control mr-sm-2 filter-trades-search-bar filter-portfolio-search-bar"
              type="search"
              placeholder="Search Position By BB Ticker, ISIN, or Location code"
              aria-label="Search"
              onChange={(event) => {
                onChangePositionFilter(event);
              }}
            />
          </div>
          <input title="date" type="date" className="input-calendar" value={date} onChange={(event) => queryPortfolio(event, "order", 1, "", true)} />
        </div>
      </div>
      {portfolio == null || portfolio.length == 0 ? "" : <ViewSummaryFund summary={summary} titles={titles} getTrades={getTrades} queryPortfolio={queryPortfolio} setFilterCardDisplay={setFilterCardDisplay} date={date} statistics={true} filter={true} />}
      <div style={!filterText.length ? { marginBottom: "-30px" } : {}}>
        {filterText.length ? (
          <div className="filter-params">
            <p className="title-unassigned">Filtered</p>{" "}
            <button className="btn upload-btn no-flex btn-clear" onClick={(event) => (window.location.href = "/")}>
              Clear
            </button>
          </div>
        ) : (
          ""
        )}
        <ul className="list-filter">
          {filterText.map((text: any, index: any) => (
            <li>{text}</li>
          ))}
        </ul>
      </div>
      <div className={(fullScreen ? "full-screen-element " : "") + "table-container-custom"}>
        <button onClick={(event) => setFullScreen(!fullScreen)} className="btn more-info-container" style={{ position: "sticky", padding: "5px", margin: "5px 3px 5px auto", border: "solid 1px black", zIndex: "1200", left: "3px" }}>
          Full Screen Mode
        </button>
        <table id="table-id" className="table table-summary table-summary-fo">
          <tbody>
            <tr className="sticky-top">
              <td style={{ left: screenWidth < 1000 ? width[0] : "" }} className={"copied-text higher-sticky-row small-table-cell"}>
                Pin
              </td>
              {titles.map((title: any, titleIndex) => {
                return (
                  <td
                    key={titleIndex}
                    className={
                      "copied-text " +
                      (titleIndex < 6 ? " higher-sticky-row " : " lower-sticky-row ") +
                      (longTitlesFO.includes(title) ? " wider-table-cell" : "") +
                      (titleIndex < 6 ? "higher-sticky-row" : "lower-sticky-row") +
                      (groupSortTitles.includes(title) ? " sort-titles" : "") +
                      (longTitlesFO.includes(title) ? " wider-table-cell" : "") +
                      (smallTitlesFO.includes(title) ? " small-table-cell" : "") +
                      (mediumTitlesFO.includes(title) ? " medium-table-cell" : "") +
                      (rightBorderTitlesFo.includes(title) ? " borders-right" : leftBorderTitlesFo.includes(title) ? " borders-left" : "")
                    }
                    style={{ left: screenWidth < 1000 ? width[titleIndex + 1] : "" }}
                    onClick={(event) => groupSortTitles.includes(title) && queryPortfolio(event, groupSortTitlesAssignBorderKeys[title], sign == 0 ? 1 : -1 * sign, title, false)}
                  >
                    {groupSortTitles.includes(title) && title == pressedTitle.toString() ? sign == 1 ? <TriangleUp /> : sign == -1 ? <TriangleDown /> : "" : ""}
                    {title}
                  </td>
                );
              })}
            </tr>
            {filteredPortfolio.map((position: any, index: any) => (
              <tr
                key={index}
                className={"table-body " + (position["Type"] && position["Type"].includes("Total") ? "borders" : "")}
                style={{
                  borderBottom: position["bottom"] ? "2px solid grey" : "",

                  cursor: "pointer",
                }}
                onDoubleClick={(event) => getTrades(event, position)}
              >
                <td className="sticky-summary" style={{ cursor: "pointer", position: "relative", backgroundColor: position["Color"], left: screenWidth < 1000 ? width[0] : "" }} onClick={(event) => handlePinPosition(event, position)}>
                  {position["Pin"] == "pinned" ? <RedStar /> : <WhiteStar />}
                </td>

                {titles.map((title, titleIndex) => (
                  <CopyableCell
                    key={titleIndex}
                    text={isFinite(position[title]) && position[title] ? position[title].toLocaleString() : position[title]}
                    classNameParam={rightBorderTitlesFo.includes(title) ? " borders-right " : leftBorderTitlesFo.includes(title) ? " borders-left " : ""}
                    index={titleIndex}
                    columns={6}
                    type={"summary"}
                    color={title == "3-Day Price Move" ? (Math.abs(parseFloat(position[title])) > 1.5 ? "red" : position["Color"]) : position["Color"]}
                    disabled={false}
                    style={{ cursor: "pointer", position: "relative", backgroundColor: title == "3-Day Price Move" ? "red !important" : position["Color"] }}
                    left={screenWidth < 1000 ? width[titleIndex + 1] : ""}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: filterCardDisplay }} onDoubleClick={(event) => setFilterCardDisplay("none")}>
        {portfolio == null || portfolio.length == 0 ? (
          ""
        ) : (
          <FilterCard
            countries={summary.analysis.countryNAVPercentage}
            sectors={summary.analysis.sectorNAVPercentage}
            currencies={summary.analysis.currencies}
            strategies={summary.analysis.strategyNAVPercentage}
            issuers={summary.analysis.issuerNAVPercentage}
            tickerTable={summary.analysis.tickerTable}
            ratings={summary.analysis.ratingNAVPercentage}
            regions={summary.analysis.regionNAVPercentage}
            marketTypes={summary.analysis.marketTypeNAVPercentage}
            setFilterCardDisplay={setFilterCardDisplay}
          />
        )}
      </div>
      <button id="download-btn" onClick={downloadCSV} className="btn upload-btn">
        Download CSV
      </button>
    </div>
  );
}
export default ViewSummary;
