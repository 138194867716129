import { useState } from "react";
import { CopyableCellSimple } from "../../../common/functions";

function PlTable(props: any) {
  let fundDetails = props.fundDetails;
  let analysis = props.analysis;

  return (
    <div style={{}} className=" fadeIn margin-top pnl-table-container">
      <table id="" className="table table-striped table-light table-hover  fund-table">
        <tbody>
          <tr className="sticky-top">
            <td>Type</td>
            <td>P&L (USD)</td>
            <td>Performance %</td>
            <td>Realizd (USD)</td>
            <td>Unrealized (USD)</td>
            <td>Int. Income (USD)</td>
            <td>Int. Income (USD) %</td>
            <td>FX P&L (USD)</td>
            <td>FX P&L (USD) %</td>
          </tr>
          <tr className="sticky-top">
            <td>Day (Gross)</td>

            <CopyableCellSimple text={Math.round(fundDetails.daypl).toLocaleString() || 0} />

            <CopyableCellSimple text={(fundDetails.dayplPercentage.toLocaleString() || 0) + " %"} />

            <CopyableCellSimple text={Math.round(fundDetails.dayrlzd).toLocaleString() || 0} />

            <CopyableCellSimple text={Math.round(fundDetails.dayurlzd).toLocaleString() || 0} />

            <CopyableCellSimple text={Math.round(fundDetails.dayint).toLocaleString() || 0} />

            <CopyableCellSimple text={(fundDetails.dayintPercentage.toLocaleString() || 0) + " %"} />
            <CopyableCellSimple text={Math.round(fundDetails.dayfx).toLocaleString() || 0} />

            <CopyableCellSimple text={(fundDetails.dayFXGross.toLocaleString() || 0) + " %"} />
          </tr>

          <tr className="sticky-top">
            <td>MTD (Gross || Net)</td>
            <CopyableCellSimple text={(Math.round(fundDetails.mtdpl).toLocaleString() || 0) + " || " + (Math.round(fundDetails.mtdplNet).toLocaleString() || 0)} />

            <CopyableCellSimple text={(fundDetails.mtdplPercentage || 0) + " % || " + (fundDetails.mtdplNetPercentage || 0) + " %"} />

            <CopyableCellSimple text={Math.round(fundDetails.mtdrlzd).toLocaleString() || 0} />

            <CopyableCellSimple text={Math.round(fundDetails.mtdurlzd).toLocaleString() || 0} />

            <CopyableCellSimple text={Math.round(fundDetails.mtdint).toLocaleString() || 0} />

            <CopyableCellSimple text={(fundDetails.mtdintPercentage.toLocaleString() || 0) + " %"} />
            <CopyableCellSimple text={Math.round(fundDetails.mtdfx).toLocaleString() || 0} />

            <CopyableCellSimple text={(fundDetails.mtdFXGross.toLocaleString() || 0) + " %"} />
          </tr>
          <tr className="sticky-top">
            <td>YTD (Net)</td>
            <CopyableCellSimple text={"x"} />

            <CopyableCellSimple text={(fundDetails.ytdNet.toLocaleString() || 0) + " %"} />

            <CopyableCellSimple text={"x"} />

            <CopyableCellSimple text={"x"} />

            <CopyableCellSimple text={"YTD: " + (Math.round(fundDetails.ytdint).toLocaleString() || 0) + " || EST: " + Math.round(fundDetails.ytdEstInt).toLocaleString() || 0} />

            <CopyableCellSimple text={"YTD: " + (fundDetails.ytdintPercentage.toLocaleString() || 0) + " %" + " || EST: " + (fundDetails.ytdEstIntPercentage.toLocaleString() || 0) + " %"} />
            <CopyableCellSimple text={"x"} />

            <CopyableCellSimple text={"x"} />
          </tr>
          <tr className="sticky-top">
            <td>Type</td>
            <td>Loan Amount (USD)</td>

            <td>Capacity Total (USD)</td>
            <td>Capacity IG (USD)</td>

            <td>Capacity HY (USD)</td>

            <td>Capacity Hedge (USD)</td>
            <td>DV01 Sum || CR01 Sum</td>
            <td>Starting NAV (End of {fundDetails.month})</td>
            <td>Estimate NAV</td>
          </tr>
          <tr className="sticky-top">
            <td>Amount (USD)</td>
            <CopyableCellSimple text={Math.round(fundDetails.borrowAmount).toLocaleString() || 0} />

            <CopyableCellSimple text={Math.round(analysis.capacity.amount).toLocaleString() || 0} />
            <CopyableCellSimple text={Math.round(analysis.capacity.amountIG).toLocaleString() || 0} />
            <CopyableCellSimple text={Math.round(analysis.capacity.amountHY).toLocaleString() || 0} />
            <CopyableCellSimple text={Math.round(analysis.capacity.amountHedge).toLocaleString() || 0} />
            <CopyableCellSimple text={(Math.round(fundDetails.dv01Sum).toLocaleString() || 0) + " || " + (Math.round(fundDetails.cr01Sum).toLocaleString() || 0)} />
            <CopyableCellSimple text={Math.round(fundDetails.nav).toLocaleString() || 0} />
            <CopyableCellSimple text={Math.round(fundDetails.shadawNAV).toLocaleString() || 0} />
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PlTable;
