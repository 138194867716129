import { useState } from "react";
import Loader from "../../common/loader";
import NavBar from "../../common/navbar";
import { handleAuth, postRequestOptions, baseUrl } from "../../common/cookie";
import axios from "axios";

const fileTypes = ["xlsx", "csv"];

function IbExcelFormat() {
  let [error, setError] = useState<any>(null);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setRequestStatus(true);
    try {
      setError(null);
      let url = baseUrl + "ib-excel";

      const form: any = document.getElementById("upload-trades");
      const formData = new FormData(form);

      let action: any = await axios.post(url, formData, postRequestOptions);

      handleAuth(action);

      if (action.data.error) {
        setError(action.data.error);

        setRequestStatus(false);
      } else {
        // window.alert("Success!");

        setTimeout(() => {}, 2000);
        window.open(action.data);

        setRequestStatus(false);
      }
    } catch (error) {
      console.error("Error:", error);

      setRequestStatus(false);
    }
  }

  const [request, setRequestStatus] = useState(false);

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div className="title">
        <h1>Format IB Trades Excel</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form onSubmit={(event) => handleSubmit(event)} encType="multipart/form-data" id="upload-trades">
          <div className="col-6">
            <h3>Upload IB Trades csv/xlsx file</h3>
          </div>

          <div className="drop-container">
            <input multiple={true} className="drop-box-input" placeholder="Upload or drop file" type="file" name="ib" accept=".xlsx, .csv" required />
          </div>

          <button type="submit" className="btn upload-btn">
            Upload
          </button>
        </form>
      </div>
      <h4 className="error-text">{error}</h4>
    </div>
  );
}

export default IbExcelFormat;
