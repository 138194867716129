import { useRef, useState } from "react";
import NavBar from "../../common/navbar";
import { title } from "process";
import { useLocation } from "react-router-dom";

function Media() {
  let query = new URLSearchParams(useLocation().search);
  let [queryId, setQueryId] = useState(query.get("id"));

  let media = [
    {
      link: "https://www.cnbc.com/video/2024/08/14/is-the-chinese-yuan-the-next-carry-trade-to-unwind.html",
      title: "CNBC – Monica Hsiao’s  Interview: As a low-yielding currency, is the Chinese Yuan the next carry trade to unwind?",
      date: "August 14, 2024",
      type: "link",
      id: "41",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/first_video.mp4",
      title: "Bloomberg TV - Monica Hsiao’s Interview: Fed rate cuts and BOJ intervention",
      date: "August 9, 2024",
      type: "video",
      id: "40",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/My%20Movie%201.mp4",
      title: "Bloomberg TV – Monica Hsiao’s Interview: China’s latest property measures and Fed rate cuts",
      date: "May 31, 2024",
      type: "iframe",
      id: "39",
    },
    {
      link: "https://www.cnbc.com/video/2024/04/08/fed-leans-towards-wanting-to-cut-rates-even-if-it-doesnt-need-to.html",
      title: "CNBC – Monica Hsiao’s  Interview: The Fed is leaning towards ‘wanting to cut’ rates even if it doesn’t need to",
      date: "April 8, 2024",
      type: "link",
      id: "38",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/IFRAsia-Asian%20Credit%20Roundtable%20April%202024.pdf",
      title: "IFR Asia – Outlook for Asian Credit - Roundtable 2024",
      date: "April 4, 2024",
      type: "pdf",
      id: "37",
    },
    {
      link: "https://www.youtube.com/embed/LLE4TXwCUbk?si=vM5nFGfeIit7TMtV",
      title: "Bloomberg – Monica Hsiao Bloomberg TV interview China Show – US Fed rate cuts discussions",
      date: "March 20, 2024",
      type: "iframe",
      id: "36",
    },
    {
      link: "https://www.bloomberg.com/news/videos/2024-02-01/triada-capital-s-hsiao-on-credit-strategy-video",
      title: "Bloomberg – Monica Hsiao TV Interview – US Fed rate cuts and China credit discussions",
      date: "Feburary 1, 2024",
      type: "link",
      id: "35",
    },
    {
      link: "https://cnbcmediahub.com/item/ae34ad9e-c15b-4dd2-a0dd-e238902852c9",
      title: "CNBC – Monica Hsiao’s  Interview: No ‘huge reflationary’ consumer demand in China without property sector recovery: Hedge fund",
      date: "October 16, 2023",
      type: "link",
      id: "34",
    },
    {
      link: "https://cnbcmediahub.com/item/013087ab-9457-459e-a44f-3e81ac451ab0",
      title: "CNBC – Local governments in China are facing a critical cash shortage, says asset management firm ",
      date: "June 1, 2023",
      type: "link",
      id: "33",
    },
    {
      link: "https://www.nbd.com.cn/articles/2023-02-03/2654859.html",
      title: "Reuters – Investors reaction to Adani market rout after short-seller report",
      date: "February 2, 2023",
      type: "link",
      id: "32",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/QUOTES-Investor-reaction-to-Adani-market-rout-after-short-seller-report-1.pdf",
      title: "Reuters – Investors reaction to Adani market rout after short-seller report",
      date: "February 2, 2023",
      type: "pdf",
      id: "31",
    },
    {
      link: "https://www.ifre.com/story/3528499/ifr-asia-high-yield-roundtable-2022-asian-high-yield-needs-to-evolve-0j7wgnfz2v",
      title: "IFR Asia – 2022 High Yield Roundtable: Asian high Yield needs to evolve",
      date: "September 24, 2022",
      type: "link",
      id: "30",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/IFRAsia_RT_High-Yield-Roundtable_2021.pdf",
      title: "IFR Asia High Yield Roundtable report",
      date: "November 30, 2021",
      type: "pdf",
      id: "29",
    },
    {
      link: "https://www.cnbc.com/video/2021/09/30/china-wants-to-keep-property-sector-stable-despite-evergrande-triada.html",
      title: "CNBC – Triada Capital says China will want to keep the property sector stable despite Evergrande crisis",
      date: "September 30, 2021",
      type: "link",
      id: "28",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/NPV%20recovery.pdf",
      title: "Debtwire – Hidili bondholder committee expects at least 90 NPV recovery ….",
      date: "May 5, 2021",
      type: "pdf",
      id: "27",
    },
    {
      link: "https://podcasts.apple.com/us/podcast/monica-hsiao-founder-and-cio-triada-capital-the/id1547706749?i=1000504549160",
      title: "Podcasts – AAAIM High ELI – The industry’s next $1bn female led hedge fund, why not?",
      date: "January 15, 2021",
      type: "link",
      id: "26",
    },
    {
      link: "https://www.skagenfunds.com/country-disclaimers/?redirectTo=/events/new-years-conference/new-years-conference-2021/?utm_campaign=unspecified",
      title: "SKAGEN Funds’ New Year’s Conference 2021",
      date: "January 12, 2021",
      type: "link",
      id: "25",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/TheKoreaHerald_Breaking-the-gender-mold-for-diverse-thinking_healthy-finance.pdf",
      title: "The Korea Herald – Breaking the gender mold for diverse thinking, healthy finance",
      date: "December 25, 2020",
      type: "pdf",
      id: "24",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/The-Korea-Herald_Diverse-boards-broader-minds_2020.pdf",
      title: "The Korea Herald – Pandemic highlights power of gender diversity to improve decision-making",
      date: "December 18, 2020",
      type: "pdf",
      id: "23",
    },
    {
      link: "https://www.skagenfondene.no/topic/new-years-conference/monica-hsiao-asian-credit-market-is-rife-with-opportunities/",
      title: "SKAGEN – Monica Hsiao: Asian credit market is rife with opportunities",
      date: "December 14, 2020",
      type: "link",
      id: "22",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/IFRAaia_Asian-High-Yield-Bonds-Roundtable-2020.pdf",
      title: "IFRAsia – Asian High-Yield Bonds Roundtable 2020",
      date: "October 27, 2020",
      type: "pdf",
      id: "21",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/Preqin-Honor-Roll-H1-2020-HF-in-APAC.pdf",
      title: "Preqin – Honor Roll H1 2020 HF in APAC",
      date: "September 2, 2020",
      type: "pdf",
      id: "20",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/Preqin-Honor-Roll-H1-2020-HF-in-APAC.pdf",
      title: "Preqin – Honor Roll H1 2020 HF in APAC",
      date: "September 2, 2020",
      type: "pdf",
      id: "19",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/Women-led-hedge-funds-beat-male-rivals-in-coronavirus-crisis-_-Financial-Times.pdf",
      title: "Financial Times – Women-led hedge funds beat male rivals in coronavirus crisis",
      date: "June 1, 2020",
      type: "pdf",
      id: "18",
    },
    {
      link: "https://www.ft.com/content/3a680c26-2e45-4204-b472-4747ebe2e5da",
      title: "Financial Times – China-focused hedge funds record best month in half a decade",
      date: "May 12, 2020",
      type: "link",
      id: "17",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/IFR-Asia-Speical-Repot.pdf",
      title: "IFR – IFR Asia High Yield Bonds Roundtable 2019",
      date: "December 2, 2019",
      type: "pdf",
      id: "16",
    },
    {
      link: "https://www.cnbc.com/video/2019/08/15/how-asian-markets-reacted-to-the-inverted-us-yield-curve.html?__source=sharebar%7Cemail&par=sharebar",
      title: "How Asian markets reacted to the inverted US yield curve",
      date: "August 15, 2019",
      type: "link",
      id: "15",
    },
    {
      link: "https://www.cnbc.com/video/2019/08/15/how-asian-markets-reacted-to-the-inverted-us-yield-curve.html?__source=sharebar%7Cemail&par=sharebar",
      title: "https://www.cnbc.com/video/2019/08/15/how-asian-markets-reacted-to-the-inverted-us-yield-curve.html?__source=sharebar%7Cemail&par=sharebar",
      date: "August 15, 2019",
      type: "link",
      id: "14",
    },
    {
      link: "https://www.cnbc.com/video/2019/07/31/its-pretty-hard-to-ignore-chinas-credit-market-triada-capital.html",
      title: "It’s pretty hard to ignore China’s credit market: Triada Capital",
      date: "August 1, 2019",
      type: "link",
      id: "13",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/WSJ-Chinese-High-Yield-Debt-Is-a-Rare-Bright-July-28-2019.pdf",
      title: "WSJ – Chinese High-Yield Debt Is a Rare Bright Spot for Bond Investors",
      date: "Jul 29, 2019",
      type: "pdf",
      id: "12",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/20190318TriadaPodcast.mp3",
      title: "HedgeVista – Interview with Monica Hsiao",
      date: "March 18, 2019",
      length: "53:38 minutes",
      participants: "Monica Hsiao (CIO and Founder, Triada Capital) and Terence Hau (Founder, HedgeVista)",
      description: "The podcast covers four broad areas including:",
      list: ["Monica’s background", "Investment philosophy & approach", "Execution and reviewing the fund’s journey since launch", "Key learnings and future business plans"],
      type: "mp3",
      id: "11",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/IFRAsiaCreditRoundTableMar2019.pdf",
      title: "IFR Asia – Outlook for Asian Credit Roundtable 2019",
      date: "March 1, 2019",
      type: "pdf",
      id: "10",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/BBG_20181014.pdf",
      title: "彭博 – 對沖基金Triada認為現在是買進亞洲信用債的最佳時機",
      date: "December 14, 2018",
      type: "pdf",
      id: "10",
    },
    {
      link: "https://na.eventscloud.com/ehome/270404/594639/",
      title: "AsiaHedge Awards 2017 – Final Nominations",
      date: "October 26, 2017",
      type: "link",
      id: "9",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/2017-Preqin-Global-Hedge-Fund-Report-Top-Performing-Funds-February-2017.pdf",
      title: "AsiaHedge – Hsiao’s Triada Asia Credit Strategy Thrives In Volatile 2016",
      date: "January 20, 2017",
      type: "pdf",
      id: "8",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/Asia-Hedge-January-2017.pdf",
      title: "Preqin – 2017 Global Hedge Funds Report",
      date: "January 11, 2017",
      type: "pdf",
      id: "7",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/AltCredit-Intelligence-Ex-CQS-duo-post-30-gains-at-Triada-Capital.pdf",
      title: "AltCredit Intelligence – Ex-CQS Duo Post 30% Gains At Triada Capital",
      date: "January 6, 2017",
      type: "pdf",
      id: "6",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/AltCredit-May-9-2016-Triada.pdf",
      title: "AltCredit Intelligence – Asia Trinity Off To A Promising Start- AltCredit",
      date: "May 9, 2016",
      type: "pdf",
      id: "5",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/Asiahedge-March17-2016.pdf",
      title: "AsiaHedge – Credit Manager Triada Outperforms Amid Volatile Markets",
      date: "March 17, 2016",
      type: "pdf",
      id: "4",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/HFI-Hedge-Funds-in-Asia-December-2015.pdf",
      title: "Hedge Fund Intelligence- Hedge Funds In Asia",
      date: "December 1, 2015",
      type: "pdf",
      id: "3",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/ALTCREDIT-Intelligence-Nov2015.pdf",
      title: "Alt Credit Intelligence -15 for 2016",
      date: "November 1, 2015",
      type: "pdf",
      id: "2",
    },
    {
      link: "https://storage.googleapis.com/public_triada_admin/files_to_be_shared/THFJ-Fifty-Women-In-Hedge-Funds-2015.pdf",
      title: "The Hedge Fund Journal – Fifty Women In Hedge Funds",
      date: "June 18, 2015",
      type: "pdf",
      id: "1",
    },
  ];
  if (queryId) {
    const post = media.find((item) => item.id === queryId);

    if (post) {
      if (post.type == "video") {
        return (
          <div>
            <NavBar navigation={false} />

            <VideoCard title={post.title} link={post.link} date={post.date} />
          </div>
        );
      } else if (post.type == "pdf") {
        return (
          <div>
            <NavBar navigation={false} />

            <PdfCard title={post.title} link={post.link} date={post.date} />
          </div>
        );
      } else if (post.type == "iframe") {
        return (
          <div>
            <NavBar navigation={false} />

            <IframeCard title={post.title} link={post.link} date={post.date} />
          </div>
        );
      } else if (post.type === "mp3") {
        return (
          <div>
            <NavBar navigation={false} />
            <AudioCard title={post.title} link={post.link} date={post.date} length={post.length} participants={post.participants} description={post.description} list={post.list} />
          </div>
        );
      } else if (post.type == "link") {
        window.location.href = post.link;
        return <div></div>;
      } else {
        return (
          <div>
            <NavBar navigation={false} />
            <Flyer /> <p>No media found for the given ID.</p>
          </div>
        );
      }
    } else {
      return (
        <div>
          <NavBar navigation={false} />
          <Flyer /> <p>No media found for the given ID.</p>
        </div>
      );
    }
  } else {
    return (
      <div>
        <NavBar navigation={false} />
        <Flyer />

        <div className="posts-links-container">
          {media.map((post: { link: string; title: string; date: string; type: string; id: string }, index: number) => (
            <div>
              <p className="post-date">{post.date}</p>
              <p className="post-title" onClick={(event: any) => (window.location.href = "/media?id=" + post.id)}>
                {post.title}
              </p>
              <hr />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
const VideoCard = ({ title, link, date }: any) => {
  const videoRef: any = useRef(null);

  return (
    <div className="video-card">
      <button className="btn btn-back-media" onClick={(event: any) => (window.location.href = "media")}>
        Back
      </button>
      <div>
        <p className="video-title">{title}</p>
        <p className="video-date">Published on:</p>
        <p className="video-date">{date}</p>
      </div>
      <div className="video-main">
        <video ref={videoRef} controls className="video-element">
          <source src={link} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};
const PdfCard = ({ title, link, date }: any) => {
  return (
    <div className="video-card">
      <button className="btn btn-back-media" onClick={(event: any) => (window.location.href = "media")}>
        Back
      </button>
      <div>
        <p className="video-title">{title}</p>
        <p className="video-date">Published on:</p>
        <p className="video-date">{date}</p>
      </div>
      <div className="video-main">
        <object className="pdf" data={link} width="100%" height="1000"></object>
      </div>
    </div>
  );
};
const IframeCard = ({ title, link, date }: any) => {
  return (
    <div className="video-card">
      <button className="btn btn-back-media" onClick={(event: any) => (window.location.href = "media")}>
        Back
      </button>
      <div>
        <p className="video-title">{title}</p>
        <p className="video-date">Published on:</p>
        <p className="video-date">{date}</p>
      </div>
      <div className="video-main">
        <iframe className="iframe-pdf" src={link} width="100%" ></iframe>
      </div>
    </div>
  );
};
const AudioCard = ({ title, link, date, length, participants, description, list }: any) => {
  return (
    <div className="video-card">
      <button className="btn btn-back-media" onClick={(event: any) => (window.location.href = "media")}>
        Back
      </button>
      <div>
        <p className="video-title">{title}</p>
        <p className="video-date">Published on:</p>
        <p className="video-date">{date}</p>
        <p className="video-date">Length: {length}</p>
        <p className="video-date">Participants: {participants}</p>
        <p className="video-date">{description}</p>
        <ul className="video-date">
          {list.map((item: string, index: number) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
      <div className="video-main ">
        <audio className="pdf mp3-player" src={link} controls></audio>
      </div>
    </div>
  );
};

const Flyer = () => {
  return (
    <div className="flyer">
      <p className="flyer-text">MEDIA</p>
    </div>
  );
};
export default Media;
