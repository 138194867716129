import { CopyableCell } from "../../../../common/functions";
import { longTitlesFO, mediumTitlesFO, smallTitlesFO } from "../../tools/titlesSummary";
import { TableFrontOffice } from "../../tools/tool";
import DurationUST from "./durationComponent";
function USTTable(props: any) {
  let table = props.analysis.ustTable;

  let tableData = new TableFrontOffice("Total");
  let allTotalSum: any = tableData.calculateTotal(table, "Total", "#F9F4D2", null);

  longTitlesFO.push("Ticker");
  mediumTitlesFO.push("Duration(Mkt)");
  smallTitlesFO.push("Total");
  let titlesWithIdents = ["Total", "Duration(Mkt)", "Ticker", ...props.titles];
  titlesWithIdents = titlesWithIdents.filter((title: any, index: any) => title != "Strategy");
  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
      }}
      className=" fadeIn table-container-custom"
    >
      <table id="" className="table table-summary">
        <tbody>
          <tr className="sticky-top">
            {titlesWithIdents.map((title: any, titleIndex: any) => {
              // Check if the title is one of the specified strings

              // If you need to keep the table structure consistent, render an empty <td>
              return (
                <td
                  key={titleIndex}
                  className={"copied-text " + (longTitlesFO.includes(title) ? " wider-table-cell" : "") + (longTitlesFO.includes(title) ? " wider-table-cell" : "") + (smallTitlesFO.includes(title) ? " small-table-cell" : "") + (mediumTitlesFO.includes(title) ? " medium-table-cell" : "")}
                >
                  {title}
                </td>
              );
            })}
          </tr>
          <tr className="table-body sum-row borders" style={{ backgroundColor: `${allTotalSum["Color"]}` }}>
            {titlesWithIdents.map((title: any, index: any) => (
              <CopyableCell
                key={index}
                text={isFinite(allTotalSum[title]) && allTotalSum[title] ? allTotalSum[title].toLocaleString() : allTotalSum[title]}
                color={title == "DV01 Dollar Value Impact Test" ? allTotalSum["DV01 Dollar Value Impact Test Color"] : title == "Value (BC) Test" ? allTotalSum["Value (BC) Test Color"] : ""}
                type={"summary"}
                columns={5}
              />
            ))}
          </tr>
          {Object.keys(table["0 To 2"]).length > 0 ? <DurationUST title="0 To 2" param="0 To 2" ustAnalysis={table} titles={titlesWithIdents} prefixId={props.prefixId + "_0"} /> : ""}
          {Object.keys(table["2 To 5"]).length > 0 ? <DurationUST title="2 To 5" param="2 To 5" ustAnalysis={table} titles={titlesWithIdents} prefixId={props.prefixId + "_1"} /> : ""}
          {Object.keys(table["5 To 10"]).length > 0 ? <DurationUST title="5 To 10" param="5 To 10" ustAnalysis={table} titles={titlesWithIdents} prefixId={props.prefixId + "_2"} /> : ""}
          {Object.keys(table["10 To 30"]).length > 0 ? <DurationUST title="10 To 30" param="10 To 30" ustAnalysis={table} titles={titlesWithIdents} prefixId={props.prefixId + "_3"} /> : ""}
          {Object.keys(table["> 30"]).length > 0 ? <DurationUST title="> 30" param="> 30" ustAnalysis={table} titles={titlesWithIdents} prefixId={props.prefixId + "_4"} /> : ""}
        </tbody>
      </table>
    </div>
  );
}
export default USTTable;
