import { useState } from "react";
import Loader from "../../common/loader";
import NavBar from "../../common/navbar";
import { baseUrl, handleAuth, postRequestOptions } from "../../common/cookie";
import axios from "axios";

function CheckRlzdPositions() {
  let [errors, setError] = useState<any>([]);
  let [errorText, setErrorText] = useState<any>("");

  async function handleSubmit(event: any) {
    event.preventDefault();
    setRequestStatus(true);

    // handle file processing here

    try {
      setError([]);
      setErrorText("");
      const form: any = document.getElementById("live-prices");
      const formData = new FormData(form);
      let url = baseUrl + "live-prices";
      let action: any = await axios.post(url, formData, postRequestOptions);

      handleAuth(action);
        if (action.data.error) {
          setError(action.data.error);
        } else {
          console.log(action);
          setTimeout(() => {}, 2000);
          window.open(action.data);
        }

        setRequestStatus(false);
   
    } catch (error) {
      console.error("Error:", error);

      setRequestStatus(false);
    }
  }

  const [request, setRequestStatus] = useState(false);

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div className="title">
        <h1>Check Realized Positions</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form onSubmit={(event) => handleSubmit(event)} encType="multipart/form-data" id="live-prices">
          <button type="submit" className="btn upload-btn">
            Generate
          </button>
        </form>
      </div>
      <h4 className="error-text">
        {errorText
          ? errorText
          : errors.map((error: any, index: any) => {
              return <p>{error}</p>;
            })}
      </h4>
    </div>
  );
}

export default CheckRlzdPositions;
