import { useState } from "react";
import Loader from "../../common/loader";
import NavBar from "../../common/navbar";
import { baseUrl, handleAuth, postRequestOptions } from "../../common/cookie";
import axios from "axios";

function EmailUpdate() {
  const [error, setError] = useState<any>(null);
  const [text, setText] = useState("");
  const [subject, setSubject] = useState("");
  const [shareClass, setShareClass] = useState("");

  const [request, setRequestStatus] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);

  const handleChange = (e: any) => {
    setText(e.target.value);
  };

  const convertToHtmlString = (input: string) => {
    return input
      .split("\n")
      .map((line) => `<p>${line}</p>`)
      .join("");
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(e.target.files);
  };

  async function handleSubmit(event: any, target: string) {
    event.preventDefault();
    setRequestStatus(true);

    try {
      setError(null);
      let url = baseUrl + "email-update";
      const formData = new FormData();
      formData.append("email", convertToHtmlString(text));
      formData.append("subject", subject);
      formData.append("target", target);
      formData.append("shareClass", shareClass);

      if (files) {
        Array.from(files).forEach((file) => {
          formData.append("files", file);
        });
      }

      let action: any = await axios.post(url, formData, postRequestOptions);
      handleAuth(action);
      if (action.data.error) {
        setError(action.data.error);
        setRequestStatus(false);
      } else {
        setRequestStatus(false);
        window.alert("Success!");
      }
    } catch (error) {
      console.error("Error:", error);
      setRequestStatus(false);
    }
  }

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <div className="title">
        <h1>Email Update</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form encType="multipart/form-data" id="email-update">
          <h2>Email Subject</h2>
          <input className="formTextInput inputCustom" onChange={(event) => setSubject(event.target.value)} value={subject} placeholder="Type here..." name="subject" required />
          <h2>Email Content</h2>
          <p>Name = $name, Share Class = $shareClass</p>
          <textarea value={text} className="formTextInput inputCustom" onChange={handleChange} rows={10} cols={80} placeholder="Type here..." name="email" required />
          <h2>Files</h2>
          <div className="drop-container">
            <input multiple={true} className="drop-box-input" placeholder="Upload or drop file" type="file" name="files" accept=".xlsx, .csv, .pdf, .docx, .doc" onChange={handleFileChange} required />
          </div>
          <h2>Share Class</h2>
          <input className="formTextInput inputCustom" placeholder="Type here..." onChange={(event) => setShareClass(event.target.value)} name="shareClass" required />

          <button type="button" className="btn upload-btn" onClick={(event) => handleSubmit(event, "test")}>
            Send Sample Test
          </button>
          <button type="button" className="btn upload-btn" onClick={(event) => handleSubmit(event, "investor")}>
            Send to all Investors
          </button>
        </form>
      </div>
      <h4 className="error-text">{error}</h4>
    </div>
  );
}

export default EmailUpdate;
