import React, { useState, useEffect, useRef } from "react";
import NavBar from "../../../common/navbar";
import { baseUrl, getRequestOptions, handleAuth } from "../../../common/cookie";
import Loader from "../../../common/loader";
import { useLocation } from "react-router-dom";
import LineChart from "./cumulativeReturnsCharts";
import ViewDisclaimers from "./viewDisclaimers";
import YearlyStats from "./yearlyStats";
import Unavaliable from "../../../common/unavaliable";
import YearlyStatsCompare from "./yearlyStatsCompare";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function ViewFactSheetReportCompare() {
  let [factSheet, setFactSheet] = useState<any>();
  let [compare, setCompare] = useState<any>();

  let [monthlyReturns, setMonthlyReturns] = useState<any>({});

  let [disabled, setDisabled] = useState<any>(false);

  let [cumulativeReturnsHashTable, setCumulativeReturnsHashTable] = useState<any>({});
  let [cumulativeReturnsHashTableSince2020, setCumulativeReturnsHashTableSince2020] = useState<any>({});

  let [lastDate, setLastDate] = useState<any>("");
  let query = new URLSearchParams(useLocation().search);
  let accessRole = localStorage.getItem("accessRole");
  let shareClass = localStorage.getItem("shareClass") || "";
  let shareClasses = shareClass.split(" ");
  let classes = ["a2", "a3", "a4", "a5", "a6", "ma2", "ma3", "ma4", "ma6"];

  let [type, setType] = useState(classes.includes(query.get("type") || shareClasses[0]) ? query.get("type") || shareClasses[0] : "a2");

  let [years, setYears] = useState([]);
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "YTD Return"];
  let monthsThatMatchDB = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Cumulative"];
  let yearlyStats = ["Annualized Return", "Annualized Rfr", "Risk", "Downside Risk", "Sharpe", "Sortino"];

  const [request, setRequestStatus] = useState(false);

  let url: any = baseUrl + `fact-sheet-mkt?type=${type}`;

  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setDisabled(data.disabled);
        if (!data.disabled) {
          let monthlyReturns = data.inception.result.monthlyReturns;

          let lastDate = data.inception.lastDate;
          let cumulativeReturnsHashTable = data.inception.cumulativeReturnsHashTable;
          let cumulativeReturnsHashTableSince2020 = data.inception.cumulativeReturnsHashTableSince2020;

          setMonthlyReturns(monthlyReturns[type]);

          setLastDate(lastDate);
          setCumulativeReturnsHashTable(cumulativeReturnsHashTable);
          setCumulativeReturnsHashTableSince2020(cumulativeReturnsHashTableSince2020);
          setCompare(data.inception.compare);
          let years: any = Object.keys(monthlyReturns[type]) || [];
          years = years.reverse();
          setYears(years);
          setFactSheet(data);
        } else {
        }
      });
  }, []);

  function getFactSheet(event: any, type: any) {
    window.location.href = `view-fact-sheet?type=${type}`;
  }
  function getFactSheetMKT(event: any, type: any) {
    window.location.href = `view-fact-sheet-mkt?type=${type}`;
  }
  const componentRef: any = useRef();

  const handleDownloadPdf = async () => {
    const element: any = componentRef.current;
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgProps = pdf.getImageProperties(imgData);

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    let position = 0;
    const pageHeight = pdf.internal.pageSize.getHeight();
    const totalPages = Math.ceil(pdfHeight / pageHeight);

    for (let i = 0; i < totalPages; i++) {
      if (i > 0) {
        pdf.addPage();
        position = -pageHeight * i;
      }
      pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
    }
    pdf.save(`${type}.pdf`);
  };

  if (disabled) {
    return <Unavaliable />;
  }
  if (factSheet == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (factSheet.length == 0) {
    return (
      <div>
        <NavBar />
        <div className="title">
          <h4>No Record</h4>
        </div>
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  return (
    <div className="">
      <NavBar />

      <div className="log-container classes-container">
        {classes.map((className: string, index: number) =>
          shareClasses.includes(className) || accessRole == "admin" ? (
            <button key={index} className="btn btn-trades btn-edit-logs btn-share-class" onClick={(event: any) => getFactSheetMKT(event, className)}>
              {className.toLocaleUpperCase()}
            </button>
          ) : null
        )}
        <button className="btn btn-trades btn-edit-logs btn-share-class" onClick={(event: any) => getFactSheet(event, type)}>
          Factsheet
        </button>
      </div>

      <div className="fact-sheet" id="fact-sheet" ref={componentRef}>
        <div className="row">
          <div className="col-lg-6">
            <p className="fact-sheet-title">Triada Capital Limited</p>
            <p className="fact-sheet-title">Triada Asia Credit Opportunities Fund</p>
          </div>{" "}
          <div className="col-lg-6 fact-sheet-photo-container">
            <img src={"/photos/triada-logo.png"} className="header-logo fact-sheet-photo" alt="logo-triada" />
          </div>
          <div className="fact-sheet-title-container">
            <p className="fact-sheet-title">
              Share Class {type.toUpperCase()} Fund Factsheet for {lastDate} - Private & Confidential
            </p>
          </div>
        </div>
        <hr className="fact-sheet-hr"></hr>
        <div className="row monthly-returns-container">
          <YearlyStatsCompare
            compare={compare.ytdReturns}
            months={months}
            monthsThatMatchDB={monthsThatMatchDB}
            monthlyReturns={monthlyReturns}
            years={years}
            title="Yearly Returns Statistics Comparison"
            description={`Every column shows the annual returns of Triada and benchmark funds sorted by highest to lowest. `}
            header="Triada"
          />
        </div>
        <div className="row monthly-returns-container">
          <YearlyStatsCompare
            compare={compare.volitality}
            months={months}
            monthsThatMatchDB={monthsThatMatchDB}
            monthlyReturns={monthlyReturns}
            years={years}
            description={`Every column shows the volatility of Triada and benchmark funds sorted by lowest to highest. `}
            title="Yearly Volatility Statistics Comparison"
            header="Triada"
          />
        </div>{" "}
        <div className="row monthly-returns-container">
          <YearlyStatsCompare
            compare={compare.sharpe}
            description={`Every column shows the sharpe ratio of Triada and benchmark funds sorted by highest to lowest. `}
            months={months}
            monthsThatMatchDB={monthsThatMatchDB}
            monthlyReturns={monthlyReturns}
            years={years}
            ratio={true}
            title="Yearly Sharpe Ratio Statistics Comparison"
            header="Triada"
          />
        </div>
        <div className="row monthly-returns-container">
          <YearlyStats
            yearlyStats={yearlyStats}
            months={months}
            monthsThatMatchDB={monthsThatMatchDB}
            monthlyReturns={monthlyReturns}
            years={years}
            description={"The following tables shows different metrics of triada and funds' performance by year."}
            title="Yearly Statistics for Triada Asia Credit Opportunities Fund Ltd. (“TACO”)"
            header="Triada"
          />
        </div>
        <div className="row monthly-returns-container">
          <YearlyStats yearlyStats={yearlyStats} months={months} monthsThatMatchDB={monthsThatMatchDB} monthlyReturns={factSheet.inception.result_legatruu.monthlyReturns["main"]} years={years} header={"BBG Global Aggregate"} title="" />
        </div>
        <div className="row monthly-returns-container">
          <YearlyStats yearlyStats={yearlyStats} months={months} monthsThatMatchDB={monthsThatMatchDB} monthlyReturns={factSheet.inception.result_emustruu.monthlyReturns["main"]} years={years} header={"BBG EM Aggregate"} title="" />
        </div>{" "}
        <div className="row monthly-returns-container">
          <YearlyStats yearlyStats={yearlyStats} months={months} monthsThatMatchDB={monthsThatMatchDB} monthlyReturns={factSheet.inception.result_beuctruu.monthlyReturns["main"]} years={years} header={"BBG EM Asia"} title="" />
        </div>
        {/* <div className="row monthly-returns-container">
          <YearlyStats yearlyStats={yearlyStats} months={months} monthsThatMatchDB={monthsThatMatchDB} monthlyReturns={factSheet.inception.result_beuytruu.monthlyReturns["main"]} years={years} header={"BBG EM Asia HY"} title="" />
        </div>{" "} */}
        <div className="row monthly-returns-container">
          <YearlyStats yearlyStats={yearlyStats} months={months} monthsThatMatchDB={monthsThatMatchDB} monthlyReturns={factSheet.inception.result_PIMGLBA.monthlyReturns["main"]} years={years} header={"Pimco Global Bond"} title="" />
        </div>
        <div className="row monthly-returns-container">
          <YearlyStats yearlyStats={yearlyStats} months={months} monthsThatMatchDB={monthsThatMatchDB} monthlyReturns={factSheet.inception.result_FIDITBD.monthlyReturns["main"]} years={years} header={"Fidelity Global Bond"} title="" />
        </div>
        <div className="row">
          <div className="col-12 fact-sheet-footer">
            This document does not constitute or form part of any offer or invitation to sell or any solicitation of any offer to purchase or subscribe for shares of Triada Asia Credit Opportunities Fund. The information contained in this documents has been compiled from sources believed to be
            reliable. However, certain information contained herein has been obtained from published sources and/or prepared by other parties. None of Triada Asia Credit Opportunities Fund Ltd., Triada Capital Management Ltd and Triada Capital Limited accept responsibility for the accuracy or
            completeness of such information. The contents of this document have not been reviewed by any regulatory authority. If you are in any doubt about any of the contents of this document, you should obtain independent professional advice. Recipients should make their own inquiries and
            consult their advisors as to legal, tax and related matters concerning Triada Asia Credit Opportunities Fund. All security transactions involve substantial risk of loss. Performance is no assurance of future results.
          </div>
        </div>
      </div>
      <ViewDisclaimers />
      {/* <button onClick={(event) => handleDownloadPdf()}>download</button> */}
    </div>
  );
}
export default ViewFactSheetReportCompare;
