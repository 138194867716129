import { useEffect, useState } from "react";
import Loader from "../../../common/loader";
import NavBar from "../../../common/navbar";
import { baseUrl, getAxiosRequestOptions, getRequestOptions, handleAuth } from "../../../common/cookie";
import Compare from "./compare";
import FilterCardAnalytics from "../tools/filterCardAnalytics";
import { Filter, getQueryParamters } from "../../../common/functions";
import { useLocation } from "react-router-dom";
import axios from "axios";

function Analytics() {
  let [analytics, setAnalytics] = useState<any>();
  const [request, setRequestStatus] = useState(false);
  let [query, setQuery]: any = useState(decodeURIComponent(new URLSearchParams(useLocation().search).toString()));
  let todayDate: any = new Date();
  let lastWeekDate: any = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);

  let [fromRange, setFromRange] = useState(lastWeekDate);
  let [toRange, setToRange] = useState(todayDate);
  let [filterCardDisplay, setFilterCardDisplay] = useState("none");
  let [type, setType] = useState("pnl");

  let [filterText, setFilterText] = useState(getQueryParamters(query.toString()));

  let types: any = {
    pnl: "P&L Amount USD",
    percentage: "P&L Of NAV %",
    // performance: "Average Weighted Return %",
  };

  let mtdTitles = {
    mtdUnrlzd: "MTD P&L URLZD USD",
    mtdRlzd: "MTD P&L RLZD USD",
    mtdInt: "MTD P&L Int. USD",
    mtdFx: "MTD P&L FX USD",
    mtdPNL: "MTD P&L USD",
    mtdExpensesAmount: "MTD Expenses",
  };
  let dayTitles = {
    dayUnrlzd: "Day P&L URLZD USD",
    dayRlzd: "Day P&L RLZD USD",
    dayInt: "Day P&L Int. USD",
    dayFx: "Day P&L FX USD",
    dayPNL: "Day P&L USD",
  };
  let url: any = baseUrl + `compare?` + (query == "" ? `start=${fromRange}&end=${toRange}&portfolio=portfolio` : query.toString().includes("start") ? query : `start=${fromRange}&end=${toRange}&${query}`);

  useEffect(() => {
    setRequestStatus(true);
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        console.group(data);

        setAnalytics(data);
      });
    setRequestStatus(false);
  }, []);

  function redirect(event: any, start: any, end: any) {
    let newUrl: any = `/analytics?start=${start}&end=${end}&` + query;
    window.location.href = newUrl;
  }
  async function getAnalytics(event: any, override = "") {
    console.log({ type });
    setRequestStatus(true);
    let newUrl: any = baseUrl + `compare?` + (query == "" ? `start=${fromRange}&end=${toRange}&portfolio=portfolio` : query.toString().includes("start") ? query : `start=${fromRange}&end=${toRange}&${query}`);
    if (override != "") {
      newUrl = override;
    }

    let action = await axios(newUrl, getAxiosRequestOptions);
    setAnalytics(action.data);
    setRequestStatus(false);
  }
  function downloadCSV() {
    // Get the HTML table element
    var table: any = document.getElementById("table-id");

    // Create an empty string for the CSV data
    var csv = "";

    // Loop through each row in the table
    for (var i = 0; i < table?.rows.length; i++) {
      // Loop through each cell in the row
      for (var j = 0; j < table?.rows[i].cells.length; j++) {
        // Add the cell value to the CSV data, surrounded by quotes
        csv += '"' + table?.rows[i].cells[j].textContent + '",';
      }
      // Add a newline character to the end of each row
      csv += "\n";
    }

    // Create a new Blob object with the CSV data
    var blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });

    // Create a new URL object for the Blob object
    var url = URL.createObjectURL(blob);

    // Create a new link element for the download
    var link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `data.csv`);

    // Trigger the download by clicking the link
    link.click();
  }
  if (analytics == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (analytics.length == 0) {
    return (
      <div>
        <NavBar />
        <div className="title">
          <h4>No Record</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <NavBar />

      <div className="calender-input-trades-container">
        <div onClick={(event: any) => setFilterCardDisplay("block")} className="filter-svg-container calender-inputs-container filter-desktop">
          <Filter />
        </div>
        <div className="">
          <div className="edit-logs-time-picker-container">
            <p className="edit-logs-time-picker-text">From</p>
            <input title="date" type="datetime-local" className="input-calendar edit-logs-time-picker" value={fromRange} onChange={(event: any) => setFromRange(event.target.value)} />
          </div>
          <div className="edit-logs-time-picker-container">
            <p className="edit-logs-time-picker-text">To</p>
            <input title="date" type="datetime-local" className="input-calendar edit-logs-time-picker" value={toRange} onChange={(event: any) => setToRange(event.target.value)} />
          </div>
        </div>
        <div onClick={(event: any) => setFilterCardDisplay("block")} className="filter-svg-container calender-inputs-container filter-mobile">
          <Filter />
        </div>
        <button className="btn upload-btn no-flex query-trade-btn" onClick={(event: any) => getAnalytics(event)}>
          Query
        </button>
      </div>
      <div>
        {filterText.length ? (
          <div className="filter-params">
            <p className="title-unassigned">Filtered</p>{" "}
            <button className="btn upload-btn no-flex btn-clear" onClick={(event) => (window.location.href = "/analytics")}>
              Clear
            </button>
          </div>
        ) : (
          ""
        )}
        <ul className="list-filter">
          {filterText.map((text: any, index: any) => (
            <li>{text}</li>
          ))}
        </ul>
      </div>
      <Compare analytics={analytics} start={fromRange} end={toRange} yTitle={type == "" ? "P&L Amount USD" : types[type] || "P&L Amount USD"} parameters={mtdTitles} />
      <Compare analytics={analytics} start={fromRange} end={toRange} yTitle={type == "" ? "P&L Amount USD" : types[type] || "P&L Amount USD"} parameters={dayTitles} />
      <div style={{ display: filterCardDisplay }} onDoubleClick={(event) => setFilterCardDisplay("none")}>
        <FilterCardAnalytics setFilterCardDisplay={setFilterCardDisplay} url="analytics?" analytics={analytics} getAnalytics={getAnalytics} fromRange={fromRange} toRange={toRange} setFilterText={setFilterText} setType={setType} setQuery={setQuery} />
      </div>
      <table
        id="table-id"
        style={{
          width: "100%",
        }}
        className="table table-hover table-portfolio table-striped table-trades table-trades-custom"
      >
        <tr className="sticky-top table-header">
          <td>Day</td>
          <td>MTD Pnl</td>
          <td>Day Pnl</td>
        </tr>
        {Object.keys(analytics.final).map((day: string, index: number) => (
          <tr>
            <td>{day}</td>
            <td>{Math.round(analytics.final[day].mtdPNL * 100) / 100}</td>
            <td>{Math.round(analytics.final[day].dayPNL * 100) / 100}</td>
          </tr>
        ))}
      </table>
      <button id="download-btn" onClick={downloadCSV} className="btn upload-btn">
        Download CSV
      </button>
    </div>
  );
}
export default Analytics;
