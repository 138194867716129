import { useState } from "react";
import { CopyableCell } from "../../../../common/functions";
import { TableFrontOffice } from "../../tools/tool";
function DurationUST(props: any) {
  let [paramsDisplay, setParamsDisplay] = useState("");
  let data: any = props.ustAnalysis;
  let param = props.param;
  let subTotals = Object.keys(data[param]);

  let title = props.title;
  let tableData = new TableFrontOffice(param + " Aggregated");
  let sum = tableData.calculateTotal(data, "", "white", "Duration(Mkt)", title);
  let formmated = [sum];

  subTotals.sort((a: any, b: any) => data[a]["Duration"] - data[b]["Duration"]);
  for (let index = 0; index < subTotals.length; index++) {
    let subTotal = subTotals[index];
    data[subTotal]["subTotal"] = "true";
    data[subTotal]["Ticker"] = data[subTotal]["L/S"];
    data[subTotal]["L/S"] = "";
    data[subTotal]["Color"] = "#F5F5F5";
    data[subTotal]["Notional Amount"] = "";
    data[subTotal]["USD Market Value"] = "";
    data[subTotal]["DV01"] = "";
    formmated.push(data[subTotal]);
    let strategies = Object.keys(data[param][subTotal]);
    for (let strategyIndex = 0; strategyIndex < strategies.length; strategyIndex++) {
      let strategy = strategies[strategyIndex];
      data[subTotal][strategy]["Color"] = strategy == "RV" ? "rgb(254, 235, 237)" : "rgb(232, 245, 233)";
      data[subTotal][strategy]["Group"] = data[subTotal][strategy]["L/S"];
      data[subTotal][strategy]["L/S"] = "";

      formmated.push(data[subTotal][strategy]);
      for (let positionIndex = 0; positionIndex < data[subTotal][strategy].length; positionIndex++) {
        data[param][subTotal][strategy][positionIndex]["subTotal"] = "false";
      }

      formmated.push(...data[param][subTotal][strategy]);
    }
  }

  return (
    <>
      {formmated.map((position: any, index: number) => (
        <tr
          key={index}
          id={props.prefixId + "_" + index}
          className={"table-body " + (index == 0 ? "sum-row " : "") + (!(position["L/S"] == "Short" || position["L/S"] == "Long" || position["subTotal"] == "true" || position["subTotal"] != "false") ? "borders" : "")}
          style={{ backgroundColor: `${position["Color"]}`, display: position["L/S"] == "Short" || position["L/S"] == "Long" ? paramsDisplay : "" }}
          // onClick={(event) => setParamsDisplay(paramsDisplay == "none" ? "" : "none")}
          
        >
          {props.titles.map((title: any, titleIndex: any) => (
            <CopyableCell
              key={titleIndex}
              text={isFinite(position[title]) && position[title] ? position[title].toLocaleString() : position[title]}
              color={title == "DV01 Dollar Value Impact Test" ? position["DV01 Dollar Value Impact Test Color"] : title == "Value (BC) Test" ? position["Value (BC) Test Color"] : ""}
              type={"summary"}
              columns={5}
            />
          ))}
        </tr>
      ))}
    </>
  );
}
export default DurationUST;
