import { CopyableCell } from "../../../common/functions";
import { groupSortTitles, hiddenTitlesFO, leftBorderTitlesFo, longTitlesFO, mediumTitlesFO, rightBorderTitlesFo, smallTitlesFO } from "../../frontOffice/tools/titlesSummary";

function ShowPosition(props: any) {
  let titles = Object.keys(props.position).filter((key: string) => !hiddenTitlesFO.includes(key));

  return (
    <div className={"table-container-custom"}>
      <p
        style={{
          marginLeft: "20px",
          fontWeight: "bold",
        }}
      >
        {props.position["BB Ticker"]}
      </p>
      <table id="table-id" className="table table-summary table-summary-fo">
        <tbody>
          <tr className="sticky-top">
            {titles.map((title: any, titleIndex) => {
              return (
                <td
                  key={titleIndex}
                  className={
                    "copied-text " +
                    (titleIndex < 6 ? " higher-sticky-row " : " lower-sticky-row ") +
                    (longTitlesFO.includes(title) ? " wider-table-cell" : "") +
                    (titleIndex < 6 ? "higher-sticky-row" : "lower-sticky-row") +
                    (groupSortTitles.includes(title) ? " sort-titles" : "") +
                    (longTitlesFO.includes(title) ? " wider-table-cell" : "") +
                    (smallTitlesFO.includes(title) ? " small-table-cell" : "") +
                    (mediumTitlesFO.includes(title) ? " medium-table-cell" : "") +
                    (rightBorderTitlesFo.includes(title) ? " borders-right" : leftBorderTitlesFo.includes(title) ? " borders-left" : "")
                  }
                >
                  {title}
                </td>
              );
            })}
          </tr>
          <tr
            className={"table-body"}
            style={{
              backgroundColor: `${props.position["Color"]}`,
              borderBottom: props.position["bottom"] ? "2px solid grey" : "",
              cursor: "pointer",
            }}
          >
            {titles.map((title: string, titleIndex: number) => (
              <CopyableCell
                key={titleIndex}
                classNameParam={rightBorderTitlesFo.includes(title) ? " borders-right " : leftBorderTitlesFo.includes(title) ? " borders-left " : ""}
                text={isFinite(props.position[title]) && props.position[title] ? props.position[title].toLocaleString() : props.position[title]}
                index={titleIndex}
                color={props.position["Color"]}
                disabled={false}
                style={{ cursor: "pointer", backgroundColor: props.position["Color"] }}
              />
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default ShowPosition;
