import { useState } from "react";
import { CloseIcon } from "../../../common/functions";

function EditPosition(props: any) {
  let titles = props.tableTitles;
 

  if (props.position["BB Ticker"]) {
    return (
      <div className="position-container edit-info-container-2">
        <div onClick={(event) => props.cancelPositionInfo(event)} className="close-icon-container-edit-position">
          <CloseIcon />
        </div>
        <form
          id="edit-position"
          onSubmit={(event) => props.handleEditPosition(event)}
          style={{
            overflowX: "auto",
          }}
        >
          <table
            id="table-id"
            className="table table-hover table-striped table-portfolio table-edit"
            style={{
              // width: "300%",
              border: "1px solid black",
            }}
          >
            <tbody>
              <tr className="sticky-top">
                {props.tableTitles.map((title: any, titleIndex: any) => {
                  return (
                    <td key={titleIndex} className="medium-wide-table-cell">
                      {title}
                    </td>
                  );
                })}
              </tr>

              <tr>
                {props.tableTitles.map((title: string, index: number) => {
                  return props.unEditableParams.includes(title) ? (
                    <td key={index} className="extra-wide-table-cell">
                      <input name={title} type="text" value={props.position[title]} className="paramDisabled" />
                    </td>
                  ) : (
                    <td key={index} className="extra-wide-table-cell">
                      {title == "Event Type" ? (
                        <select name="Event Type">
                          <option value="edit_position">Edit Position</option>
                          <option value="sink_factor">Sink Factor</option>
                          <option value="pay_in_kind">Pay In Kind</option>
                          <option value="edit_position">Settlement Edit</option>

                          <option value="redemption">Redemption</option>
                        </select>
                      ) : title == "Edit Note" ? (
                        <input type="text" name={title} placeholder={props.position[title]} required />
                      ) : title == "Factor Date (if any)" ? (
                        <input type="date" name={title} placeholder={props.position[title]} />
                      ) : (
                        <input type="text" name={title} placeholder={props.position[title]} />
                      )}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
          <div className="edit-info-edit-container">
            <button type="submit" className="btn upload-btn position-info-edit">
              {" "}
              Edit
            </button>
          </div>
        </form>
        {props.authStatus && <h4 className="error error-position-info">{props.authStatus}</h4>}
      </div>
    );
  } else {
    return <div></div>;
  }
}
export default EditPosition;
