import { useState, useEffect } from "react";
import Loader from "../../common/loader";
import NavBar from "../../common/navbar";
import { handleAuth, postRequestOptions, getRequestOptions, baseUrl } from "../../common/cookie";
import axios from "axios";

function CashReconcile() {
  let [error, setError] = useState<any>({});
  let [errorText, setErrorText] = useState<any>("");

  let url: any = baseUrl + `previous-collections`;
  let [dates, setCollectionDates]: any = useState([]);
  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setCollectionDates(data);
      });
  }, []);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setRequestStatus(true);

    // handle file processing here
    const form: any = document.getElementById("reconcile-cash");
    const formData = new FormData(form);

    try {
      setError({});
      setErrorText("");
      let url = baseUrl + "reconcile-cash";
      let action: any = await axios.post(url, formData, postRequestOptions);
      handleAuth(action);
      if (action.data.error) {
        setError(action.data.error);
      } else {
        console.log(action);
        setTimeout(() => {}, 2000);
        window.open(action.data);
      }

      setRequestStatus(false);
    } catch (error) {
      console.error("Error:", error);

      setRequestStatus(false);
    }
  }

  const [request, setRequestStatus] = useState(false);

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div className="title">
        <h1>Check Coupon Payments, MTD Rlzd, FX Interest, Redeemped Positions</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form onSubmit={(event) => handleSubmit(event)} encType="multipart/form-data" id="reconcile-cash">
          <h3>Select the portfolio's date</h3>

          <select className="select-collection-date" name="collectionDate">
            {dates.map((date: any, index: any) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select>
          <div>
            <h2>Start Time (same as nomura file range)</h2>

            <input id="start" title="next" type="date" className="formTextInput inputCustom" name="timestamp_start" required />
            <h2 className="input-custom">End Time (same as nomura file range)</h2>

            <input id="end" title="next" type="date" className="formTextInput inputCustom" name="timestamp_end" required />

            <p>Reconcile file has to be from the first day of month to the portfolio's date, e.g., portfolio's june 25th, will have nomura cash file from june 1st to june 25th</p>
          </div>
          <h2>Upload Nomura File</h2>

          <div className="drop-container">
            <input multiple={true} className="drop-box-input" placeholder="Upload or drop file" type="file" name="file" accept=".xlsx, .csv" required />
          </div>
          <h4 className="error-text">{errorText ? errorText : ""}</h4>

          <button type="submit" className="btn upload-btn">
            Upload
          </button>
        </form>
      </div>
      <h4 className="error-text">{errorText ? errorText : ""}</h4>
    </div>
  );
}

export default CashReconcile;
