import { CloseIcon } from "../../../common/functions";

function EditTrade(props: any) {
    let editTableTitles = props.editTableTitles
    editTableTitles.push("Edit Note")
  if (props.trade["BB Ticker"]) {
    return (
      <div className="position-container edit-info-container-2">
         <div onClick={(event) => props.cancelTradeInfo(event)} className="close-icon-container-edit-position">
            <CloseIcon />
          </div>
        <form
        
          onSubmit={(event) => props.handleEditTrade(event)}
          style={{
            overflowX: "auto",
          }}
        >
          <table
            id="table-id"
            className="table table-hover table-portfolio table-trades table-edit"
            style={{
              // width: "300%",
           
            }}
          >
            <tbody>
              <tr className="sticky-top">
                {editTableTitles.map((title: any, titleIndex: any) => {
                  return (
                    <td key={titleIndex} className="medium-wide-table-cell">
                      {title}
                    </td>
                  );
                })}
              </tr>

              <tr>
                {editTableTitles.map((title: string, index: number) => {
                  return props.unEditableParams.includes(title) ? (
                    <td key={index} className="extra-wide-table-cell">
                      <input name={title} type="text" value={props.trade[title]} className="paramDisabled" />
                    </td>
                  ) : title == "Edit Note" ? (
                    <td>
                      <input type="text" name={title} placeholder={props.trade[title]} required />
                    </td>
                  ) : (
                    <td>
                      <input type="text" name={title} placeholder={props.trade[title]} />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
          <div className="edit-info-edit-container">
            <button type="submit" className="btn upload-btn position-info-edit">
              {" "}
              Edit
            </button>
          </div>
        </form>
        {props.authStatus && <h4 className="error error-position-info">{props.authStatus}</h4>}
      </div>
    );
  } else {
    return <div></div>;
  }
}
export default EditTrade;
