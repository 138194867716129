function RegressionAlpha(props: any) {
  let benchmarksHeaders = props.benchmarksHeaders;
  let benchmarksNaming = props.benchmarksNaming;
  let correlationAndRegresion = props.correlationAndRegresion;

  return (
    <div className="col-12 fact-sheet-sub-container">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats-1 fact-sheet-outperformance-table fact-sheet-sub-container-2 ">
        <tbody>
          <tr className="sticky-top table-header">
            {benchmarksHeaders.map((title: string, index: number) => (
              <th className={title == "Fund" ? "fact-sheet-long-title" : ""} key={index}>
                {benchmarksNaming[title] || "Regression"}
              </th>
            ))}
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Active Premium (%)</td>
            <td></td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[2]]["Active Premium"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[3]]["Active Premium"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[4]]["Active Premium"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[5]]["Active Premium"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[6]]["Active Premium"] * 10000) / 100).toFixed(2)}</td>
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Annualized Alpha (%)</td>
            <td></td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[2]]["Annualized Alpha"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[3]]["Annualized Alpha"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[4]]["Annualized Alpha"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[5]]["Annualized Alpha"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[6]]["Annualized Alpha"] * 10000) / 100).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Tracking Error (%)</td>
            <td></td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[2]]["Tracking Error"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[3]]["Tracking Error"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[4]]["Tracking Error"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[5]]["Tracking Error"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(correlationAndRegresion[benchmarksHeaders[6]]["Tracking Error"] * 10000) / 100).toFixed(2)}</td>
          </tr>{" "}
        </tbody>
      </table>
    </div>
  );
}
export default RegressionAlpha;
