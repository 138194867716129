function AbsoluteRisk(props: any) {
  let volitality = props.volitality;
  let drawDown = props.drawDown;
  let factSheetTwoYears = props.factSheetTwoYears;
  let factSheetFiveYears = props.factSheetFiveYears;
  let type = props.type;
  let factSheetChinaPeriod = props.factSheetChinaPeriod;
  let periods = props.periods;
  return (
    <div className="col-12 fact-sheet-sub-container">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats-1">
        <tbody>
          <tr className="sticky-top table-header">
            <th>Absolute Risk Statistics</th>
            {periods.map((period: string, index: string) => (
              <th>{period}</th>
            ))}
          </tr>
          <tr className="table-body fact-sheet-row ">
            <td className="fact-sheet-col-grey">Volatility (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.volitality[type].annualVolitality * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.volitality[type].annualVolitality * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.volitality[type].annualVolitality * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(volitality.annualVolitality * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>

          <tr className="table-body fact-sheet-row ">
            <td className="fact-sheet-col-grey">Gain Volatility (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.volitality[type].positiveAnnualVolitality.volitality * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.volitality[type].positiveAnnualVolitality.volitality * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.volitality[type].positiveAnnualVolitality.volitality * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(volitality.positiveAnnualVolitality.volitality * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
          <tr className="table-body fact-sheet-row ">
            <td className="fact-sheet-col-grey">Loss Volatility (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.volitality[type].negativeAnnualVolitality.volitality * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.volitality[type].negativeAnnualVolitality.volitality * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.volitality[type].negativeAnnualVolitality.volitality * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(volitality.negativeAnnualVolitality.volitality * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
          <tr className="table-body fact-sheet-row ">
            <td className="fact-sheet-col-grey">Upside Deviation (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.volitality[type].positiveAnnualVolitality.sd * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.volitality[type].positiveAnnualVolitality.sd * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.volitality[type].positiveAnnualVolitality.sd * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(volitality.positiveAnnualVolitality.sd * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
          <tr className="table-body fact-sheet-row">
            <td className="fact-sheet-col-grey">Downside Deviation (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.volitality[type].negativeAnnualVolitality.sd * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.volitality[type].negativeAnnualVolitality.sd * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.volitality[type].negativeAnnualVolitality.sd * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(volitality.negativeAnnualVolitality.sd * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
          <tr className="table-body fact-sheet-row">
            <td className="fact-sheet-col-grey">Max Drawdown (%)</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetTwoYears.result.maxDrawdown[type].mdd * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetChinaPeriod.result.maxDrawdown[type].mdd * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(factSheetFiveYears.result.maxDrawdown[type].mdd * 10000) / 100).toFixed(2) || "0"}</td>
            <td className="fact-sheet-col-white">{(Math.round(drawDown * 10000) / 100).toFixed(2) || "0"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default AbsoluteRisk;
