import { CopyableCell } from "../../../../common/functions";
import { longTitlesFO, mediumTitlesFO, smallTitlesFO } from "../../tools/titlesSummary";
import { TableFrontOffice } from "../../tools/tool";
import SectorAssetClass from "./assetClassComponent";
function AssetClassIGTable(props: any) {
  let table = props.analysis.igTable;
  let tableData = new TableFrontOffice("Total");
  let allTotalSum: any = tableData.calculateTotal(table, "Total", "#F9F4D2", null);
  let titlesWithIdents = ["Total", ...props.titles];

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
      }}
      className=" fadeIn table-container-custom"
    >
      <table id="" className="table  table-summary">
        <tbody>
          <tr className="sticky-top">
            {titlesWithIdents.map((title: any, titleIndex: any) => {
              // Check if the title is one of the specified strings

              // If you need to keep the table structure consistent, render an empty <td>
              return (
                <td
                  key={titleIndex}
                  className={
                    "copied-text " +
                    (titleIndex < 5 ? " higher-sticky-row " : " lower-sticky-row ") +
                    (longTitlesFO.includes(title) ? " wider-table-cell" : "") +
                    (titleIndex < 5 ? "higher-sticky-row" : "lower-sticky-row") +
                    (longTitlesFO.includes(title) ? " wider-table-cell" : "") +
                    (smallTitlesFO.includes(title) ? " small-table-cell" : "") +
                    (mediumTitlesFO.includes(title) ? " medium-table-cell" : "")
                  }
                >
                  {title}
                </td>
              );
            })}
          </tr>
          <tr className="table-body sum-row borders" style={{ backgroundColor: `${allTotalSum["Color"]}` }}>
            {titlesWithIdents.map((title: any, index: any) => (
              <CopyableCell key={index} text={isFinite(allTotalSum[title]) && allTotalSum[title] ? allTotalSum[title].toLocaleString() : allTotalSum[title]} index={index} columns={5} type={"summary"} color={allTotalSum["Color"]} disabled={false} />
            ))}
          </tr>
          {table["Bonds"].length > 0 ? <SectorAssetClass title="Bonds" param="Bonds" analysis={table} titles={titlesWithIdents} getTrades={props.getTrades} prefixId={props.prefixId + "_0"} /> : ""}
          {table["FINS Perps"].length > 0 ? <SectorAssetClass title="FINS Perps" param="FINS Perps" analysis={table} titles={titlesWithIdents} getTrades={props.getTrades} prefixId={props.prefixId + "_1"} /> : ""}
          {table["Corps Perps"].length > 0 ? <SectorAssetClass title="Corps Perps" param="Corps Perps" analysis={table} titles={titlesWithIdents} getTrades={props.getTrades} prefixId={props.prefixId + "_2"} /> : ""}
        </tbody>
      </table>
    </div>
  );
}
export default AssetClassIGTable;
