import { AdvancedTooltip, Comment } from "../../../common/functions";

function YearlyStats(props: any) {
  let minus = "- ";
  let positive = "+ ";
  let yearsReversed = props.years;
  return (
    <div>
      <p className="fact-sheet-sub-header">{props.title}</p>
      <p className="fact-sheet-sub-des">{props.description}</p>

      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-monthly-returns">
        <tbody>
          <tr className="sticky-top table-header">
            <th >{props.header}</th>

            {yearsReversed.map((year: string, index: number) => (
              <th key={index} >
                {year}{" "}
                {index == 0 && props.header == "Triada" ? (
                  <>
                    <Comment id={year + props.header + index} /> <AdvancedTooltip id={year + props.header + index} content="Annualized Return projects this year's returns as if they continue at the same rate, unlike Year to Date Return which shows actual returns up to now." />
                  </>
                ) : (
                  ""
                )}
              </th>
            ))}
          </tr>
          <tr className="table-body">
            <td className="fact-sheet-green-col">{props.yearlyStats[0]}</td>
            {yearsReversed.map((year: any, index: number) => (
              <td key={index} >
                {props.monthlyReturns[year]["Annualized Return"] || props.monthlyReturns[year]["Annualized Return"] == 0 ? (props.monthlyReturns[year]["Annualized Return"] >= 0 ? positive : minus) + Math.abs(Math.round(props.monthlyReturns[year]["Annualized Return"] * 10000) / 100).toFixed(2) : ""}
              </td>
            ))}
          </tr>
          <tr className="table-body">
            <td className="fact-sheet-green-col">{props.yearlyStats[1]}</td>
            {yearsReversed.map((year: any, index: number) => (
              <td key={index}>
                {props.monthlyReturns[year]["Annualized Rfr"] || props.monthlyReturns[year]["Annualized Rfr"] == 0 ? (props.monthlyReturns[year]["Annualized Rfr"] >= 0 ? positive : minus) + Math.abs(Math.round(props.monthlyReturns[year]["Annualized Rfr"] * 10000) / 100).toFixed(2) : ""}
              </td>
            ))}
          </tr>
          <tr className="table-body">
            <td className="fact-sheet-green-col">{props.yearlyStats[2]}</td>
            {yearsReversed.map((year: any, index: number) => (
              <td key={index}>{props.monthlyReturns[year]["Risk"] || props.monthlyReturns[year]["Risk"] == 0 ? (props.monthlyReturns[year]["Risk"] >= 0 ? positive : minus) + Math.abs(Math.round(props.monthlyReturns[year]["Risk"] * 10000) / 100).toFixed(2) : ""}</td>
            ))}
          </tr>{" "}
          <tr className="table-body">
            <td className="fact-sheet-green-col">{props.yearlyStats[3]}</td>
            {yearsReversed.map((year: any, index: number) => (
              <td key={index}>
                {props.monthlyReturns[year]["Downside Risk"] || props.monthlyReturns[year]["Downside Risk"] == 0 ? (props.monthlyReturns[year]["Downside Risk"] >= 0 ? positive : minus) + Math.abs(Math.round(props.monthlyReturns[year]["Downside Risk"] * 10000) / 100).toFixed(2) : ""}
              </td>
            ))}
          </tr>
          <tr className="table-body">
            <td className="fact-sheet-green-col">{props.yearlyStats[4]}</td>
            {yearsReversed.map((year: any, index: number) => (
              <td key={index}>
                {props.monthlyReturns[year]["Sharpe"] || props.monthlyReturns[year]["Sharpe"] == 0
                  ? (props.monthlyReturns[year]["Sharpe"] >= 0 ? (props.monthlyReturns[year]["Sharpe"] >= 10 ? "> " : positive) : minus) + Math.abs(Math.round(props.monthlyReturns[year]["Sharpe"] * 100) / 100).toFixed(2)
                  : ""}
              </td>
            ))}
          </tr>
          <tr className="table-body">
            <td className="fact-sheet-green-col">{props.yearlyStats[5]}</td>
            {yearsReversed.map((year: any, index: number) => (
              <td key={index}>
                {props.monthlyReturns[year]["Sortino"] || props.monthlyReturns[year]["Sortino"] == 0
                  ? (props.monthlyReturns[year]["Sortino"] >= 0 ? (props.monthlyReturns[year]["Sortino"] >= 10 ? "> " : positive) : minus) + Math.abs(Math.round(props.monthlyReturns[year]["Sortino"] * 100) / 100).toFixed(2)
                  : ""}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default YearlyStats;
