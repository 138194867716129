import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../../../common/navbar";
import { baseUrl, getAxiosRequestOptions, getRequestOptions, handleAuth } from "../../../common/cookie";
import Loader from "../../../common/loader";
import axios from "axios";
import { getDateTimeInMongoDBCollectionFormat, getCurrentDateFormatted } from "../../../common/functions";
import ViewRiskTables from "./viewRiskTables";
import { formatDate } from "../../../common/functions";
import { hiddenTitlesFO, hiddenTitlesRisk, hiddenTitlesRiskFO } from "../tools/titlesSummary";
function ViewRisk() {
  let [portfolio, setPortfolio] = useState<any>();
  let [summary, setSummary] = useState<any>({});
  let [lastPriceUpdate, setPriceLastUpdate] = useState("");
  let [lastTradeUpload, setTradeLastUpload] = useState("");
  let [positionInfo, setPositionInfo] = useState([]);
  let [filterCardDisplay, setFilterCardDisplay] = useState("none");

  const [request, setRequestStatus] = useState(false);

  let today: any = getCurrentDateFormatted(new Date());

  let [date, setDate] = useState(today);
  let url: any = baseUrl + `summary-portfolio?date=${today}&sort=order&sign=1`;

  let [titles, setTitles] = useState([]);
  const [searchText, setSearchText] = useState("");

  const handleSearch = (text: any) => {
    let initialIds = ["1", "2", "3", "4", "5", "6"];
    if (!text) {
      initialIds.forEach((num) => {
        for (let i = 0; i < 15; i++) {
          for (let j = 0; j < 15; j++) {
            let id = "content_" + num + "_" + i + "_" + j;
            const content = document.getElementById(id);
            if (content) {
              const elements = content.querySelectorAll("*");
              elements.forEach((element: any) => {
                element.classList.remove("highlight");
              });
            }
          }
        }
      });
      return;
    } else {
      initialIds.forEach((num) => {
        for (let i = 0; i < 15; i++) {
          for (let j = 0; j < 15; j++) {
            let id = "content_" + num + "_" + i + "_" + j;
            const content = document.getElementById(id);
            if (content) {
              const elements = content.querySelectorAll("*");

              // Remove all highlights first
              elements.forEach((element: any) => {
                element.classList.remove("highlight");
              });

              // Add highlights to matched elements
              elements.forEach((element: any) => {
                if (element.textContent.toLowerCase().includes(text.toLowerCase())) {
                  element.classList.add("highlight");
                  element.scrollIntoView({ behavior: "smooth", block: "center" });
                }
              });
            }
          }
        }
      });
    }
  };
  let query = new URLSearchParams(useLocation().search);

  let longShort = query.get("longShort");

  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setPortfolio(data.portfolio);
        setTradeLastUpload(formatDate(data.uploadTradesDate));
        let filtered = data.portfolio;

        if (longShort) {
          filtered = filtered.filter((position: any) => {
            // Check if position is truthy to avoid errors when accessing properties

            // Check if each property exists and includes the searchTerm
            return position["L/S"].includes(longShort);
          });
        }

        setSummary(data);
        let titles: any = Object.keys(data.portfolio[0]).filter((title: string, index: number) => !hiddenTitlesRiskFO.includes(title) && !hiddenTitlesRisk.includes(title) && !title.includes("Mid"));
        let index = 0;
        while (titles.length < 15 && index < data.portfolio.length) {
          titles = Object.keys(data.portfolio[index]).filter((title: string, index: number) => !hiddenTitlesRiskFO.includes(title) && !hiddenTitlesRisk.includes(title) && !title.includes("Mid"));
          index++;
        }
        setTitles(titles);
        if (data.portfolio.length > 0) {
          try {
            setPriceLastUpdate(formatDate(data.updatePriceDate));
          } catch (error) {
            setPriceLastUpdate("");
          }
        }
      });
  }, []);
  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const contextMenuRef: any = useRef(null);

  const handleContextMenu = useCallback((event: any, issue: any) => {
    event.preventDefault();

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the position of the context menu accounting for the scroll
    const x = event.clientX + scrollX;
    const y = event.clientY + scrollY;
    console.log(issue);
    if (issue["L/S"] == "Sum") {
      setPositionInfo([]);
    } else {
      setPositionInfo(issue);
      setContextMenuState({
        visible: true,
        x: x,
        y: y,
      });
    }
  }, []);

  const handleClick = useCallback((event: any) => {
    // Hide context menu if clicking outside
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenuState({ visible: false, x: 0, y: 0 });
    }
  }, []);
  if (portfolio == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  if (portfolio.length == 0) {
    return (
      <div>
        <NavBar />
        <div>
          <input title="date" type="date" className="input-calendar" value={date} onChange={(event) => queryPortfolio(event, "order", 1, "", true)} />
        </div>
        <div className="title">
          <h4>No Record</h4>
        </div>
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  async function queryPortfolio(event: any, sort: string, newSign: number, title: string, calendar: boolean) {
    let newDate = getDateTimeInMongoDBCollectionFormat(date);
    if (calendar) {
      setDate(event.target.value);

      newDate = getDateTimeInMongoDBCollectionFormat(event.target.value);
    }

    setRequestStatus(true);
    setContextMenuState({ visible: false, x: 0, y: 0 });

    url = baseUrl + `summary-portfolio?date=${newDate}&sort=${sort}&sign=${newSign}`;
    // console.log(url);
    let action = await axios(url, getAxiosRequestOptions);
    setTradeLastUpload(formatDate(action.data.uploadTradesDate));
    setPortfolio(action.data.portfolio);
    let titles: any = Object.keys(action.data.portfolio[0]).filter((title: string, index: number) => !hiddenTitlesFO.includes(title));
    let index = 0;
    while (titles.length < 15 && index < action.data.portfolio.length) {
      titles = Object.keys(action.data.portfolio[index]).filter((title: string, index: number) => !hiddenTitlesFO.includes(title));
      index++;
    }
    setTitles(titles);

    setSummary(action.data);
    if (action.data.portfolio.length > 0) {
      try {
        setPriceLastUpdate(formatDate(action.data.updatePriceDate));
      } catch (error) {
        setPriceLastUpdate("");
      }
    }
    setRequestStatus(false);
  }

  function getTrades(event: any, position: any) {
    let issue = position["BB Ticker"];
    let isin = position["ISIN"];
    let location = position["Location"];
    let assetClass = position["Asset Class"];

    let tradeType = "vcons";
    let identifier = isin ? isin : issue;
    if (issue.includes("IB")) {
      tradeType = "ib";
    } else if (issue.includes("1393")) {
      tradeType = "emsx";
    } else if (assetClass.includes("CDS")) {
      tradeType = "gs";
    }
    window.location.href = `/view-trades?identifier=${identifier}&location=${location}&tradeType=${tradeType}`;
  }

  return (
    <div>
      <NavBar />

      <div className="portfolio-range-updates-container">
        <div>
          <h4 className="lastUpdateText">Last Prices Update: {lastPriceUpdate}</h4>
          <h4 className="lastUpdateText">Last Trade Upload: {lastTradeUpload}</h4>
        </div>
        <div className="portfolio-range-picker-container input-search-calendar-container">
          <div className="search-container portfolio-search-container">
            <input
              className="form-control mr-sm-2 filter-trades-search-bar filter-portfolio-search-bar"
              type="search"
              placeholder="Search Position By BB Ticker, ISIN, or Location code"
              aria-label="Search"
              onChange={(e) => {
                handleSearch(e.target.value);
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </div>
          <input title="date" type="date" className="input-calendar" value={date} onChange={(event) => queryPortfolio(event, "order", 1, "", true)} />
        </div>
      </div>
      <ViewRiskTables summary={summary} titles={titles} getTrades={getTrades} onCancelClick={handleClick} queryPortfolio={queryPortfolio} setFilterCardDisplay={setFilterCardDisplay} positionInfo={positionInfo} />
    </div>
  );
}
export default ViewRisk;
