function SectorsNAV(props: any) {
  let sectorHeaders = props.sectorHeaders;
  let sectorsNAV = props.sectorsNAV;
  delete sectorsNAV["Total"];
  let sectorsNames = Object.keys(sectorsNAV).sort((a, b) => {
    return parseFloat(sectorsNAV[b]["percentage"]) - parseFloat(sectorsNAV[a]["percentage"]); // For all other cases, sort alphabetically
  });
  sectorsNames = sectorsNames.slice(0, 10);

  return (
    <div className="col-12 fact-sheet-sub-container fact-sheet-sub-container-sectors">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats">
        <tbody>
          <tr className="sticky-top table-header">
            {sectorHeaders.map((title: string, index: number) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
          {sectorsNames.map((sector: string, index: any) => (
            <tr className="fact-sheet-row">
              <td className="fact-sheet-col-grey">{sector}</td>
              <td className="fact-sheet-col-white">{(Math.round(sectorsNAV[sector].percentage * 100) / 100).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default SectorsNAV;
