import { useState, useEffect, useCallback, useRef } from "react";
import NavBar from "../../../common/navbar";
import { baseUrl, getRequestOptions, postRequestOptions, handleAuth, getAxiosRequestOptions } from "../../../common/cookie";
import Loader from "../../../common/loader";
import axios from "axios";
import { getDate, formatDate, getDateTimeInMongoDBCollectionFormat, getDateFromInput, CopyableCell, getDurationName } from "../../../common/functions";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";

import { editTitlesBO, longTitlesBO, mediumTitlesBO, smallTitlesBO, tableTitlesBackOffice } from "../tools/titlesPortfolio";
import ViewSummaryFund from "../../frontOffice/portfolio/moreInfoTable";
import FilterCard from "../../frontOffice/tools/filterCard";
import { unEditableTitlesBO } from "../tools/titlesPortfolio";
import EditPosition from "../tools/editPosition";
import ViewRlzdTrades from "../tools/viewRlzdTrades";
import ViewSettlement from "../tools/viewSettlement";

export let widthScreen: any = {
  0: "0px",
  1: "30px",
  2: "60px",
  3: "90px",
  4: "120px",
  5: "200px",
  6: "250px",
  7: "300px",
  8: "350px",
  9: "430px",
};
export let widthMobile: any = {
  0: "0px",
  1: "17px",
  2: "34px",
  3: "51px",
  4: "68px",
  5: "108px",
  6: "138px",
  7: "168px",
  8: "198px",
  9: "238px",
};
function ViewReport() {
  let [portfolio, setPortfolio] = useState<any>();
  let [filteredPortfolio, setFilteredPortfolio] = useState<any>();
  let [lastPriceUpdate, setPriceLastUpdate] = useState("");
  let [lastTradeUpload, setTradeLastUpload] = useState("");
  let [positionInfoDisplay, setPositionInfoDisplay] = useState("none");
  let [positionInfo, setPositionInfo] = useState<any>([]);
  let [filterCardDisplay, setFilterCardDisplay] = useState("none");
  let [rlzdTradesCardDisplay, setRlzdTradesCardDisplay] = useState("none");
  let [settlementCardDisplay, setSettlementCardDisplay] = useState("none");

  let [authStatus, setAuthStatus] = useState("");
  let query = new URLSearchParams(useLocation().search);
  let [yieldParam, setYieldParam] = useState(query.get("yield"));

  let [country, setCountry] = useState(query.get("country"));
  let [sector, setSector] = useState(query.get("sector"));
  let [strategy, setStrategy] = useState(query.get("strategy"));
  let [durationStart, setDurationStart]: any = useState(query.get("duration_start"));
  let [durationEnd, setDurationEnd]: any = useState(query.get("duration_end"));
  let [coupon, setCoupon] = useState(query.get("coupon"));
  let [assetClass, setAssetClass] = useState(query.get("assetClass"));
  let [rating, setRating] = useState(query.get("rating"));
  let [marketType, setMarketType] = useState(query.get("marketType"));
  let [region, setRegion] = useState(query.get("region"));
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  let [issuer, setIssuer]: any = useState(query.get("issuer"));
  let [currency, setCurrency]: any = useState(query.get("currency"));
  let longShort = query.get("longShort");
  let [filterText, setFilterText] = useState<any>([]);
  let [sort, setSort] = useState(query.get("sort"));
  let newSign: any = query.get("newSign");
  let title: any = query.get("title");
  let ticker: any = query.get("ticker");
  let dateSort: any = query.get("dateSort");
  let [dateQuery, setDateQuery]: any = useState(query.get("date"));
  const [request, setRequestStatus] = useState(false);

  let [summary, setSummary] = useState<any>({});
  let today: any;
  if (dateQuery) {
    today = getDateTimeInMongoDBCollectionFormat(new Date(new Date(dateQuery).getTime() + 60 * 1000));
  } else {
    today = getDateTimeInMongoDBCollectionFormat(new Date(new Date().getTime() + 60 * 1000));
  }
  let [date, setDate] = useState(today);
  let url: any = baseUrl + `portfolio?date=${today}&sort=order&sign=1`;
  let [reportName, setReportName] = useState(today);
  let [unEditableParams, setUnEditableParams] = useState(unEditableTitlesBO);

  if (country) {
    url += "&country=" + country;
  }
  if (sector) {
    url += "&sector=" + sector;
  }
  if (strategy) {
    url += "&strategy=" + strategy;
  }
  if (durationStart) {
    url += "&durationStart=" + durationStart;
  }
  if (durationEnd) {
    url += "&durationEnd=" + durationEnd;
  }
  if (currency) {
    url += "&currency=" + currency;
  }
  if (issuer) {
    url += "&issuer=" + issuer;
  }
  if (longShort) {
    url += "&longShort=" + longShort;
  }
  if (ticker) {
    url += "&ticker=" + ticker;
  }
  if (assetClass) {
    url += "&assetClass=" + assetClass;
  }
  if (coupon) {
    url += "&coupon=" + coupon;
  }
  if (yieldParam !== "") {
    url += "&yield=" + yieldParam;
  }

  if (rating) {
    url += "&rating=" + rating;
  }
  if (region) {
    url += "&region=" + region;
  }
  if (marketType) {
    url += "&marketType=" + marketType;
  }
  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          setPortfolio([]);
          setRequestStatus(false);
        } else {
          setTradeLastUpload(formatDate(data.uploadTradesDate));
          setPortfolio(data.portfolio);

          let filtered = data.portfolio;
          let text = [];
          if (country) {
            text.push("Country: " + country.replace(/@/g, " & "));
          }

          if (sector) {
            text.push("Sector: " + sector.replace(/@/g, " & "));
          }
          if (strategy) {
            text.push("Strategy: " + strategy.replace(/@/g, " & "));
          }
          if (durationStart) {
            if (!durationEnd) {
              durationEnd = "100 years";
            }
            durationStart = getDurationName(durationStart);
            durationEnd = getDurationName(durationEnd);

            text.push("Duration: " + durationStart + " - " + durationEnd);
          }

          if (issuer) {
            text.push("Issuer: " + issuer.replace(/@/g, " & "));
          }
          if (ticker) {
            text.push("BB Ticker: " + ticker.replace(/@/g, " & "));
          }
          if (currency) {
            text.push("Currency: " + currency.replace(/@/g, " & "));
          }
          if (assetClass) {
            text.push("Asset Class: " + assetClass.replace(/@/g, " & "));
          }
          if (coupon) {
            text.push("Coupon: > " + coupon);
          }
          if (yieldParam) {
            text.push("Yield To Maturity: > " + yieldParam);
          }
          if (rating) {
            text.push("Rating: " + rating.replace(/@/g, " & "));
          }
          if (region) {
            text.push("Region: " + region.replace(/@/g, " & "));
          }
          if (marketType) {
            text.push("Market Class: " + marketType.replace(/@/g, " & "));
          }
          setFilteredPortfolio(filtered);
          setSummary(data);
          setFilterText(text);

          if (data.portfolio.length > 0) {
            try {
              setPriceLastUpdate(formatDate(data.updatePriceDate));
            } catch (error) {
              setPriceLastUpdate("");
            }
          }
        }
      });
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const contextMenuRef: any = useRef(null);

  const handleContextMenu = useCallback((event: any, issue: any) => {
    event.preventDefault();

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the position of the context menu accounting for the scroll
    const x = event.clientX + scrollX;
    const y = event.clientY + scrollY;

    setPositionInfo(issue);
    setContextMenuState({
      visible: true,
      x: x,
      y: y,
    });
  }, []);

  const handleClick = useCallback((event: any) => {
    // Hide context menu if clicking outside
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenuState({ visible: false, x: 0, y: 0 });
    }
  }, []);

  function handleViewRlzdMtd(event: any, position: any) {
    setPositionInfo(position);
    setRlzdTradesCardDisplay("block");
  }
  function handleViewSettlement(event: any, position: any) {
    setPositionInfo(position);
    setSettlementCardDisplay("block");
  }

  async function queryPortfolio(event: any) {
    let newDate = getDateTimeInMongoDBCollectionFormat(date);
    setReportName(newDate);
    window.location.href = `view-portfolio?date=${newDate}`;
  }
  async function handleDeletePosition(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData();

    // Append the date to the FormData object with the desired key
    console.log(positionInfo);
    formData.append("data", JSON.stringify(positionInfo));
    let currentDate: string = new Date(reportName).toISOString();

    // Append the date to the FormData object with the desired key
    formData.append("date", currentDate);
    let confirm = window.confirm("Confirm delete position " + positionInfo["BB Ticker"] + " with location code: " + positionInfo["Location"]);
    if (confirm) {
      try {
        let auth: any = await axios.post(baseUrl + "delete-position", formData, postRequestOptions);

        if (!auth.data.error) {
          window.location.href = "/view-portfolio?date" + reportName;
        } else {
          setRequestStatus(false);
          window.alert(`${auth.data.error}`);
        }
        setRequestStatus(false);
      } catch (error) {
        setAuthStatus("error");
      }
    }else{
          setRequestStatus(false);

    }
  }

  async function handleRecalculatePosition(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData();
    let currentDate: string = new Date(reportName).toISOString();
    // Append the date to the FormData object with the desired key
    let tradeType = "vcons";
    let identifier = positionInfo["ISIN"];
    let cdsCheck = positionInfo["Type"];
    if (identifier.includes("IB")) {
      tradeType = "ib";
    } else if (identifier.includes("1393")) {
      tradeType = "emsx";
    } else if (cdsCheck.includes("CDS")) {
      tradeType = "gs";
    }
    formData.append("date", currentDate);
    formData.append("ISIN", positionInfo["ISIN"]);
    formData.append("Location", positionInfo["Location"]);
    formData.append("tradeType", tradeType);

    let confirm = window.confirm("Confirm recalculate position " + positionInfo["BB Ticker"] + " with location code: " + positionInfo["Location"]);
    if (confirm) {
      try {
        let auth: any = await axios.post(baseUrl + "recalculate-position", formData, postRequestOptions);

        if (!auth.data.error) {
          window.location.href = "/view-portfolio?date=" + currentDate;
        } else {
          setRequestStatus(false);
          window.alert(`${auth.data.error}`);
        }
        setRequestStatus(false);
      } catch (error) {
        setAuthStatus("error");
      }
    }
    setRequestStatus(false);
  }

  function downloadCSV() {
    let table = document.getElementById("table-id");

    // Use XLSX.utils.table_to_book to convert the table to a workbook
    let wb = XLSX.utils.table_to_book(table, { sheet: "Sheet 1" });

    // Write the workbook to a file
    XLSX.writeFile(wb, `portfolio_${reportName}.xlsx`);
  }

  function getTrades(event: any) {
    let issue = positionInfo["BB Ticker"];
    let isin = positionInfo["ISIN"];
    let location = positionInfo["Location"];
    let tradeType = "vcons";
    let identifier = isin ? isin : issue;
    let assetClass = positionInfo["Type"];

    if (isin.includes("IB")) {
      tradeType = "ib";
    } else if (issue.includes("1393")) {
      tradeType = "emsx";
    } else if (assetClass.includes("CDS")) {
      tradeType = "gs";
    }
    window.location.href = `/view-trades?identifier=${identifier}&location=${location}&tradeType=${tradeType}`;
  }

  function cancelPositionInfo(event: any) {
    setPositionInfoDisplay("none");
    setAuthStatus("");
  }

  function displayPositionEditInfo(event: any) {
    let todayDate = getDate();
    let setDate = getDateFromInput(date);
    if (todayDate == setDate) {
      setPositionInfoDisplay("flex");
    } else {
      setPositionInfoDisplay("flex");
    }
  }

  async function handleEditPosition(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData(event.target);
    let currentDate: string = new Date(reportName).toISOString();

    // Append the date to the FormData object with the desired key
    formData.append("date", currentDate);

    try {
      let auth = await axios.post(baseUrl + "edit-position", formData, postRequestOptions);

      if (auth.status == 200) {
        window.location.href = "/view-portfolio?date=" + currentDate;
      } else {
        setAuthStatus(auth.data.error);
        setRequestStatus(false);
      }
    } catch (error) {
      setAuthStatus("error");
    }
  }

  function onChangePositionFilter(event: any) {
    let searchTerms = event.target.value.toLowerCase().split(" ");
    let matchedLocationCodes = new Set();

    // Step 1: Filter positions based on search terms and collect Location codes
    portfolio.forEach((position: any) => {
      if (!position) return;

      // Check if each property exists and includes any of the searchTerms
      let conditions = searchTerms.every((searchTerm: any) => Object.keys(position).some((key) => position[key]?.toString().toLowerCase().includes(searchTerm)));

      // If conditions are met, add the Location to matchedLocationCodes
      if (conditions) {
        matchedLocationCodes.add(position["Location"]);
      }
    });

    // Step 2: Include positions that match the search terms or share a Location with the matched positions
    let filteredAndIncludedPositions = portfolio.filter((position: any) => {
      if (!position) return false;

      // Check if this position's Location is in the set of matched Location codes
      let locationMatch = matchedLocationCodes.has(position["Location"]);

      // If the position matches the search terms or shares a Location, include it
      return locationMatch;
    });

    // filteredAndIncludedPositions now contains the filtered positions in the original order

    setFilteredPortfolio(filteredAndIncludedPositions);
  }

  if (portfolio == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (portfolio.length == 0 || portfolio == null) {
    return (
      <div>
        <NavBar />
        <div className="portfolio-range-updates-container">
          <div>
            <h4 className="lastUpdateText">Last Prices Update: {lastPriceUpdate}</h4>
            <h4 className="lastUpdateText">Last Trade Upload: {lastTradeUpload}</h4>
          </div>
          <div className="portfolio-range-picker-container input-search-calendar-container">
            <div className="search-container portfolio-search-container">
              <input
                className="form-control mr-sm-2 filter-trades-search-bar filter-portfolio-search-bar"
                type="search"
                placeholder="Search Position By BB Ticker, ISIN, or Location code"
                aria-label="Search"
                onChange={(event) => {
                  onChangePositionFilter(event);
                }}
              />
            </div>
            <input title="date" type="datetime-local" className="input-calendar" value={date} onChange={(event: any) => setDate(event.target.value)} />
            <button className="btn upload-btn no-flex" onClick={(event) => queryPortfolio(event)}>
              Query
            </button>
          </div>
        </div>
        <div>
          {filterText.length ? (
            <div className="filter-params">
              <p className="title-unassigned">Filtered</p>{" "}
              <button className="btn upload-btn no-flex btn-clear" onClick={(event) => (window.location.href = "/view-portfolio")}>
                Clear
              </button>
            </div>
          ) : (
            ""
          )}
          <ul className="list-filter">
            {filterText.map((text: any, index: any) => (
              <li>{text}</li>
            ))}
          </ul>
        </div>
        <div className="title">
          <h4>No Record</h4>
        </div>
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else {
    return (
      <div>
        <NavBar />

        <div className="portfolio-range-updates-container">
          <div>
            <h4 className="lastUpdateText">Last Prices Update: {lastPriceUpdate}</h4>
            <h4 className="lastUpdateText">Last Trade Upload: {lastTradeUpload}</h4>
          </div>
          <div className="portfolio-range-picker-container input-search-calendar-container">
            <div className="search-container portfolio-search-container">
              <input
                className="form-control mr-sm-2 filter-trades-search-bar filter-portfolio-search-bar"
                type="search"
                placeholder="Search Position By BB Ticker, ISIN, or Location code"
                aria-label="Search"
                onChange={(event) => {
                  onChangePositionFilter(event);
                }}
              />
            </div>
            <input title="date" type="datetime-local" className="input-calendar" value={date} onChange={(event: any) => setDate(event.target.value)} />
            <button className="btn upload-btn no-flex" onClick={(event) => queryPortfolio(event)}>
              Query
            </button>
          </div>
        </div>

        <ViewSummaryFund summary={summary} titles={tableTitlesBackOffice} queryPortfolio={queryPortfolio} setFilterCardDisplay={setFilterCardDisplay} url={"/view-portfolio?"} date={date} statistics={true} filter={true} />
        <div>
          {filterText.length ? (
            <div className="filter-params">
              <p className="title-unassigned">Filtered</p>{" "}
              <button className="btn upload-btn no-flex btn-clear" onClick={(event) => (window.location.href = "/view-portfolio")}>
                Clear
              </button>
            </div>
          ) : (
            ""
          )}
          <ul className="list-filter">
            {filterText.map((text: any, index: any) => (
              <li>{text}</li>
            ))}
          </ul>
        </div>
        <div
          className="table-container-custom"
          style={{
            overflowX: "auto",
          }}
        >
          <table id="table-id" onClick={handleClick} className="table table-hover table-portfolio">
            <tbody>
              <tr className="sticky-top">
                {tableTitlesBackOffice.map((title: any, titleIndex: any) => {
                  // Check if the title is one of the specified strings

                  // If you need to keep the table structure consistent, render an empty <td>
                  return (
                    <td
                      key={titleIndex}
                      className={
                        "copied-text " +
                        (titleIndex < 10 ? " higher-sticky-row " : " lower-sticky-row ") +
                        (titleIndex < 10 ? " higher-sticky-portfolio-row  " : "") +
                        (longTitlesBO.includes(title) ? " wider-table-cell" : "") +
                        (longTitlesBO.includes(title) ? " wider-table-cell" : "") +
                        (smallTitlesBO.includes(title) ? " small-table-cell" : "") +
                        (mediumTitlesBO.includes(title) ? " medium-table-cell" : "")
                      }
                      style={{ left: screenWidth < 1000 ? widthMobile[titleIndex] : widthScreen[titleIndex] }}
                    >
                      {title}
                    </td>
                  );
                })}
              </tr>
              {filteredPortfolio.map((position: any, index: number) => (
                <tr
                  key={index}
                  onContextMenu={(event) => handleContextMenu(event, position)}
                  className={"table-body " + (position["Type"] && position["Type"].includes("Total") ? " borders" : "")}
                  style={{
                    backgroundColor: `${position["Color"]}`,
                    borderBottom: position["bottom"] ? "2px solid grey" : "",

                    cursor: "pointer",
                  }}
                >
                  {tableTitlesBackOffice.map((title: string, index: number) => (
                    <CopyableCell key={index} text={isFinite(position[title]) && position[title] ? position[title].toLocaleString() : position[title]} index={index} columns={10} type={"portfolio"} color={position["Color"]} left={screenWidth < 1000 ? widthMobile[index] : widthScreen[index]} />
                  ))}
                </tr>
              ))}
              {contextMenuState.visible && (
                <div
                  ref={contextMenuRef}
                  style={{
                    position: "absolute",
                    top: contextMenuState.y,
                    left: contextMenuState.x,
                  }}
                  className="context-menu-container"
                >
                  <div className="context-menu">
                    <div className="context-menue-row">
                      <p className="context-menu-text" onClick={(event) => displayPositionEditInfo(event)}>
                        Edit Position
                      </p>
                    </div>
                    <div className="context-menue-row">
                      <p className="context-menu-text" onClick={(event) => handleRecalculatePosition(event)}>
                        Recalculate Position
                      </p>
                    </div>

                    <div className="context-menue-row">
                      <p className="context-menu-text" onClick={(event) => handleDeletePosition(event)}>
                        Delete Position
                      </p>
                    </div>
                  </div>
                  <hr className="hr" />
                  <div className="context-menu">
                    <div className="context-menue-row">
                      <p className="context-menu-text" onClick={(event) => handleViewRlzdMtd(event, positionInfo)}>
                        View MTD Rlzd Trades
                      </p>
                    </div>
                  </div>
                  <div className="context-menu">
                    <div className="context-menue-row">
                      <p className="context-menu-text" onClick={(event) => handleViewSettlement(event, positionInfo)}>
                        View Settlement
                      </p>
                    </div>
                    <div className="context-menue-row">
                      <p className="context-menu-text" onClick={(event) => getTrades(event)}>
                        View Trades
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </tbody>
          </table>
        </div>
        <button id="download-btn" onClick={downloadCSV} className="btn upload-btn">
          Download CSV
        </button>
        <div className="edit-info-container-1" style={{ display: positionInfoDisplay }} onDoubleClick={(event: any) => cancelPositionInfo(event)}>
          <EditPosition position={positionInfo} authStatus={authStatus} unEditableParams={unEditableParams} tableTitles={editTitlesBO} handleEditPosition={handleEditPosition} cancelPositionInfo={cancelPositionInfo} />
        </div>
        <div style={{ display: filterCardDisplay }} onDoubleClick={(event) => setFilterCardDisplay("none")}>
          {portfolio == null ? (
            ""
          ) : (
            <FilterCard
              countries={summary.analysis.countryNAVPercentage}
              currencies={summary.analysis.currencies}
              sectors={summary.analysis.sectorNAVPercentage}
              strategies={summary.analysis.strategyNAVPercentage}
              issuers={summary.analysis.issuerNAVPercentage}
              tickerTable={summary.analysis.tickerTable}
              ratings={summary.analysis.ratingNAVPercentage}
              regions={summary.analysis.regionNAVPercentage}
              marketTypes={summary.analysis.marketTypeNAVPercentage}
              setFilterCardDisplay={setFilterCardDisplay}
              url={"/view-portfolio?"}
            />
          )}
        </div>

        <div style={{ display: rlzdTradesCardDisplay }} onDoubleClick={(event) => setRlzdTradesCardDisplay("none")}>
          {portfolio == null || !positionInfo ? "" : <ViewRlzdTrades setRlzdTradesCardDisplay={setRlzdTradesCardDisplay} positionInfo={positionInfo} date={date} />}
        </div>
        <div style={{ display: settlementCardDisplay }} onDoubleClick={(event) => setSettlementCardDisplay("none")}>
          {portfolio == null || !positionInfo ? "" : <ViewSettlement setSettlementCardDisplay={setSettlementCardDisplay} positionInfo={positionInfo} date={date} />}
        </div>
      </div>
    );
  }
}
export default ViewReport;
