import { CopyableCell } from "../../../../common/functions";
import { TableFrontOffice } from "../../../frontOffice/tools/tool";
import SectorAssetClass from "./sectorAssetClass";

function AssetClassHYTable(props: any) {
  let table = props.analysis.hyTable;
  let tableData = new TableFrontOffice("Total");
  let allTotalSum: any = tableData.calculateTotal(table, "Total", "#F9F4D2", "BB Ticker", "Total");
 
  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
      }}
      className=" fadeIn table-container-custom"
    >
      <table id={props.tableId} className="table table-risk">
        <tbody>
          <tr className="sticky-top">
            {props.titles.map((title: any, titleIndex: any) => {
              // Check if the title is one of the specified strings
              if (title !== "BB Ticker" && title !== "Long Security Name") {
                // Render the <td> element
                return (
                  <td key={titleIndex}>
                    {title}
                  </td>
                );
              } else {
                // If you need to keep the table structure consistent, render an empty <td>
                return (
                  <td key={titleIndex} className="wider-table-cell">
                    {title}
                  </td>
                );
              }
            })}
          </tr>
          <tr className="table-body sum-row" style={{ backgroundColor: `${allTotalSum["Color"]}`, borderTop: "2px solid grey", borderBottom: "2px solid grey" }}>
            {props.titles.map((title: any, index: any) => (
              <CopyableCell
                key={index}
                text={isFinite(allTotalSum[title]) && allTotalSum[title] ? allTotalSum[title].toLocaleString() : allTotalSum[title]}
                color={title.includes("Test") ? allTotalSum[title + " Color"] : ""}
               
                type={"summary"}
              />
            ))}
          </tr>
          {table["Bonds"].length > 0 ? <SectorAssetClass title="Bonds" param="Bonds" analysis={table} titles={props.titles} /> : ""}
          {table["FINS Perps"].length > 0 ? <SectorAssetClass title="FINS Perps" param="FINS Perps" analysis={table} titles={props.titles} /> : ""}
          {table["Corps Perps"].length > 0 ? <SectorAssetClass title="Corps Perps" param="Corps Perps" analysis={table} titles={props.titles} /> : ""}
        </tbody>
      </table>
     
    </div>
  );
}
export default AssetClassHYTable;
