import NavBar from "../../common/navbar";
import Loader from "../../common/loader";
import { useState } from "react";
import { postRequestOptions, handleAuth, baseUrl, eblotUrl } from "../../common/cookie";
import axios from "axios";

function ConfirmationExcel() {
  const fileTypes = ["xlsx", "csv", "xls"];

  let [error, setError] = useState<any>("");
  async function handleSubmit(event: any) {
    event.preventDefault();
    setRequestStatus(true);

    // handle file processing here
    const form: any = document.getElementById("elec-blot-form");
    const formData = new FormData(form);

    try {
      setError("");
      let url = eblotUrl + "confirmation-excel";
      let action: any = await axios.post(url, formData, postRequestOptions);
      handleAuth(action);
      //   console.log(action);
      if (action.data.error) {
        setError(action.data.error);
      } else {
        console.log(action);
        setTimeout(() => {}, 2000);
        window.open(action.data);
      }

      setRequestStatus(false);
    } catch (error) {
      setRequestStatus(false);
    }
  }

  const [request, setRequestStatus] = useState(false);

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />

      <div className="title bold-text">
        <h1>Generate Confirmation Excel</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form onSubmit={(event) => handleSubmit(event)} encType="multipart/form-data" id="elec-blot-form">
          <h2>Start Time</h2>

          <input id="start" title="next" type="datetime-local" className="formTextInput inputCustom" name="timestamp_start" required />
          <h2 className="input-custom">End Time</h2>

          <input id="end" title="next" type="datetime-local" className="formTextInput inputCustom" name="timestamp_end" required />

          <button type="submit" className="btn upload-btn">
            Generate
          </button>
        </form>
      </div>
      <h4 className="error-text">{error}</h4>
    </div>
  );
}

export default ConfirmationExcel;
