import NavBar from "../../common/navbar";

function LinksRedirect() {
  return (
    <div>
      <NavBar redirect="/view-fact-sheet" />
    </div>
  );
}
export function LinksRedirectMedia() {
  return (
    <div>
      <NavBar redirect="/media" />
    </div>
  );
}
export default LinksRedirect;
