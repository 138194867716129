import { useState } from "react";
import { CloseIcon } from "../../../common/functions";
import CreatableSelect from 'react-select/creatable';

import { passConditions, reactSelectFormat } from "./tool";

function FilterCard(props: any) {
  let countries = reactSelectFormat(
    Object.keys(props.countries)
      .filter((title, index) => !title.includes("Total"))
      .sort() || []
  );
  let sectors = reactSelectFormat(
    Object.keys(props.sectors)
      .filter((title, index) => !title.includes("Total"))
      .sort() || []
  );
  let strategies = reactSelectFormat(
    Object.keys(props.strategies)
      .filter((title, index) => !title.includes("Total"))
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  );
  let issuers = reactSelectFormat(
    Object.keys(props.issuers)
      .filter((title, index) => !title.includes("Total"))
      .sort((a, b) => a.localeCompare(b))
  );
  let regions = reactSelectFormat(
    Object.keys(props.regions)
      .filter((title, index) => !title.includes("Total"))
      .sort((a, b) => a.localeCompare(b))
  );
  let ratings = reactSelectFormat(
    Object.keys(props.ratings)
      .filter((title, index) => !title.includes("Total"))
      .sort((a, b) => a.localeCompare(b))
  );
  let marketTypes = reactSelectFormat(
    Object.keys(props.marketTypes)
      .filter((title, index) => !title.includes("Total"))
      .sort((a, b) => a.localeCompare(b))
  );
  let assetClass = reactSelectFormat(["Hedge", "IG", "HY"]);

  let coupons: any = [
    { value: 3, label: "> 3" },
    { value: 4, label: "> 4" },
    { value: 5, label: "> 5" },
    { value: 6, label: "> 6" },
    { value: 7, label: "> 7" },
    { value: 8, label: "> 8" },
    { value: 9, label: "> 9" },
  ];
  let yieldsKeys: any = [
    { value: 3, label: "> 3" },
    { value: 4, label: "> 4" },
    { value: 5, label: "> 5" },
    { value: 6, label: "> 6" },
    { value: 7, label: "> 7" },
    { value: 8, label: "> 8" },
    { value: 9, label: "> 9" },
  ];

  let currencies = reactSelectFormat(
    Object.keys(props.currencies)
      .filter((title, index) => !title.includes("Total"))
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  );

  let tickers = reactSelectFormat(Object.keys(props.tickerTable).sort() || []);

  let durationKeys: any = [
    { value: 0.0001, label: "Now" },
    { value: 45 / 365, label: "6 Weeks" },
    { value: 30 / 365, label: "1 Month" },
    { value: 90 / 365, label: "3 Months" },
    { value: 1, label: "1 Year" },
    { value: 2, label: "2 Years" },
    { value: 5, label: "5 Years" },
    { value: 10, label: "10 Years" },
    { value: 30, label: "30 Years" },
  ];
  let [country, setCountry] = useState([]);
  let [sector, setSector] = useState([]);
  let [strategy, setStrategy] = useState([]);
  let [coupon, setCoupon] = useState("");
  let [yieldParam, setYieldParam] = useState("");

  let [assetClassParam, setAssetClass] = useState([]);

  let [durationStart, setDurationStart]: any = useState("");
  let [durationEnd, setDurationEnd]: any = useState("");
  let [issuer, setIssuer]: any = useState([]);

  let [bbTicker, setBBTicker]: any = useState([]);
  let [currency, setCurrency]: any = useState([]);
  let [rating, setRating]: any = useState([]);

  let [region, setRegion]: any = useState([]);

  let [marketType, setMarketType]: any = useState([]);

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  let url = props.url || "/?";

  function handleFilter(event: any) {
    if (country.length !== 0) {
      url += "country=" + passConditions(country) + "&";
    }
    if (sector.length !== 0) {
      url += "sector=" + passConditions(sector) + "&";
    }
    if (strategy.length !== 0) {
      url += "strategy=" + passConditions(strategy) + "&";
    }
    if (durationStart !== "") {
      url += "duration_start=" + durationStart + "&";
    }
    if (durationEnd !== "") {
      url += "duration_end=" + durationEnd + "&";
    }
    if (issuer.length !== 0) {
      url += "issuer=" + passConditions(issuer) + "&";
    }
    if (bbTicker.length !== 0) {
      url += "ticker=" + passConditions(bbTicker) + "&";
    }
    if (currency.length !== 0) {
      console.log(passConditions(currency));
      url += "currency=" + passConditions(currency) + "&";
    }
    if (assetClassParam.length !== 0) {
      url += "assetClass=" + passConditions(assetClassParam) + "&";
    }
    if (coupon !== "") {
      url += "coupon=" + coupon + "&";
    }
    if (yieldParam !== "") {
      url += "yield=" + yieldParam + "&";
    }

    if (rating.length !== 0) {
      url += "rating=" + passConditions(rating) + "&";
    }
    if (region.length !== 0) {
      url += "region=" + passConditions(region) + "&";
    }
    if (marketType.length !== 0) {
      url += "marketType=" + passConditions(marketType) + "&";
    }
    if (props.url == "/view-performers?") {
      url += "view=" + props.view + "&type=" + props.performersType;
    }
    console.log(url);
    window.location.href = url;
  }

  return (
    <div className="edit-info-container-1">
      <div onClick={(event) => props.setFilterCardDisplay("none")}>
        <CloseIcon />
      </div>
      <div className="position-container filter-info-container-2 filter-card">
        <form className="filter-form">
          <p>Issuer</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={issuers}
            onChange={(event: any) => setIssuer(event ? event || [] : [])}
            isMulti
          />
          <p>BB Ticker</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={tickers}
            onChange={(event: any) => setBBTicker(event ? event || [] : [])}
            isMulti
          />
          <p>Strategy</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={strategies}
            onChange={(event: any) => setStrategy(event ? event || [] : [])}
            isMulti
          />

          <p>Asset Class</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={assetClass}
            onChange={(event: any) => setAssetClass(event ? event || [] : [])}
            isMulti
          />

          <p>Coupon</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={coupons}
            onChange={(event: any) => setCoupon(event ? event.value || "" : "")}
          />

          <p>Yield To Maturity</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={yieldsKeys}
            onChange={(event: any) => setYieldParam(event ? event.value || "" : "")}
          />

          <p>Country</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={countries}
            onChange={(event: any) => setCountry(event ? event || [] : [])}
            isMulti
          />

          <p>Sector</p>

          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={sectors}
            onChange={(event: any) => setSector(event ? event || [] : [])}
            isMulti
          />

          <p>Duration Start</p>

          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={durationKeys}
            onChange={(event: any) => setDurationStart(event ? event.value || "" : "")}
          />
          <p>Duration End</p>

          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={durationKeys}
            onChange={(event: any) => setDurationEnd(event ? event.value || "" : "")}
          />
          <p>Currency</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={currencies}
            onChange={(event: any) => setCurrency(event ? event || [] : [])}
            isMulti
          />
          <p>Region</p>

          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={regions}
            onChange={(event: any) => setRegion(event ? event || [] : [])}
            isMulti
          />
          <p>Rating</p>

          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={ratings}
            onChange={(event: any) => setRating(event ? event || [] : [])}
            isMulti
          />
          <p>Market Class</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={marketTypes}
            onChange={(event: any) => setMarketType(event ? event || [] : [])}
            isMulti
          />

          <div className="edit-info-edit-container">
            <button type="button" className="btn upload-btn btn-filter" onClick={(event) => handleFilter(event)}>
              {" "}
              Filter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FilterCard;
