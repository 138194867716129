import React, { useState, useEffect, useRef } from "react";
import NavBar from "../../../common/navbar";
import { baseUrl, getRequestOptions, handleAuth } from "../../../common/cookie";
import Loader from "../../../common/loader";
import MonthlyReturns from "./monthlyReturns";
import AbsoluteReturn from "./fund/absoluteReturn";
import SkewnessKurtosis from "./fund/skewnessKurtosis";
import UpDownRatios from "./benchmark/upDownRatios";
import RiskRatios from "./benchmark/riskRatios";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { formatDate, getCurrentDateFormatted } from "../../../common/functions";
import { useLocation } from "react-router-dom";
import LineChart from "./cumulativeReturnsCharts";
import RiskAdjustedMultiYear from "./fund/riskAdjustedMultiYear";
import RegionsNAV from "./fund/regionsNAV";
import { fundInformation, wording } from "./text";
import ViewDisclaimers from "./viewDisclaimers";
import OutPerformance from "./benchmark/outPerformance";
import Regression from "./benchmark/regression";
import RegressionAlpha from "./benchmark/regression_2";
import AbsoluteRisk from "./fund/absoluteRisk";
import Efficiency from "./fund/efficiency";
import MarketsNAV from "./fund/marketNAV";
import SectorsNAV from "./fund/sectorsNAV";
import Exposure from "./fund/exposure";
import YearlyStats from "./yearlyStats";
import Unavaliable from "../../../common/unavaliable";

function ViewFactSheetReport() {
  let [factSheet, setFactSheet] = useState<any>();
  let [factSheetFiveYears, setFactSheetFiveYears] = useState<any>();
  let [factSheetTwoYears, setFactSheetTwoYears] = useState<any>();
  let [factSheetChinaPeriod, setFactSheetChinaPeriod] = useState<any>();

  let [monthlyReturns, setMonthlyReturns] = useState<any>({});
  let [volitality, setVolitality] = useState<any>({});
  let [annulizedReturn, setAnnulizedReturn] = useState<any>({});

  let [variance, setVariance] = useState<any>(0);
  let [drawDown, setDrawDown] = useState<any>(0);
  let [treasuryAnnualRate, setTreasuryAnnualRate] = useState<any>(0);
  let [ratios, setRatios] = useState<any>({});
  let [normal, setNormal] = useState<any>({});
  let [countriesNAV, setCountriesNAV] = useState<any>({});
  let [sectorsNAV, setSectorsNAV] = useState<any>({});
  let [marketsNAV, setMarketsNAV] = useState<any>({});
  let [regionsNAV, setRegionsNAV] = useState<any>({});
  let [disabled, setDisabled] = useState<any>(false);

  let [exposure, setExposure] = useState<any>({});
  let [outPerformance, setOutPerformance] = useState<any>({});
  let [annulizedReturnBenchMarks, setAnnulizedReturnBenchMarks] = useState<any>({});
  let [cumulativeReturnsBenchMarks, setCumulativeReturnsBenchMarks] = useState<any>({});
  let [upDownRatios, setUpDownRatios] = useState<any>({});
  let [riskRatios, setRiskRatios] = useState<any>({});
  let [correlationAndRegresion, setCorrelationAndRegresion] = useState<any>({});
  let [cumulativeReturnsHashTable, setCumulativeReturnsHashTable] = useState<any>({});
  let [cumulativeReturnsHashTableSince2020, setCumulativeReturnsHashTableSince2020] = useState<any>({});

  let [lastDate, setLastDate] = useState<any>("s");
  let query = new URLSearchParams(useLocation().search);
  let accessRole = localStorage.getItem("accessRole");
  let shareClass = localStorage.getItem("shareClass") || "";
  let shareClasses = shareClass.split(" ");
  let classes = ["a2", "a3", "a4", "a5", "a6", "ma2", "ma3", "ma4", "ma6"];

  let [type, setType] = useState(classes.includes(query.get("type") || shareClasses[0]) ? query.get("type") || shareClasses[0] : "a2");

  let [years, setYears] = useState([]);
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "YTD Return"];
  let monthsThatMatchDB = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Cumulative"];
  let yearlyStats = ["Annualized Return", "Annualized Rfr", "Risk", "Downside Risk", "Sharpe", "Sortino"];
  let countriesHeaders = ["Top 10 Country Exposure", "NAV %"];
  let sectorHeaders = ["Top 10 Industry Exposure", "NAV %"];
  let exposureHeaders = ["LMV (%)", "GMV (%)", "SMV (%)", "NMV (%)"];
  let outPerformanceHeaders = ["Fund", "Triada", "LEGATRUU Index", "EMUSTRUU Index", "BEUCTRUU Index", "FIDITBD LX Equity", "PIMGLBA ID Equity"];
  let benchmarksHeaders = ["Fund", "Triada", "LEGATRUU Index", "EMUSTRUU Index", "BEUCTRUU Index", "FIDITBD LX Equity", "PIMGLBA ID Equity"];
  let periods = ["Jan' 23 - Now", "Jan' 21 - Dec' 22", "June' 15 - Dec' 20", , "Inception"];

  let benchmarksNaming = {
    Triada: "Triada",
    "LEGATRUU Index": "BBG Global Aggregate",
    "EMUSTRUU Index": "BBG EM Aggregate",
    "BEUCTRUU Index": "BBG EM Asia",
    // "BEUYTRUU Index": "BBG EM Asia HY",
    "LG30TRUU Index": "BBG Global HY",
    "FIDITBD LX Equity": "Fidelity Global Bond",
    "PIMGLBA ID Equity": "Pimco Global Bond",
  };

  const [request, setRequestStatus] = useState(false);

  let url: any = baseUrl + `fact-sheet?type=${type}`;

  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setDisabled(data.disabled);
        if (!data.disabled) {
          let monthlyReturns = data.inception.result.monthlyReturns;
          let volitality = data.inception.result.volitality;
          let annulizedReturn = data.inception.result.annulizedReturn;
          let variance = data.inception.result.variance;
          let drawdown = data.inception.result.maxDrawdown;
          let ratios = data.inception.result.ratios;
          let normal = data.inception.result.normal;
          let exposure = data.countrySectorMacro.fundDetails;
          let analysis = data.countrySectorMacro.analysis;
          let Outperformance = data.inception.outPerformance;
          let annulizedReturnBenchMarks = data.inception.annulizedReturnBenchMarks;
          let cumulativeReturnsBenchMarks = data.inception.cumulativeReturnsBenchMarks;
          let upDownRatios = data.inception.ratios;
          let riskRatios = data.inception.riskRatios;
          let correlationAndRegresion = data.inception.correlationAndRegresion;
          let lastDate = data.inception.lastDate;
          let cumulativeReturnsHashTable = data.inception.cumulativeReturnsHashTable;
          let cumulativeReturnsHashTableSince2020 = data.inception.cumulativeReturnsHashTableSince2020;

          setAnnulizedReturn(annulizedReturn[type]);

          setMonthlyReturns(monthlyReturns[type]);
          setVolitality(volitality[type]);
          setVariance(variance[type]);
          setDrawDown(drawdown[type].mdd);
          setRatios(ratios[type]);
          setNormal(normal[type]);

          setExposure(exposure);
          setCountriesNAV(analysis.countryLMVPercentage);
          setSectorsNAV(analysis.sectorLMVPercentage);
          setRegionsNAV(analysis.regionLMVPercentage);
          setMarketsNAV(analysis.marketTypeLMVPercentage);

          setOutPerformance(Outperformance);
          setAnnulizedReturnBenchMarks(annulizedReturnBenchMarks);
          setCumulativeReturnsBenchMarks(cumulativeReturnsBenchMarks);
          setUpDownRatios(upDownRatios);
          setRiskRatios(riskRatios);
          setCorrelationAndRegresion(correlationAndRegresion);
          setLastDate(lastDate);
          setCumulativeReturnsHashTable(cumulativeReturnsHashTable);
          setCumulativeReturnsHashTableSince2020(cumulativeReturnsHashTableSince2020);
          setTreasuryAnnualRate(data.inception.treasuryAnnualRate);
          setFactSheetFiveYears(data.fiveYears);
          setFactSheetTwoYears(data.twoYears);
          setFactSheetChinaPeriod(data.chinaPeriod);

          let years: any = Object.keys(monthlyReturns[type]) || [];
          years = years.reverse();
          setYears(years);
          setFactSheet(data);
        } else {
        }
      });
  }, []);

  function getFactSheet(event: any, type: any) {
    window.location.href = `view-fact-sheet?type=${type}`;
  }
  function getFactSheetMKT(event: any, type: any) {
    window.location.href = `view-fact-sheet-mkt?type=${type}`;
  }
  const componentRef: any = useRef();

  const handleDownloadPdf = async () => {
    const element: any = componentRef.current;
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgProps = pdf.getImageProperties(imgData);

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    let position = 0;
    const pageHeight = pdf.internal.pageSize.getHeight();
    const totalPages = Math.ceil(pdfHeight / pageHeight);

    for (let i = 0; i < totalPages; i++) {
      if (i > 0) {
        pdf.addPage();
        position = -pageHeight * i;
      }
      pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
    }
    pdf.save(`${type}.pdf`);
  };

  if (disabled) {
    return <Unavaliable />;
  }
  if (factSheet == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (factSheet.length == 0) {
    return (
      <div>
        <NavBar />
        <div className="title">
          <h4>No Record</h4>
        </div>
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  return (
    <div className="">
      <NavBar />

      <div className="log-container classes-container">
        {classes.map((className: string, index: number) =>
          shareClasses.includes(className) || accessRole == "admin" || accessRole == "member (risk report)" ? (
            <button key={index} className="btn btn-trades btn-edit-logs btn-share-class" onClick={(event: any) => getFactSheet(event, className)}>
              {className.toLocaleUpperCase()}
            </button>
          ) : null
        )}
        <button className="btn btn-trades btn-edit-logs btn-share-class" onClick={(event: any) => getFactSheetMKT(event, type)}>
          Analytics
        </button>
      </div>
      <div ref={componentRef}>
        <div className="fact-sheet" id="fact-sheet">
          <div className="row">
            <div className="col-lg-6">
              <p className="fact-sheet-title">Triada Capital Limited</p>
              <p className="fact-sheet-title">Triada Asia Credit Opportunities Fund</p>
            </div>{" "}
            <div className="col-lg-6 fact-sheet-photo-container">
              <img src={"/photos/triada-logo.png"} className="header-logo fact-sheet-photo" alt="logo-triada" />
            </div>
            <div className="fact-sheet-title-container">
              <p className="fact-sheet-title">
                Share Class {type.toUpperCase()} Fund Factsheet for {lastDate} - Private & Confidential
              </p>
            </div>
          </div>
          <hr className="fact-sheet-hr"></hr>
          <div className="chart-container col-6">
            <LineChart cumulativeReturnsHashTable={cumulativeReturnsHashTable} title="Since Inception" />
            <LineChart cumulativeReturnsHashTable={cumulativeReturnsHashTableSince2020} title="Since Dec' 2022" />
          </div>
          <div className="row monthly-returns-container">
            <MonthlyReturns months={months} monthsThatMatchDB={monthsThatMatchDB} monthlyReturns={monthlyReturns} years={years} />
          </div>
          <div className="row monthly-returns-container">
            <YearlyStats yearlyStats={yearlyStats} months={months} monthsThatMatchDB={monthsThatMatchDB} monthlyReturns={monthlyReturns} years={years} title="Yearly Statistics for Triada Asia Credit Opportunities Fund Ltd. (“TACO”)" header="Triada" />
          </div>
          <div className="row fact-sheet-content fact-sheet-bottom-border">
            <p className="special-comments">{wording[type]}</p>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 row fact-sheet-zero-padding  fact-sheet-borders ">
              <p className="fact-sheet-sub-title">TACO Fund Relative to Benchmark Statistics (Since Inception)</p>
              <div className="col-12 ">
                <OutPerformance outPerformanceHeaders={outPerformanceHeaders} outPerformance={outPerformance} benchmarksNaming={benchmarksNaming} annulizedReturnBenchMarks={annulizedReturnBenchMarks} cumulativeReturnsBenchMarks={cumulativeReturnsBenchMarks} />
                <RiskRatios benchmarksHeaders={benchmarksHeaders} benchmarksNaming={benchmarksNaming} riskRatios={riskRatios} factSheetTwoYears={factSheetTwoYears} factSheetChinaPeriod={factSheetChinaPeriod} />
                <UpDownRatios benchmarksHeaders={benchmarksHeaders} benchmarksNaming={benchmarksNaming} upDownRatios={upDownRatios} />
                <Regression benchmarksHeaders={benchmarksHeaders} benchmarksNaming={benchmarksNaming} correlationAndRegresion={correlationAndRegresion} />
                <RegressionAlpha benchmarksHeaders={benchmarksHeaders} benchmarksNaming={benchmarksNaming} correlationAndRegresion={correlationAndRegresion} />
              </div>
              <div className="col-12 fact-sheet-sub-container information-container-1">
                <p className="fact-sheet-sub-header-2 fact-sheet-top-border">Fund Information</p>
                <div className="fact-sheet-bottom-border">
                  <div className="fact-sheet-line">
                    <p>Structure:</p>
                    <p className="fact-sheet-text-margin-left">Master Feeder Fund</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Domicile:</p>
                    <p className="fact-sheet-text-margin-left">Cayman Islands</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Manager:</p>
                    <p className="fact-sheet-text-margin-left">Triada Capital Limited</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Advisor:</p>
                    <p className="fact-sheet-text-margin-left">Triada Capital Management Ltd</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Administrator:</p>
                    <p className="fact-sheet-text-margin-left">MUFG Alternative Fund Services (Cayman Limited)</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Auditor:</p>
                    <p className="fact-sheet-text-margin-left">Ernst & Young LLP</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Prime Broker:</p>
                    <p className="fact-sheet-text-margin-left">Nomura International Plc</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Subscription:</p>
                    <p className="fact-sheet-text-margin-left">Monthly</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Redemption:</p>
                    <p className="fact-sheet-text-margin-left">{fundInformation[type].redemption}</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Management Fees:</p>
                    <p className="fact-sheet-text-margin-left">{fundInformation[type].managementFee}</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Performance Fees:</p>
                    <p className="fact-sheet-text-margin-left">{fundInformation[type].performanceFee}</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Lock-Up:</p>
                    <p className="fact-sheet-text-margin-left">
                      <p className="fact-sheet-text-margin-left">{fundInformation[type].lockUp}</p>
                    </p>
                  </div>
                </div>
                <div>
                  <p className="fact-sheet-sub-header-2">Contact Information</p>
                  <div className="fact-sheet-line">
                    <p>Contact:</p>
                    <p className="fact-sheet-text-margin-left">Jean-Marie Barreau</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Tel:</p>
                    <p className="fact-sheet-text-margin-left">+852 3468 8529</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>E-mail:</p>
                    <p className="fact-sheet-text-margin-left">jm@triadacapital.com</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Address:</p>
                    <p className="fact-sheet-text-margin-left">Unit 520, 5/F, Dina House, Ruttonjee Centre, 3-11 Duddell Street, Central, Hong Kong</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 row fact-sheet-zero-padding">
              <p className="fact-sheet-sub-title">TACO Fund Statistics</p>
              <div className="col-12 fact-sheet-zero-padding">
                <AbsoluteReturn annulizedReturn={annulizedReturn} factSheetTwoYears={factSheetTwoYears} factSheetChinaPeriod={factSheetChinaPeriod} factSheetFiveYears={factSheetFiveYears} type={type} treasuryAnnualRate={treasuryAnnualRate} periods={periods} />
                <AbsoluteRisk volitality={volitality} drawDown={drawDown} factSheetTwoYears={factSheetTwoYears} factSheetChinaPeriod={factSheetChinaPeriod} factSheetFiveYears={factSheetFiveYears} type={type} periods={periods} />
                <Efficiency volitality={volitality} ratios={ratios} factSheetTwoYears={factSheetTwoYears} factSheetChinaPeriod={factSheetChinaPeriod} factSheetFiveYears={factSheetFiveYears} type={type} periods={periods} />
                <SkewnessKurtosis normal={normal} variance={variance} factSheetTwoYears={factSheetTwoYears} factSheetChinaPeriod={factSheetChinaPeriod} factSheetFiveYears={factSheetFiveYears} type={type} periods={periods} />
                <RiskAdjustedMultiYear factSheetTwoYears={factSheetTwoYears} factSheetChinaPeriod={factSheetChinaPeriod} factSheetFiveYears={factSheetFiveYears} type={type} riskRatios={riskRatios} periods={periods} />
              </div>

              <div className="col-12 fact-sheet-sub-container">
                <p className="fact-sheet-sub-header">Portfolio Long Positions Concentration (Percentage of NAV)</p>
              </div>
              <div className="col-6">
                {/* <CountriesNAV countriesHeaders={countriesHeaders} countriesNAV={countriesNAV} /> */}
                <RegionsNAV regionsNAV={regionsNAV} />
                <MarketsNAV marketsNAV={marketsNAV} />
              </div>
              <div className="col-6 fact-sheet-zero-padding">
                <SectorsNAV sectorHeaders={sectorHeaders} sectorsNAV={sectorsNAV} />
              </div>

              <div className="col-12 fact-sheet-sub-container">
                <Exposure exposureHeaders={exposureHeaders} exposure={exposure} />
              </div>
              <div className="col-12 fact-sheet-sub-container information-container-2">
                <p className="fact-sheet-sub-header-2 fact-sheet-top-border">Fund Information</p>
                <div className="fact-sheet-bottom-border">
                  <div className="fact-sheet-line">
                    <p>Structure:</p>
                    <p className="fact-sheet-text-margin-left">Master Feeder Fund</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Domicile:</p>
                    <p className="fact-sheet-text-margin-left">Cayman Islands</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Manager:</p>
                    <p className="fact-sheet-text-margin-left">Triada Capital Limited</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Advisor:</p>
                    <p className="fact-sheet-text-margin-left">Triada Capital Management Ltd</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Administrator:</p>
                    <p className="fact-sheet-text-margin-left">MUFG Alternative Fund Services (Cayman Limited)</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Auditor:</p>
                    <p className="fact-sheet-text-margin-left">Ernst & Young LLP</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Prime Broker:</p>
                    <p className="fact-sheet-text-margin-left">Nomura International Plc</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Subscription:</p>
                    <p className="fact-sheet-text-margin-left">Monthly</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Redemption:</p>
                    <p className="fact-sheet-text-margin-left">{fundInformation[type].redemption}</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Management Fees:</p>
                    <p className="fact-sheet-text-margin-left">{fundInformation[type].managementFee}</p>
                  </div>{" "}
                  <div className="fact-sheet-line">
                    <p>Performance Fees:</p>
                    <p className="fact-sheet-text-margin-left">{fundInformation[type].performanceFee}</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Lock-Up:</p>
                    <p className="fact-sheet-text-margin-left">
                      <p className="fact-sheet-text-margin-left">{fundInformation[type].lockUp}</p>
                    </p>
                  </div>
                </div>
                <div>
                  <p className="fact-sheet-sub-header-2">Contact Information</p>
                  <div className="fact-sheet-line">
                    <p>Contact:</p>
                    <p className="fact-sheet-text-margin-left">Jean-Marie Barreau</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Tel:</p>
                    <p className="fact-sheet-text-margin-left">+852 3468 8529</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>E-mail:</p>
                    <p className="fact-sheet-text-margin-left">jm@triadacapital.com</p>
                  </div>
                  <div className="fact-sheet-line">
                    <p>Address:</p>
                    <p className="fact-sheet-text-margin-left">Unit 520, 5/F, Dina House, Ruttonjee Centre, 3-11 Duddell Street, Central, Hong Kong</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 fact-sheet-footer">
              This document does not constitute or form part of any offer or invitation to sell or any solicitation of any offer to purchase or subscribe for shares of Triada Asia Credit Opportunities Fund. The information contained in this documents has been compiled from sources believed to be
              reliable. However, certain information contained herein has been obtained from published sources and/or prepared by other parties. None of Triada Asia Credit Opportunities Fund Ltd., Triada Capital Management Ltd and Triada Capital Limited accept responsibility for the accuracy or
              completeness of such information. The contents of this document have not been reviewed by any regulatory authority. If you are in any doubt about any of the contents of this document, you should obtain independent professional advice. Recipients should make their own inquiries and
              consult their advisors as to legal, tax and related matters concerning Triada Asia Credit Opportunities Fund. All security transactions involve substantial risk of loss. Performance is no assurance of future results.
            </div>
          </div>
        </div>

        <ViewDisclaimers />
      </div>
        {/* <button onClick={(event) => handleDownloadPdf()}>download</button> */}
    </div>
  );
}
export default ViewFactSheetReport;
