import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
function Statistics(props: any) {
  // Find the longest length among the objects
  let analysis = props.analysis;
  let url = props.url || "/?";

  let [currencies, setCurrencies] = useState<any>({});
  let [currenciesNames, setCurrenciesNames] = useState<any>([]);
  let accessRole = localStorage.getItem("accessRole");

  useEffect(() => {
    // Assuming props.summary.analysis.currencies is the object you want to start with
    let analysisCurrencies = props.analysis.currencies;
    let newCurrencies = { ...analysisCurrencies }; // Create a shallow copy

    delete newCurrencies["USD"]; // Remove "USD" key
    let currencyNames = Object.keys(newCurrencies);

    let updatedCurrencies: any = {};

    for (let index = 0; index < currencyNames.length; index++) {
      let currency = currencyNames[index];
      let currencyRate = newCurrencies[currency];

      if (currencyRate < 1) {
        // Convert HKD to USD/HKD rate and update the key
        updatedCurrencies["USD/" + currency] = (1 / currencyRate).toFixed(3);
      } else {
        // Just update the key
        updatedCurrencies[currency + "/USD"] = currencyRate.toFixed(3);
      }
    }

    // Now set the currencies state with the updated values
    setCurrencies(updatedCurrencies);
    setCurrenciesNames(Object.keys(updatedCurrencies));
  }, [props.analysis.currencies]);

  const countryKeys = Object.keys(analysis.countryNAVPercentage).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1;
    return parseFloat(analysis.countryNAVPercentage[b]["percentage"]) - parseFloat(analysis.countryNAVPercentage[a]["percentage"]); // For all other cases, sort alphabetically
  });
  const sectorKeys = Object.keys(analysis.sectorNAVPercentage).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.sectorNAVPercentage[b]["percentage"]) - parseFloat(analysis.sectorNAVPercentage[a]["percentage"]); // For all other cases, sort alphabetically
  });
  const strategyKeys = Object.keys(analysis.strategyNAVPercentage).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.strategyNAVPercentage[b]["percentage"]) - parseFloat(analysis.strategyNAVPercentage[a]["percentage"]); // For all other cases, sort alphabetically
  });

  const issuerKeys = Object.keys(analysis.issuerNAVPercentage).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.issuerNAVPercentage[b]["percentage"]) - parseFloat(analysis.issuerNAVPercentage[a]["percentage"]); // For all other cases, sort alphabetically
  });

  const durationKeys = Object.keys(analysis.duration).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.duration[b]) - parseFloat(analysis.duration[a]); // For all other cases, sort alphabetically
  });
  const longShortKeys = Object.keys(analysis.longShort).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.longShort[b].dv01Sum) - parseFloat(analysis.longShort[a].dv01Sum); // For all other cases, sort alphabetically
  });

  const ratingKeys = Object.keys(analysis.ratingNAVPercentage).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.ratingNAVPercentage[b]["percentage"]) - parseFloat(analysis.ratingNAVPercentage[a]["percentage"]); // For all other cases, sort alphabetically
  });

  const regionKeys = Object.keys(analysis.regionNAVPercentage).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.regionNAVPercentage[b]["percentage"]) - parseFloat(analysis.regionNAVPercentage[a]["percentage"]); // For all other cases, sort alphabetically
  });

  const marketTypesKeys = Object.keys(analysis.marketTypeNAVPercentage).sort((a, b) => {
    if (a.includes("Total")) return -1; // Ensure "sum" comes first
    if (b.includes("Total")) return 1; // Ensure "sum" comes first
    return parseFloat(analysis.marketTypeNAVPercentage[b]["percentage"]) - parseFloat(analysis.marketTypeNAVPercentage[a]["percentage"]); // For all other cases, sort alphabetically
  });
  const assetClassKeys = Object.keys(analysis.assetClassNAVPercentage)
    .sort((a, b) => {
      if (a.includes("Total")) return -1; // Ensure "sum" comes first
      if (b.includes("Total")) return 1; // Ensure "sum" comes first
      return parseFloat(analysis.assetClassNAVPercentage[b]["percentage"]) - parseFloat(analysis.assetClassNAVPercentage[a]["percentage"]); // For all other cases, sort alphabetically
    })
    .filter((name: string) => name != "");
  // const durationKeys = Object.keys(analysis.duration);
  const longestLength = Math.max(countryKeys.length, sectorKeys.length, strategyKeys.length, durationKeys.length, longShortKeys.length, issuerKeys.length, ratingKeys.length, regionKeys.length, marketTypesKeys.length);
  function reload(event: any, param: any) {
    if (accessRole == "admin") {
      window.location.href = url + param;
    }
  }

  function reloadDuration(event: any, duration: string) {
    if (accessRole == "admin") {
      let durationMeaning: any = {
        "0 To 2": [0, 2],
        "2 To 5": [2, 5],
        "5 To 10": [5, 10],
        "10 To 30": [10, 30],
        "> 30": [30, null],
      };
      window.location.href = url + "duration_start=" + durationMeaning[duration][0] + "&duration_end=" + durationMeaning[duration][1];
    }
  }

  // Create rows with data from each object or empty cells if no data is available
  const rows = [];
  for (let i = 0; i < longestLength; i++) {
    const country = countryKeys[i];
    const sector = sectorKeys[i];
    const strategy = strategyKeys[i];
    const issuer = issuerKeys[i];
    const rating = ratingKeys[i];

    const duration = durationKeys[i];
    const longShort = longShortKeys[i];
    const curreny = currenciesNames[i];
    const region = regionKeys[i];
    const marketType = marketTypesKeys[i];
    const assetClass = assetClassKeys[i];

    rows.push(
      <tr key={i} className="sticky-top">
        <td className={country && !country.includes("Total") ? "clickable-param" : ""} onClick={(event) => (country && !country.includes("Total") ? reload(event, `country=${country}`) : "")}>
          {country || ""}
        </td>
        <td className={country && !country.includes("Total") ? "clickable-param" : ""} onClick={(event) => (country && !country.includes("Total") ? reload(event, `country=${country}`) : "")}>
          {country ? `${analysis.countryNAVPercentage[country]["percentage"]} %` : ""}
        </td>
        <td className={country && !country.includes("Total") ? "clickable-param" : ""} onClick={(event) => (country && !country.includes("Total") ? reload(event, `country=${country}`) : "")}>
          {country ? `${analysis.countryNAVPercentage[country]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={country && !country.includes("Total") ? "clickable-param" : ""} onClick={(event) => (country && !country.includes("Total") ? reload(event, `country=${country}`) : "")}>
          {country ? `${analysis.countryGMVPercentage[country]["percentage"]} %` : ""}
        </td>
        <td className={country && !country.includes("Total") ? "clickable-param" : ""} onClick={(event) => (country && !country.includes("Total") ? reload(event, `country=${country}`) : "")}>
          {country ? `${analysis.countryGMVPercentage[country]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={country && !country.includes("Total") ? "clickable-param" : ""} onClick={(event) => (country && !country.includes("Total") ? reload(event, `country=${country}`) : "")}>
          {country && analysis.countryLMVPercentage[country] ? `${analysis.countryLMVPercentage[country]["percentage"]} %` : ""}
        </td>
        <td style={{ borderRight: "1px solid grey" }} className={country && !country.includes("Total") ? "clickable-param" : ""} onClick={(event) => (country && !country.includes("Total") ? reload(event, `country=${country}`) : "")}>
          {country && analysis.countryLMVPercentage[country] ? `${analysis.countryLMVPercentage[country]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={sector && !sector.includes("Total") ? "clickable-param" : ""} onClick={(event) => (sector && !sector.includes("Total") ? reload(event, `sector=${sector}`) : "")}>
          {sector || ""}
        </td>
        <td className={sector && !sector.includes("Total") ? "clickable-param" : ""} onClick={(event) => (sector && !sector.includes("Total") ? reload(event, `sector=${sector}`) : "")}>
          {sector ? `${analysis.sectorNAVPercentage[sector]["percentage"]} %` : ""}
        </td>
        <td className={sector && !sector.includes("Total") ? "clickable-param" : ""} onClick={(event) => (sector && !sector.includes("Total") ? reload(event, `sector=${sector}`) : "")}>
          {sector ? `${analysis.sectorNAVPercentage[sector]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={sector && !sector.includes("Total") ? "clickable-param" : ""} onClick={(event) => (sector && !sector.includes("Total") ? reload(event, `sector=${sector}`) : "")}>
          {sector ? `${analysis.sectorGMVPercentage[sector]["percentage"]} %` : ""}
        </td>
        <td className={sector && !sector.includes("Total") ? "clickable-param" : ""} onClick={(event) => (sector && !sector.includes("Total") ? reload(event, `sector=${sector}`) : "")}>
          {sector ? `${analysis.sectorGMVPercentage[sector]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={sector && !sector.includes("Total") ? "clickable-param" : ""} onClick={(event) => (sector && !sector.includes("Total") ? reload(event, `sector=${sector}`) : "")}>
          {sector && analysis.sectorLMVPercentage[sector] ? `${analysis.sectorLMVPercentage[sector]["percentage"]} %` : ""}
        </td>
        <td style={{ borderRight: "1px solid grey" }} className={sector && !sector.includes("Total") ? "clickable-param" : ""} onClick={(event) => (sector && !sector.includes("Total") ? reload(event, `sector=${sector}`) : "")}>
          {sector && analysis.sectorLMVPercentage[sector] ? `${analysis.sectorLMVPercentage[sector]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={strategy && !strategy.includes("Total") ? "clickable-param" : ""} onClick={(event) => (strategy && !strategy.includes("Total") ? reload(event, `strategy=${strategy}`) : "")}>
          {" "}
          {strategy || ""}
        </td>
        <td className={strategy && !strategy.includes("Total") ? "clickable-param" : ""} onClick={(event) => (strategy && !strategy.includes("Total") ? reload(event, `strategy=${strategy}`) : "")}>
          {strategy ? `${analysis.strategyNAVPercentage[strategy]["percentage"]} %` : ""}
        </td>

        <td className={strategy && !strategy.includes("Total") ? "clickable-param" : ""} onClick={(event) => (strategy && !strategy.includes("Total") ? reload(event, `strategy=${strategy}`) : "")}>
          {strategy ? `${analysis.strategyNAVPercentage[strategy]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={strategy && !strategy.includes("Total") ? "clickable-param" : ""} onClick={(event) => (strategy && !strategy.includes("Total") ? reload(event, `strategy=${strategy}`) : "")}>
          {strategy ? `${analysis.strategyGMVPercentage[strategy]["percentage"]} %` : ""}
        </td>

        <td className={strategy && !strategy.includes("Total") ? "clickable-param" : ""} onClick={(event) => (strategy && !strategy.includes("Total") ? reload(event, `strategy=${strategy}`) : "")}>
          {strategy ? `${analysis.strategyGMVPercentage[strategy]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={strategy && !strategy.includes("Total") ? "clickable-param" : ""} onClick={(event) => (strategy && !strategy.includes("Total") ? reload(event, `strategy=${strategy}`) : "")}>
          {strategy && analysis.strategyLMVPercentage[strategy] ? `${analysis.strategyLMVPercentage[strategy]["percentage"]} %` : ""}
        </td>

        <td style={{ borderRight: "1px solid grey" }} className={strategy && !strategy.includes("Total") ? "clickable-param" : ""} onClick={(event) => (strategy && !strategy.includes("Total") ? reload(event, `strategy=${strategy}`) : "")}>
          {strategy && analysis.strategyLMVPercentage[strategy] ? `${analysis.strategyLMVPercentage[strategy]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={assetClass && !assetClass.includes("Total") ? "clickable-param" : ""} onClick={(event) => (assetClass && !assetClass.includes("Total") ? reload(event, `assetClass=${assetClass}`) : "")}>
          {assetClass || ""}
        </td>
        <td className={assetClass && !assetClass.includes("Total") ? "clickable-param" : ""} onClick={(event) => (assetClass && !assetClass.includes("Total") ? reload(event, `assetClass=${assetClass}`) : "")}>
          {assetClass ? `${analysis.assetClassNAVPercentage[assetClass]["percentage"]} %` : ""}
        </td>

        <td className={assetClass && !assetClass.includes("Total") ? "clickable-param" : ""} onClick={(event) => (assetClass && !assetClass.includes("Total") ? reload(event, `assetClass=${assetClass}`) : "")}>
          {assetClass ? `${analysis.assetClassNAVPercentage[assetClass]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={assetClass && !assetClass.includes("Total") ? "clickable-param" : ""} onClick={(event) => (assetClass && !assetClass.includes("Total") ? reload(event, `assetClass=${assetClass}`) : "")}>
          {assetClass ? `${analysis.assetClassGMVPercentage[assetClass]["percentage"]} %` : ""}
        </td>

        <td className={assetClass && !assetClass.includes("Total") ? "clickable-param" : ""} onClick={(event) => (assetClass && !assetClass.includes("Total") ? reload(event, `assetClass=${assetClass}`) : "")}>
          {assetClass ? `${analysis.assetClassGMVPercentage[assetClass]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={assetClass && !assetClass.includes("Total") ? "clickable-param" : ""} onClick={(event) => (assetClass && !assetClass.includes("Total") ? reload(event, `assetClass=${assetClass}`) : "")}>
          {assetClass && analysis.assetClassLMVPercentage[assetClass] ? `${analysis.assetClassLMVPercentage[assetClass]["percentage"]} %` : ""}
        </td>

        <td style={{ borderRight: "1px solid grey" }} className={assetClass && !assetClass.includes("Total") ? "clickable-param" : ""} onClick={(event) => (assetClass && !assetClass.includes("Total") ? reload(event, `assetClass=${assetClass}`) : "")}>
          {assetClass && analysis.assetClassLMVPercentage[assetClass] ? `${analysis.assetClassLMVPercentage[assetClass]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={issuer && !issuer.includes("Total") ? "clickable-param" : ""} onClick={(event) => (issuer && !issuer.includes("Total") ? reload(event, `issuer=${issuer}`) : "")}>
          {" "}
          {issuer || ""}
        </td>
        <td className={issuer && !issuer.includes("Total") ? "clickable-param" : ""} onClick={(event) => (issuer && !issuer.includes("Total") ? reload(event, `issuer=${issuer}`) : "")}>
          {issuer ? `${analysis.issuerNAVPercentage[issuer]["percentage"]} %` : ""}
        </td>
        <td className={issuer && !issuer.includes("Total") ? "clickable-param" : ""} onClick={(event) => (issuer && !issuer.includes("Total") ? reload(event, `issuer=${issuer}`) : "")}>
          {issuer ? `${analysis.issuerNAVPercentage[issuer]["notional"].toLocaleString()}` : ""}
        </td>

        <td className={issuer && !issuer.includes("Total") ? "clickable-param" : ""} onClick={(event) => (issuer && !issuer.includes("Total") ? reload(event, `issuer=${issuer}`) : "")}>
          {issuer ? `${analysis.issuerGMVPercentage[issuer]["percentage"]} %` : ""}
        </td>
        <td className={issuer && !issuer.includes("Total") ? "clickable-param" : ""} onClick={(event) => (issuer && !issuer.includes("Total") ? reload(event, `issuer=${issuer}`) : "")}>
          {issuer ? `${analysis.issuerGMVPercentage[issuer]["notional"].toLocaleString()}` : ""}
        </td>

        <td className={issuer && !issuer.includes("Total") ? "clickable-param" : ""} onClick={(event) => (issuer && !issuer.includes("Total") ? reload(event, `issuer=${issuer}`) : "")}>
          {issuer && analysis.issuerLMVPercentage[issuer] ? `${analysis.issuerLMVPercentage[issuer]["percentage"]} %` : ""}
        </td>
        <td className={issuer && !issuer.includes("Total") ? "clickable-param" : ""} onClick={(event) => (issuer && !issuer.includes("Total") ? reload(event, `issuer=${issuer}`) : "")}>
          {issuer && analysis.issuerLMVPercentage[issuer] ? `${analysis.issuerLMVPercentage[issuer]["notional"].toLocaleString()}` : ""}
        </td>

        <td className={issuer && !issuer.includes("Total") ? "clickable-param" : ""} onClick={(event) => (issuer && !issuer.includes("Total") ? reload(event, `issuer=${issuer}`) : "")}>
          {issuer && analysis.issuerInformation[issuer] ? `${analysis.issuerInformation[issuer].country}` : ""}
        </td>
        <td style={{ borderRight: "1px solid grey" }} className={issuer && !issuer.includes("Total") ? "clickable-param" : ""} onClick={(event) => (issuer && !issuer.includes("Total") ? reload(event, `issuer=${issuer}`) : "")}>
          {issuer && analysis.issuerInformation[issuer] ? `${analysis.issuerInformation[issuer].rating}` : ""}
        </td>

        <td className={rating && !rating.includes("Total") ? "clickable-param" : ""} onClick={(event) => (rating && !rating.includes("Total") ? reload(event, `rating=${rating}`) : "")}>
          {rating || ""}
        </td>
        <td className={rating && !rating.includes("Total") ? "clickable-param" : ""} onClick={(event) => (rating && !rating.includes("Total") ? reload(event, `rating=${rating}`) : "")}>
          {rating && analysis.ratingNAVPercentage[rating] ? `${analysis.ratingNAVPercentage[rating]["percentage"]} %` : ""}
        </td>
        <td className={rating && !rating.includes("Total") ? "clickable-param" : ""} onClick={(event) => (rating && !rating.includes("Total") ? reload(event, `rating=${rating}`) : "")}>
          {rating && analysis.ratingNAVPercentage[rating] ? `${analysis.ratingNAVPercentage[rating]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={rating && !rating.includes("Total") ? "clickable-param" : ""} onClick={(event) => (rating && !rating.includes("Total") ? reload(event, `rating=${rating}`) : "")}>
          {rating && analysis.ratingGMVPercentage[rating] ? `${analysis.ratingGMVPercentage[rating]["percentage"]} %` : ""}
        </td>
        <td className={rating && !rating.includes("Total") ? "clickable-param" : ""} onClick={(event) => (rating && !rating.includes("Total") ? reload(event, `rating=${rating}`) : "")}>
          {rating && analysis.ratingGMVPercentage[rating] ? `${analysis.ratingGMVPercentage[rating]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={rating && !rating.includes("Total") ? "clickable-param" : ""} onClick={(event) => (rating && !rating.includes("Total") ? reload(event, `rating=${rating}`) : "")}>
          {rating && analysis.ratingLMVPercentage[rating] ? `${analysis.ratingLMVPercentage[rating]["percentage"]} %` : ""}
        </td>
        <td style={{ borderRight: "1px solid grey" }} className={rating && !rating.includes("Total") ? "clickable-param" : ""} onClick={(event) => (rating && !rating.includes("Total") ? reload(event, `rating=${rating}`) : "")}>
          {rating && analysis.ratingLMVPercentage[rating] ? `${analysis.ratingLMVPercentage[rating]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={region && !region.includes("Total") ? "clickable-param" : ""} onClick={(event) => (region && !region.includes("Total") ? reload(event, `region=${region}`) : "")}>
          {region || ""}
        </td>
        <td className={region && !region.includes("Total") ? "clickable-param" : ""} onClick={(event) => (region && !region.includes("Total") ? reload(event, `region=${region}`) : "")}>
          {region && analysis.regionNAVPercentage[region] ? `${analysis.regionNAVPercentage[region]["percentage"]} %` : ""}
        </td>
        <td className={region && !region.includes("Total") ? "clickable-param" : ""} onClick={(event) => (region && !region.includes("Total") ? reload(event, `region=${region}`) : "")}>
          {region && analysis.regionNAVPercentage[region] ? `${analysis.regionNAVPercentage[region]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={region && !region.includes("Total") ? "clickable-param" : ""} onClick={(event) => (region && !region.includes("Total") ? reload(event, `region=${region}`) : "")}>
          {region && analysis.regionGMVPercentage[region] ? `${analysis.regionGMVPercentage[region]["percentage"]} %` : ""}
        </td>
        <td className={region && !region.includes("Total") ? "clickable-param" : ""} onClick={(event) => (region && !region.includes("Total") ? reload(event, `region=${region}`) : "")}>
          {region && analysis.regionGMVPercentage[region] ? `${analysis.regionGMVPercentage[region]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={region && !region.includes("Total") ? "clickable-param" : ""} onClick={(event) => (region && !region.includes("Total") ? reload(event, `region=${region}`) : "")}>
          {region && analysis.regionLMVPercentage[region] ? `${analysis.regionLMVPercentage[region]["percentage"]} %` : ""}
        </td>
        <td style={{ borderRight: "1px solid grey" }} className={region && !region.includes("Total") ? "clickable-param" : ""} onClick={(event) => (region && !region.includes("Total") ? reload(event, `region=${region}`) : "")}>
          {region && analysis.regionLMVPercentage[region] ? `${analysis.regionLMVPercentage[region]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={marketType && !marketType.includes("Total") ? "clickable-param" : ""} onClick={(event) => (marketType && !marketType.includes("Total") ? reload(event, `marketType=${marketType}`) : "")}>
          {marketType || ""}
        </td>
        <td className={marketType && !marketType.includes("Total") ? "clickable-param" : ""} onClick={(event) => (marketType && !marketType.includes("Total") ? reload(event, `marketType=${marketType}`) : "")}>
          {marketType && analysis.marketTypeNAVPercentage[marketType] ? `${analysis.marketTypeNAVPercentage[marketType]["percentage"]} %` : ""}
        </td>
        <td className={marketType && !marketType.includes("Total") ? "clickable-param" : ""} onClick={(event) => (marketType && !marketType.includes("Total") ? reload(event, `marketType=${marketType}`) : "")}>
          {marketType && analysis.marketTypeNAVPercentage[marketType] ? `${analysis.marketTypeNAVPercentage[marketType]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={marketType && !marketType.includes("Total") ? "clickable-param" : ""} onClick={(event) => (marketType && !marketType.includes("Total") ? reload(event, `marketType=${marketType}`) : "")}>
          {marketType && analysis.marketTypeGMVPercentage[marketType] ? `${analysis.marketTypeGMVPercentage[marketType]["percentage"]} %` : ""}
        </td>
        <td className={marketType && !marketType.includes("Total") ? "clickable-param" : ""} onClick={(event) => (marketType && !marketType.includes("Total") ? reload(event, `marketType=${marketType}`) : "")}>
          {marketType && analysis.marketTypeGMVPercentage[marketType] ? `${analysis.marketTypeGMVPercentage[marketType]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={marketType && !marketType.includes("Total") ? "clickable-param" : ""} onClick={(event) => (marketType && !marketType.includes("Total") ? reload(event, `marketType=${marketType}`) : "")}>
          {marketType && analysis.marketTypeLMVPercentage[marketType] ? `${analysis.marketTypeLMVPercentage[marketType]["percentage"]} %` : ""}
        </td>
        <td style={{ borderRight: "1px solid grey" }} className={marketType && !marketType.includes("Total") ? "clickable-param" : ""} onClick={(event) => (marketType && !marketType.includes("Total") ? reload(event, `marketType=${marketType}`) : "")}>
          {marketType && analysis.marketTypeLMVPercentage[marketType] ? `${analysis.marketTypeLMVPercentage[marketType]["notional"].toLocaleString()} ` : ""}
        </td>

        <td className={duration && duration != "Total" ? "clickable-param" : ""} onClick={(event) => (duration && duration != "Total" ? reloadDuration(event, duration) : "")}>
          {duration || ""}
        </td>
        <td style={{ borderRight: "1px solid grey" }} className={duration && duration != "Total" ? "clickable-param" : ""} onClick={(event) => (duration && duration != "Total" ? reloadDuration(event, duration) : "")}>
          {duration ? `${analysis.duration[duration] ? analysis.duration[duration].dv01Sum : ""}` : ""}
        </td>

        <td className={longShort && longShort != "Total" ? "clickable-param" : ""}>{longShort || ""}</td>

        <td className={longShort && longShort != "Total" ? "clickable-param" : ""}>{longShort ? `${analysis.longShort[longShort] ? analysis.longShort[longShort].dv01Sum : ""}` : ""}</td>
        <td style={{ borderRight: "1px solid grey" }} className={longShort && longShort != "Total" ? "clickable-param" : ""}>
          {longShort ? `${analysis.longShort[longShort] ? analysis.longShort[longShort].intSum : ""}` : ""}
        </td>

        <td className={curreny && curreny != "Total" ? "clickable-param" : ""} onClick={(event) => (curreny && curreny != "Total" ? reload(event, `currency=${curreny.split("USD/").length > 1 ? curreny.split("/")[1] : curreny.split("/")[0]}`) : "")}>
          {" "}
          {curreny || ""}
        </td>
        <td className={curreny && curreny != "Total" ? "clickable-param" : ""} onClick={(event) => (curreny && curreny != "Total" ? reload(event, `currency=${curreny.split("USD/").length > 1 ? curreny.split("/")[1] : curreny.split("/")[0]}`) : "")}>
          {curreny ? `${currencies[curreny]}` : ""}
        </td>
      </tr>
    );
  }

  return (
    <div className="statistics-table-container">
      <table className="table table-striped table-light table-hover statistics-table" id={props.tableId}>
        <tbody>
          <tr className="sticky-top">
            <td style={{ width: "70px" }}>Country</td>
            <td>Sum of NAV %</td>
            <td>Sum of (notional NAV USD)</td>
            <td>Sum of GMV %</td>
            <td>Sum of (notional GMV USD)</td>
            <td>Sum of LMV %</td>
            <td style={{ borderRight: "1px solid grey" }}>Sum of (notional LMV USD)</td>

            <td style={{ width: "70px" }}>Sector</td>
            <td>Sum of NAV %</td>
            <td>Sum of (notional NAV USD)</td>
            <td>Sum of GMV %</td>
            <td>Sum of (notional GMV USD)</td>
            <td>Sum of LMV %</td>
            <td style={{ borderRight: "1px solid grey" }}>Sum of (notional LMV USD)</td>

            <td style={{ width: "70px" }}>Strategy</td>
            <td>Sum of NAV %</td>
            <td>Sum of (notional NAV USD)</td>
            <td>Sum of GMV %</td>
            <td>Sum of (notional GMV USD)</td>
            <td>Sum of LMV %</td>
            <td style={{ borderRight: "1px solid grey" }}>Sum of (notional LMV USD)</td>

            <td style={{ width: "70px" }}>Asset Class</td>
            <td>Sum of NAV %</td>
            <td>Sum of (notional NAV USD)</td>
            <td>Sum of GMV %</td>
            <td>Sum of (notional GMV USD)</td>
            <td>Sum of LMV %</td>
            <td style={{ borderRight: "1px solid grey" }}>Sum of (notional LMV USD)</td>

            <td style={{ width: "70px" }}>Issuer</td>
            <td>Sum of NAV %</td>
            <td>Sum of (notional NAV USD)</td>
            <td>Sum of GMV %</td>
            <td>Sum of (notional GMV USD)</td>
            <td>Sum of LMV %</td>
            <td>Sum of (notional LMV USD)</td>
            <td style={{ width: "70px" }}>Issuer's Country</td>
            <td style={{ borderRight: "1px solid grey", width: "70px" }}>BBG / S&P / Moody / Fitch Rating</td>

            <td style={{ width: "70px" }}>Rating Category</td>
            <td>Sum of NAV %</td>
            <td>Sum of (notional NAV USD)</td>
            <td>Sum of GMV %</td>
            <td>Sum of (notional GMV USD)</td>
            <td>Sum of LMV %</td>
            <td style={{ borderRight: "1px solid grey" }}>Sum of (notional LMV USD)</td>

            <td style={{ width: "70px" }}>Region</td>
            <td>Sum of NAV %</td>
            <td>Sum of (notional NAV USD)</td>
            <td>Sum of GMV %</td>
            <td>Sum of (notional GMV USD)</td>
            <td>Sum of LMV %</td>
            <td style={{ borderRight: "1px solid grey" }}>Sum of (notional LMV USD)</td>

            <td style={{ width: "70px" }}>Market Class</td>
            <td>Sum of NAV %</td>
            <td>Sum of (notional NAV USD)</td>
            <td>Sum of GMV %</td>
            <td>Sum of (notional GMV USD)</td>
            <td>Sum of LMV %</td>
            <td style={{ borderRight: "1px solid grey" }}>Sum of (notional LMV USD)</td>

            <td style={{ width: "70px" }}>Duration</td>
            <td style={{ borderRight: "1px solid grey" }}>Sum of DV01</td>

            <td style={{ width: "70px" }}>L/S</td>
            <td>Sum of DV01</td>
            <td style={{ borderRight: "1px solid grey" }}>Sum of Int.</td>

            <td style={{ width: "70px" }}>Currency</td>
            <td>Mid</td>
          </tr>

          {rows}
        </tbody>
      </table>
    </div>
  );
}

export default Statistics;
