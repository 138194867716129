import Statistics from "../portfolio/statistics";
import AssetClassHYTable from "./tables/assetClassHYTable";
import AssetClassIGTable from "./tables/assetClassIGTable";
import CurrTable from "./tables/currTable";
import GlobalHedgeTable from "./tables/globalHedgeTable";
import PlTable from "./tables/plTable";
import RVPairTabel from "./tables/rvPairTable";
import * as XLSX from "xlsx";

function ViewRiskTables(props: any) {
  let fundDetails = props.summary.fundDetails;
  let analysis: any = props.summary.analysis;
  let titles = props.titles;
  const downloadAll = () => {
    // Define all table IDs
    const tableIds = ["table-rv", "table-ig", "table-hy", "table-curr", "table-fund", "statistics"];
    let tableNames: any = {
      "table-rv": "RV",
      "table-ig": "IG",
      "table-hy": "HY",
      "table-curr": "Curr",
      "table-fund": "Fund",
      statistics: "statistics",
    };

    const wb = XLSX.utils.book_new(); // Create a new workbook

    tableIds.forEach((tableId, index) => {
      let table = document.getElementById(tableId);
      if (table) {
        const ws = XLSX.utils.table_to_sheet(table);
        XLSX.utils.book_append_sheet(wb, ws, `${tableNames[tableId]}`);
      } else {
        console.error("Table not found:", tableId);
      }
    });

    // Write the workbook to a file
    XLSX.writeFile(wb, `risk_${props.type}.xlsx`);
  };

  return (
    <div className="unassigned-container">
      <div>
        <p className="title-unassigned">Summary</p>
      </div>

      <PlTable fundDetails={fundDetails} analysis={analysis} tableId={"table-fund"} />

      <p className="title-unassigned">RV</p>
      <RVPairTabel titles={titles} analysis={analysis} getTrades={props.getTrades} positionInfo={props.positionInfo} type={props.type} tableId={"table-rv"} />
      <p className="title-unassigned">IG</p>
      <AssetClassIGTable titles={titles} analysis={analysis} getTrades={props.getTrades} positionInfo={props.positionInfo} type={props.type} tableId={"table-ig"} />
      <p className="title-unassigned">HY</p>
      <AssetClassHYTable titles={titles} analysis={analysis} getTrades={props.getTrades} positionInfo={props.positionInfo} type={props.type} tableId={"table-hy"} />
      <p className="title-unassigned">Currency</p>
      <CurrTable titles={titles} analysis={analysis} getTrades={props.getTrades} positionInfo={props.positionInfo} type={props.type} tableId={"table-curr"} />

      <p className="title-unassigned">Global Hedge</p>
      <GlobalHedgeTable titles={titles} analysis={analysis} getTrades={props.getTrades} positionInfo={props.positionInfo} type={props.type} tableId={"table-global-hedge"} />

      <Statistics analysis={props.analysis} url={"/?"} date={props.date} tableId={"statistics"} />

      <button id="download-btn" onClick={downloadAll} className="btn upload-btn">
        Download Report
      </button>
    </div>
  );
}

export default ViewRiskTables;
