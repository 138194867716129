import { useState } from "react";
import PlTable from "../portfolio/plTable";
import USTTable from "./tables/ustTable";
import UstTableByCoupon from "./tables/ustTableByCoupon";
import RVPairTabel from "./tables/rvPairTable";
import AssetClassIGTable from "./tables/assetClassIGTable";
import AssetClassHYTable from "./tables/assetClassHYTable";
import CurrTable from "./tables/currTable";

function ViewRiskTables(props: any) {
  let fundDetails = props.summary.fundDetails;
  let analysis: any = props.summary.analysis;
  let titles = props.titles;

  return (
    <div className="unassigned-container">
      <div>
        <p className="title-unassigned">Summary</p>
      </div>

      <PlTable fundDetails={fundDetails} analysis={analysis} />

      <p className="title-unassigned">UST by Duration</p>
      <USTTable titles={titles} analysis={analysis} getTrades={props.getTrades} positionInfo={props.positionInfo} prefixId={"content_1"} />
      <p className="title-unassigned">UST by Coupon</p>
      <UstTableByCoupon titles={titles} analysis={analysis} getTrades={props.getTrades} positionInfo={props.positionInfo} prefixId={"content_2"} />
      <p className="title-unassigned">RV Pair</p>
      <RVPairTabel titles={titles} analysis={analysis} getTrades={props.getTrades} positionInfo={props.positionInfo} prefixId={"content_3"} />
      <p className="title-unassigned">IG</p>
      <AssetClassIGTable titles={titles} analysis={analysis} getTrades={props.getTrades} positionInfo={props.positionInfo} prefixId={"content_4"} />
      <p className="title-unassigned">HY</p>
      <AssetClassHYTable titles={titles} analysis={analysis} getTrades={props.getTrades} positionInfo={props.positionInfo} prefixId={"content_5"} />
      <p className="title-unassigned">Currency</p>
      <CurrTable titles={titles} analysis={analysis} getTrades={props.getTrades} positionInfo={props.positionInfo} prefixId={"content_6"} />
    </div>
  );
}

export default ViewRiskTables;
