import { useState } from "react";
import NavBar from "../../common/navbar";
import Loader from "../../common/loader";
import { baseUrl, handleAuth, postRequestOptions } from "../../common/cookie";
import axios from "axios";

function FxExcel() {
  let [error, setError] = useState<any>(null);
  async function handleSubmit(event: any) {
    event.preventDefault();
    setRequestStatus(true);

    // handle file processing here
    const form: any = document.getElementById("fx-excel-form");
    const formData = new FormData(form);

    try {
      setError(null);
      let url = baseUrl + "fx-excel";
      let action: any = await axios.post(url, formData, postRequestOptions);

      handleAuth(action);
      // console.log(action)
      if (action.data.error) {
        setError(action.data.error);
      } else {
        setTimeout(() => {}, 2000);
        window.open(action.data);
      }
      setRequestStatus(false);
    } catch (error) {
      console.error("Error:", error);
      setRequestStatus(false);
    }
  }

  const [request, setRequestStatus] = useState(false);

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div className="title">
        <h1>Generate Formatted FX Excel</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form className="card-form" id="fx-excel-form" onSubmit={(event) => handleSubmit(event)}>
          <h2>Start Time</h2>

          <input id="start" title="next" type="datetime-local" className="formTextInput inputCustom" name="timestamp_start" required />
          <h2 className="input-custom">End Time</h2>

          <input id="end" title="next" type="datetime-local" className="formTextInput inputCustom" name="timestamp_end" required />
          <div className="btn-container">
            <button type="submit" className="btn upload-btn">
              Generate
            </button>
          </div>
        </form>
      </div>
      <h4 className="error-text">{error}</h4>
    </div>
  );
}

export default FxExcel;
