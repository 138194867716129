import { useEffect, useState } from "react";
import TopWorst from "./topWorst";

function ViewPerformersTables(props: any) {
  let analysis: any = props.summary.analysis;
  let titles = props.titles;
  let top5Day = analysis.topWorstPerformaners.top5Day;
  let top5MTD = analysis.topWorstPerformaners.top5MTD;
  let worst5Day = analysis.topWorstPerformaners.worst5Day;
  let worst5MTD = analysis.topWorstPerformaners.worst5MTD;
  let view = props.view;
  let performersType: "pl" | "price move" = props.performersType;
  let titleDay = performersType == "pl" ? "Day P&L (USD)" : "Day Price Move";
  let titleMTD = performersType == "pl" ? "MTD P&L (USD)" : "MTD Price Move";
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let sortObject = {
    pl: { day: "groupDayPl", mtd: "groupMTDPl" },
    "price move": { day: "groupDayPriceMoveSum", mtd: "groupMTDPriceMoveSum" },
  };

  return (
    <div className="unassigned-container" id="content" >
      <p
        className="title-unassigned margin-bottom-5 clickable"
        style={{
          cursor: "pointer",
        }}
        onClick={(event) => props.reRouteSummary(event, sortObject[performersType].day, -1, titleDay)}
      >
        Worst 5 Day Performers
      </p>

      <TopWorst titles={titles} topWorst={worst5Day} getTrades={props.getTrades} positionInfo={props.positionInfo} view={view} screenWidth={screenWidth} date={props.date} idPrefix={"1"}/>
      <p
        className="title-unassigned margin-bottom-5 clickable"
        style={{
          cursor: "pointer",
        }}
        onClick={(event) => props.reRouteSummary(event, sortObject[performersType].mtd, -1, titleMTD)}
      >
        Worst 5 MTD Performers
      </p>
      <TopWorst titles={titles} topWorst={worst5MTD} getTrades={props.getTrades} positionInfo={props.positionInfo} view={view} screenWidth={screenWidth} date={props.date} idPrefix={"2"}/>

      <p
        className="title-unassigned margin-bottom-5 clickable"
        style={{
          cursor: "pointer",
        }}
        onClick={(event) => props.reRouteSummary(event, sortObject[performersType].day, 1, titleDay)}
      >
        Top 5 Day Performers
      </p>
      <TopWorst titles={titles} topWorst={top5Day} getTrades={props.getTrades} positionInfo={props.positionInfo} view={view} screenWidth={screenWidth} date={props.date} idPrefix={"3"}/>

      <p
        className="title-unassigned margin-bottom-5 clickable"
        style={{
          cursor: "pointer",
        }}
        onClick={(event) => props.reRouteSummary(event, sortObject[performersType].mtd, 1, titleMTD)}
      >
        Top 5 MTD Performers
      </p>

      <TopWorst titles={titles} topWorst={top5MTD} getTrades={props.getTrades} positionInfo={props.positionInfo} view={view} screenWidth={screenWidth} date={props.date} idPrefix={"4"}/>
    </div>
  );
}

export default ViewPerformersTables;
