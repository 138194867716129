function UpDownRatios(props: any) {
  let benchmarksHeaders = props.benchmarksHeaders;
  let upDownRatios = props.upDownRatios;
  let benchmarksNaming = props.benchmarksNaming;
  console.log(upDownRatios);

  return (
    <div className="col-12 fact-sheet-sub-container">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats-1 fact-sheet-outperformance-table">
        <tbody>
          <tr className="sticky-top table-header">
            {benchmarksHeaders.map((title: string, index: number) => (
              <th className={title == "Fund" ? "fact-sheet-long-title" : ""} key={index}>
                {benchmarksNaming[title] || "Relative Return Statistics"}
              </th>
            ))}
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Down Capture Ratio</td>
            <td></td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[2]]["Down Capture Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[3]]["Down Capture Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[4]]["Down Capture Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[5]]["Down Capture Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[6]]["Down Capture Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Down Number Ratio</td>
            <td></td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[2]]["Down Number Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[3]]["Down Number Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[4]]["Down Number Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[5]]["Down Number Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[6]]["Down Number Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Down Percentage Ratio</td>
            <td></td>

            <td>{(Math.round(upDownRatios[benchmarksHeaders[2]]["Down Percentage Ratio"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[3]]["Down Percentage Ratio"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[4]]["Down Percentage Ratio"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[5]]["Down Percentage Ratio"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[6]]["Down Percentage Ratio"] * 10000) / 100).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Up Capture Ratio</td>
            <td></td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[2]]["Up Capture Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[3]]["Up Capture Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[4]]["Up Capture Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[5]]["Up Capture Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[6]]["Up Capture Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Up Number Ratio</td>
            <td></td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[2]]["Up Number Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[3]]["Up Number Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[4]]["Up Number Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[5]]["Up Number Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[6]]["Up Number Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Up Percentage Ratio</td>
            <td></td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[2]]["Up Percentage Ratio"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[3]]["Up Percentage Ratio"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[4]]["Up Percentage Ratio"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[5]]["Up Percentage Ratio"] * 10000) / 100).toFixed(2)}</td>
            <td>{(Math.round(upDownRatios[benchmarksHeaders[6]]["Up Percentage Ratio"] * 10000) / 100).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default UpDownRatios;
