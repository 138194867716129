import { useState } from "react";

import { Filter } from "../../../common/functions";
import PlTable from "./plTable";
import Statistics from "./statistics";

function ViewSummaryFund(props: any) {
  let fundDetails = props.summary.fundDetails;
  let analysis: any = props.summary.analysis;
  let [moreInfoDisplay, setMoreInfoDisplay] = useState("none");
  let url = props.url || "/?";
  let statistics = props.statistics;
  let filter = props.filter;

  return (
    <div className="unassigned-container">
      <div>
        <p className="title-unassigned">Summary</p>
      </div>

      <div className="more-info-container">
        {statistics && (
          <div className="more-info-container-text" onClick={(event) => setMoreInfoDisplay(moreInfoDisplay == "none" ? "block" : "none")}>
            More Info
          </div>
        )}
      </div>
      {filter && (
        <div onClick={(event: any) => props.setFilterCardDisplay("block")} className="filter-svg-container">
          <Filter />
        </div>
      )}

      <PlTable fundDetails={fundDetails} analysis={analysis} />
      {statistics && (
        <div>
          <p
            className="title-unassigned"
            style={{
              display: moreInfoDisplay,
            }}
          >
            Statistics
          </p>
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              display: moreInfoDisplay,
            }}
            className=" fadeIn"
          >
            <Statistics analysis={analysis} url={url} date={props.date} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewSummaryFund;
