function RiskAdjustedMultiYear(props: any) {
  let riskRatios = props.riskRatios;

  let factSheetTwoYears = props.factSheetTwoYears;
  let factSheetFiveYears = props.factSheetFiveYears;
  let factSheetChinaPeriod = props.factSheetChinaPeriod;
  let periods = props.periods;
  return (
    <div className="col-12 fact-sheet-sub-container">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats-1">
        <tbody>
          <tr className="sticky-top table-header">
            <th>Absolute Risk Statistics</th>
            {periods.map((period: string, index: string) => (
              <th>{period}</th>
            ))}
          </tr>
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Sharpe Ratio</td>
            <td>{(Math.round(factSheetTwoYears.riskRatios["Triada"]["Sharpe Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(factSheetChinaPeriod.riskRatios["Triada"]["Sharpe Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(factSheetFiveYears.riskRatios["Triada"]["Sharpe Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios["Triada"]["Sharpe Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Sortino Ratio</td>
            <td>{(Math.round(factSheetTwoYears.riskRatios["Triada"]["Sortino Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(factSheetChinaPeriod.riskRatios["Triada"]["Sortino Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(factSheetFiveYears.riskRatios["Triada"]["Sortino Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios["Triada"]["Sortino Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Sterling Ratio</td>
            <td>{(Math.round(factSheetTwoYears.riskRatios["Triada"]["Sterling Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(factSheetChinaPeriod.riskRatios["Triada"]["Sterling Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(factSheetFiveYears.riskRatios["Triada"]["Sterling Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios["Triada"]["Sterling Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
          <tr className="fact-sheet-row">
            <td className="fact-sheet-col-grey">Treynor Ratio</td>
            <td>{(Math.round(factSheetTwoYears.riskRatios["Triada"]["Treynor Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(factSheetChinaPeriod.riskRatios["Triada"]["Treynor Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(factSheetFiveYears.riskRatios["Triada"]["Treynor Ratio"] * 10000) / 10000).toFixed(2)}</td>
            <td>{(Math.round(riskRatios["Triada"]["Treynor Ratio"] * 10000) / 10000).toFixed(2)}</td>
          </tr>{" "}
        </tbody>
      </table>
    </div>
  );
}
export default RiskAdjustedMultiYear;
