import { useState } from "react";
import { CloseIcon, getQueryParamters } from "../../../common/functions";
import CreatableSelect from "react-select/creatable";
import { passConditions, reactSelectFormat } from "./tool";
import { baseUrl } from "../../../common/cookie";

function FilterCardAnalytics(props: any) {
  let countries = reactSelectFormat(Array.from(props.analytics.countries));
  let isinNames = props.analytics.isinNames;

  let sectors = reactSelectFormat(Array.from(props.analytics.sectors));

  let strategies = reactSelectFormat(Array.from(props.analytics.strategies));

  let issuers = reactSelectFormat(Array.from(props.analytics.issuers));

  let regions = reactSelectFormat(Array.from(props.analytics.regions));

  let ratings = reactSelectFormat(Array.from(props.analytics.ratings));

  let marketTypes = reactSelectFormat(Array.from(props.analytics.marketTypes));

  let assetClass = reactSelectFormat(["Hedge", "IG", "HY"]);

  let [country, setCountry] = useState([]);
  let [sector, setSector] = useState([]);
  let [strategy, setStrategy] = useState([]);
  let [assetClassParam, setAssetClass] = useState([]);
  let [issuer, setIssuer] = useState([]);
  let [bbTicker, setBBTicker] = useState<any>([]);
  let [rating, setRating] = useState([]);
  let [region, setRegion] = useState([]);
  let [marketType, setMarketType] = useState([]);

  const [selectedRadio, setSelectedRadio] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleRadioChange = (event: any) => {
    setSelectedRadio(event.target.value);
  };

  const handleCheckboxChange = (event: any) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  function handleFilter(event: any) {
    let override = "";
    let temp = "";

    if (country.length !== 0) {
      override += "country=" + passConditions(country) + "&";
    }
    if (sector.length !== 0) {
      override += "sector=" + passConditions(sector) + "&";
    }
    if (strategy.length !== 0) {
      override += "strategy=" + passConditions(strategy) + "&";
    }
    if (issuer.length !== 0) {
      override += "issuer=" + passConditions(issuer) + "&";
    }
    if (bbTicker.length !== 0) {
      for (let index = 1; index < bbTicker.length; index++) {
        let element = bbTicker[index].label;
        temp += element + "@";
      }
      override += "positions=" + passConditions(bbTicker) + "&";
    }

    if (assetClassParam.length !== 0) {
      override += "assetClass=" + passConditions(assetClassParam) + "&";
    }
    if (rating.length !== 0) {
      override += "rating=" + passConditions(rating) + "&";
    }
    if (region.length !== 0) {
      override += "region=" + passConditions(region) + "&";
    }
    if (marketType.length !== 0) {
      override += "marketType=" + passConditions(marketType) + "&";
    }
    if (selectedRadio) {
      override += "type=" + selectedRadio + "&";
      props.setType(selectedRadio);
    } else {
      props.setType("");
    }
    if (isCheckboxChecked) {
      override += "notOperation=" + isCheckboxChecked + "&";
    }

    props.setQuery(override);
    props.setFilterText(getQueryParamters(override.toString(), temp));
    override = baseUrl + `compare?start=${props.fromRange}&end=${props.toRange}&` + override;
    props.getAnalytics(event, override);
    props.setFilterCardDisplay("none");
    setSelectedRadio("");
    setIsCheckboxChecked(false);
  }

  return (
    <div className="edit-info-container-1">
      <div onClick={(event) => props.setFilterCardDisplay("none")}>
        <CloseIcon />
      </div>
      <div className="position-container filter-info-container-2 filter-card">
        <form className="filter-form">
          <p>Issuer</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={issuers}
            onChange={(event: any) => setIssuer(event ? event || [] : [])}
            isMulti
          />
          <p>BB Ticker</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={isinNames}
            onChange={(event: any) => setBBTicker(event ? event || [] : [])}
            isMulti
          />

          <p>Strategy</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={strategies}
            onChange={(event: any) => setStrategy(event ? event || [] : [])}
            isMulti
          />

          <p>Asset Class</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={assetClass}
            onChange={(event: any) => setAssetClass(event ? event || [] : [])}
            isMulti
          />

          <p>Country</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={countries}
            onChange={(event: any) => setCountry(event ? event || [] : [])}
            isMulti
          />

          <p>Sector</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={sectors}
            onChange={(event: any) => setSector(event ? event || [] : [])}
            isMulti
          />

          <p>Region</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={regions}
            onChange={(event: any) => setRegion(event ? event || [] : [])}
            isMulti
          />

          <p>Rating</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={ratings}
            onChange={(event: any) => setRating(event ? event || [] : [])}
            isMulti
          />

          <p>Market Class</p>
          <CreatableSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ value: "", label: "" }}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isRtl={isRtl}
            isSearchable={isSearchable}
            options={marketTypes}
            onChange={(event: any) => setMarketType(event ? event || [] : [])}
            isMulti
          />
          <div>
            <div className="checkbox">
              <input type="radio" name="options" id="pnl" value="pnl" onChange={handleRadioChange} /> P&L amount, specific to Triada.
            </div>
            <div className="checkbox">
              <input type="radio" name="options" id="percentage" value="percentage" onChange={handleRadioChange} /> P&L amount % of NAV, specific to Triada.
            </div>

            {/* <div className="checkbox">
              <input type="radio" name="options" id="performance" value="performance" onChange={handleRadioChange} /> Performance, divides positions' pnl by its USD Value (weighted average return)
            </div> */}

            <div className="checkbox">
              <input type="checkbox" id="notOperation" value="notOperation" onChange={handleCheckboxChange} /> All Portfolio but those selected.
            </div>
          </div>

          <div className="edit-info-edit-container">
            <button type="button" className="btn upload-btn btn-filter" onClick={(event) => handleFilter(event)}>
              Filter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FilterCardAnalytics;
