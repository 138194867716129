import NavBar from "../../common/navbar";
import { baseUrl, postRequestOptions } from "../../common/cookie";
import Loader from "../../common/loader";
import { useState } from "react";
import axios from "axios";
import { handleAuth } from "../../common/cookie";

function MufgFxExcel() {

  let [error, setError] = useState<any>(null);
  async function handleSubmit(event: any) {
    
    event.preventDefault();
    setRequestStatus(true);

    // handle file processing here
    const form: any = document.getElementById("elec-blot-form");
    const formData = new FormData(form);

    try {
      setError(null);
      let url = baseUrl + "mufg-fx";
      let action: any = await axios.post(url, formData, postRequestOptions);
      handleAuth(action);
      //   console.log(action);
      if (action.data.error) {
        setError(action.data.error);
      } else {
        console.log(action);
        setTimeout(() => {}, 2000);
        window.open(action.data);
      }

      setRequestStatus(false);
    } catch (error) {
      console.error("Error:", error);

      setRequestStatus(false);
    }
  }

  const [request, setRequestStatus] = useState(false);

  if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <NavBar />
      <div className="title">
        <h1>Generate Formatted FX MUFG Excel</h1>
      </div>
      <div className="card-body card row xlsx-card">
        <form onSubmit={(event) => handleSubmit(event)} encType="multipart/form-data" id="elec-blot-form">
          <div>
            <h3>Input Formatted FX Excel csv/xlsx file</h3>
          </div>

          <div className="drop-container">
            <input multiple={true} className="drop-box-input" placeholder="Upload or drop file" type="file" name="fx" accept=".xlsx, .csv" required />
          </div>

          <div>
            <h3>Input Monthly Trades Count Up to Now</h3>
          </div>
          <input type="number" name="tradesCount" className="formTextInput form-number-input" placeholder="e.g. there has been 35 trades as of november" required></input>

          <button type="submit" className="btn upload-btn">
            Upload
          </button>
        </form>
      </div>
      <h4 className="error-text">{error}</h4>
    </div>
  );
}

export default MufgFxExcel;
