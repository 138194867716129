import { useState, useEffect, useCallback, useRef } from "react";
import NavBar from "../../common/navbar";
import { baseUrl, getRequestOptions, handleAuth, postRequestOptions } from "../../common/cookie";
import Loader from "../../common/loader";
import { useLocation } from "react-router-dom";
import { CopyableCell, getDateTimeInMongoDBCollectionFormat } from "../../common/functions";
import axios from "axios";
import EditTrade from "./tools/editTrade";
import ShowPosition from "./tools/showPosition";
function ViewTrades() {
  let [trades, setTrades] = useState<any>();
  let [filteredTrades, setFilteredTrades] = useState([]);
  let [updatedTrades, setUpdatedTrades] = useState([]);
  let widerTitles = ["BB Ticker", "ISIN", "Trade App Status", "Broker Email", "Triada-Broker Notes"];

  let tableTitlesTrades: any = [
    "B/S",
    "BB Ticker",
    "Location",
    "Trade Date",
    "Trade Time",
    "Settle Date",
    "Price",
    "Notional Amount",
    "Settlement Amount",
    "Principal",
    "Counter Party",
    "Triada Trade Id",
    "Seq No",
    "ISIN",
    "Cuisp",
    "Currency",
    "Yield",
    "Accrued Interest",
    "Original Face",
    "Comm/Fee",
    "Trade Type",
    "Updated Notional",
    "Id",
    "Front Office Note",
    "Resolved",
  ];
  let unEditableParams = ["Id", "Updated Notional"];
  let query = new URLSearchParams(useLocation().search);
  let issue = query.get("identifier");
  let location = query.get("location");
  let positionInPM = query.get("position");

  let positionObject = null;
  let error = null;

  if (positionInPM) {
    try {
      // First, try to decode using decodeURIComponent
      let jsonString = decodeURIComponent(positionInPM);
      positionObject = JSON.parse(jsonString);
    } catch (e: any) {
      if (e instanceof URIError) {
        // If decodeURIComponent fails, try decoding manually
        try {
          let decodedString = positionInPM.replace(/\+/g, " ").replace(/%(?:2B|3A|3D|3F)/g, decodeChar);
          positionObject = JSON.parse(decodedString);
        } catch (innerError: any) {
          error = "Error parsing position data: " + innerError.message;
        }
      } else {
        error = "Error parsing position data: " + e.message;
      }
    }
  }
  function decodeChar(match: string) {
    switch (match) {
      case "%2B":
        return "+";
      case "%3A":
        return ":";
      case "%3D":
        return "=";
      case "%3F":
        return "?";
      default:
        return match;
    }
  }
  let [maxTrades, setMaxTrades] = useState(issue ? 100000 : 20);

  let [maxTradesBtnDisplay, setMaxTradesBtnDisplay] = useState("block");

  let [tradeInfoDisplay, setTradeInfoDisplay] = useState("none");
  let [tradeInfo, setTradeInfo] = useState<any>({});
  let [authStatus, setAuthStatus] = useState("");

  const [contextMenuState, setContextMenuState] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const contextMenuRef: any = useRef(null);

  const handleContextMenu = useCallback((event: any, trade: any) => {
    event.preventDefault();

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the position of the context menu accounting for the scroll
    const x = event.clientX + scrollX;
    const y = event.clientY + scrollY;

    setTradeInfo(trade);
    setContextMenuState({
      visible: true,
      x: x,
      y: y,
    });
  }, []);

  const handleClick = useCallback((event: any) => {
    // Hide context menu if clicking outside
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setContextMenuState({ visible: false, x: 0, y: 0 });
    }
  }, []);

  updatedTrades = filteredTrades.slice(0, maxTrades);

  let tradeTypeInput = query.get("tradeType") ? query.get("tradeType") : "vcons";

  const [request, setRequestStatus] = useState(false);

  let [tradeType, setTradeType] = useState(tradeTypeInput);
  let url: any = baseUrl + `trades?tradeType=${tradeType}`;
  let today = new Date(new Date().getTime() + 8 * 60 * 60 * 1000).toISOString().slice(0, 10);
  let [fromRange, setFromRange] = useState(0);
  let [toRange, setToRange] = useState(0);

  useEffect(() => {
    fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setTrades(data);
        if (!issue) {
          issue = "";
        }
        if (!location) {
          location = "";
        }
        let filtered: any = data.filter(
          (trade: any) =>
            (((trade["ISIN"] || "").toString().toLowerCase().includes(issue?.toLowerCase()) && (trade["Location"] || "").toString().toLowerCase().includes(location?.toLowerCase())) || (trade["BB Ticker"] || "").toString().toLowerCase().includes(issue?.toLowerCase())) &&
            trade["Location"].toLowerCase().includes(location?.toLowerCase())
        );
        filtered.sort((previous: any, next: any) => new Date(next["Trade Date"]).getTime() - new Date(previous["Trade Date"]).getTime());

        setFilteredTrades(filtered);
        console.log(filtered.length, "gs");
        if (filtered.length <= maxTrades) {
          setMaxTradesBtnDisplay("none");
        } else {
          setMaxTradesBtnDisplay("block");
        }
      });
  }, []);

  async function getTrades(tradeType: string) {
    setRequestStatus(true);
    setTradeType(tradeType);

    let url: any = baseUrl + `trades?tradeType=${tradeType}`;
    await fetch(url, getRequestOptions)
      .then((res) => {
        handleAuth(res.status);
        return res.json();
      })
      .then((data) => {
        setTrades(data);
        if (!issue) {
          issue = "";
        }
        let filtered: any = data;
        filtered.sort((previous: any, next: any) => new Date(next["Trade Date"]).getTime() - new Date(previous["Trade Date"]).getTime());

        setFilteredTrades(filtered);
      });
    setRequestStatus(false);
  }
  function onChangeTradeFilter(event: any) {
    setMaxTrades(10000);
    let searchWords = event.target.value.toLowerCase().split(" ");

    // Filter the trades based on the search words
    let filtered = trades.filter((trade: any) => {
      // Convert trade fields to lowercase strings for comparison
      let bbTicker = (trade["BB Ticker"] || "").toString().toLowerCase();
      let isin = (trade["ISIN"] || "").toString().toLowerCase();
      let location = (trade["Location"] || "").toString().toLowerCase();
      let seqNo = trade["Seq No"].toString().toLowerCase();
      let triadaTradeId = trade["Triada Trade Id"].toString().toLowerCase();
      let tradeDate = new Date(trade["Trade Date"]).getTime();
      let fromRangeTimestamp = 0,
        toRangeTimestamp = Infinity;
      if (fromRange) {
        fromRangeTimestamp = new Date(fromRange).getTime();
        toRangeTimestamp = new Date(toRange).getTime();
      }

      // Check if all search words are included in any of the trade fields
      return searchWords.every((word: any) => (bbTicker.includes(word) || isin.includes(word) || location.includes(word) || seqNo.includes(word) || triadaTradeId.includes(word)) && tradeDate >= fromRangeTimestamp && tradeDate <= toRangeTimestamp);
    });

    filtered.sort((previous: any, next: any) => new Date(next["Trade Date"]).getTime() - new Date(previous["Trade Date"]).getTime());

    setFilteredTrades(filtered);

    if (filtered.length <= maxTrades) {
      setMaxTradesBtnDisplay("none");
    } else {
      setMaxTradesBtnDisplay("block");
    }
  }
  function handleIncreaseLimit(event: any) {
    let updatedMaxLimit = maxTrades + 100;
    let previousFilteredLength = maxTrades;
    setMaxTrades(updatedMaxLimit);

    if (filteredTrades.length < previousFilteredLength) {
      setMaxTradesBtnDisplay("none");
    }
  }

  async function handleEditTrade(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData(event.target);

    // Append the date to the FormData object with the desired key
    formData.append("tradeType", tradeType);

    try {
      let auth: any = await axios.post(baseUrl + "edit-trade", formData, postRequestOptions);

      if (!auth.data.error) {
        // window.location.href = "/view-trades";
        setRequestStatus(false);
        setTradeInfoDisplay("none");
      } else {
        setAuthStatus(auth.data.error);
        setRequestStatus(false);
      }
    } catch (error) {
      setAuthStatus("error");
    }
  }
  async function handleDeleteTrade(event: any) {
    setRequestStatus(true);
    event.preventDefault();

    let formData: any = new FormData();

    // Append the date to the FormData object with the desired key
    formData.append("tradeType", tradeType);
    formData.append("Id", tradeInfo["Id"]);
    formData.append("BB Ticker", tradeInfo["BB Ticker"]);
    formData.append("Location", tradeInfo["Location"]);
    let confirm = window.confirm("Confirm delete trade " + tradeInfo["BB Ticker"] + " on date " + tradeInfo["Trade Date"]);
    if (confirm) {
      try {
        let auth: any = await axios.post(baseUrl + "delete-trade", formData, postRequestOptions);

        if (!auth.data.error) {
          // window.location.href = "/view-trades";
          setRequestStatus(false);
        } else {
          setRequestStatus(false);
          window.alert(`${auth.data.error}`);
        }
      } catch (error) {
        setAuthStatus("error");
      }
    }
    setRequestStatus(false);
  }

  function downloadCSV() {
    // Get the HTML table element
    var table: any = document.getElementById("table-id");

    // Create an empty string for the CSV data
    var csv = "";

    // Loop through each row in the table
    for (var i = 0; i < table?.rows.length; i++) {
      // Loop through each cell in the row
      for (var j = 0; j < table?.rows[i].cells.length; j++) {
        // Add the cell value to the CSV data, surrounded by quotes
        csv += '"' + table?.rows[i].cells[j].textContent + '",';
      }
      // Add a newline character to the end of each row
      csv += "\n";
    }

    // Create a new Blob object with the CSV data
    var blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });

    // Create a new URL object for the Blob object
    var url = URL.createObjectURL(blob);

    // Create a new link element for the download
    var link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `trades-as-of-${today}-${tradeType}.csv`);

    // Trigger the download by clicking the link
    link.click();
  }
  function changeEditTradesRange(event: any) {
    let updatedLogs = trades.filter((trade: any) => {
      let objDate = new Date(trade["Trade Date"]).getTime();
      return objDate >= new Date(fromRange).getTime() && objDate <= new Date(toRange).getTime();
    });
    setMaxTrades(1000000);
    setFilteredTrades(updatedLogs);
    if (updatedLogs.length <= maxTrades) {
      setMaxTradesBtnDisplay("none");
    } else {
      setMaxTradesBtnDisplay("block");
    }
  }

  function cancelTradeInfo(event: any) {
    setTradeInfoDisplay("none");
    setAuthStatus("");
  }

  if (trades == null) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  } else if (request) {
    return (
      <div>
        <NavBar />
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <div className="trades-inputs">
        <div className="col-4 search-container">
          <input
            className="form-control mr-sm-2 filter-trades-search-bar"
            type="search"
            placeholder="Search Trade By BB Ticker or ISIN"
            aria-label="Search"
            onChange={(event) => {
              onChangeTradeFilter(event);
            }}
          />
        </div>
        <div className="calender-input-trades-container">
          <div className="calender-inputs-container">
            <div className="edit-logs-time-picker-container">
              <p className="edit-logs-time-picker-text">From</p>
              <input title="date" type="datetime-local" className="input-calendar edit-logs-time-picker" value={fromRange} onChange={(event: any) => setFromRange(event.target.value)} />
            </div>
            <div className="edit-logs-time-picker-container">
              <p className="edit-logs-time-picker-text">To</p>
              <input title="date" type="datetime-local" className="input-calendar edit-logs-time-picker" value={toRange} onChange={(event: any) => setToRange(event.target.value)} />
            </div>
          </div>
          <button className="btn upload-btn no-flex query-trade-btn" onClick={(event) => changeEditTradesRange(event)}>
            Query
          </button>
        </div>
      </div>
      <div className=" input-trades-search-container">
        <div className="trade-container">
          <button className="btn btn-trades  upload-btn" onClick={(event) => getTrades("vcons")}>
            Vcons
          </button>
          <button className="btn btn-trades  upload-btn" onClick={(event) => getTrades("ib")}>
            Ib
          </button>

          <button className="btn btn-trades  upload-btn" onClick={(event) => getTrades("emsx")}>
            Emsx
          </button>
          <button className="btn btn-trades  upload-btn" onClick={(event) => getTrades("cds_gs")}>
            GS
          </button>
          <button className="btn btn-trades  upload-btn" onClick={(event) => getTrades("canceled_vcons")}>
            Canceled Vcons
          </button>
          <button className="btn btn-trades  upload-btn" onClick={(event) => getTrades("written_blotter")}>
            Written EBLOT
          </button>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          // overflowX: "auto",
        }}
        className="table-container-custom"
        onClick={handleClick}
      >
        <table
          id="table-id"
          style={{
            width: "100%",
          }}
          className="table table-hover table-portfolio table-striped table-trades table-trades-custom"
        >
          <tbody>
            <tr className="sticky-top table-header">
              {tableTitlesTrades.map((title: string, index: number) => (
                <td key={index} className={widerTitles.includes(title) ? "wider-table-cell" : ""}>
                  {title}
                </td>
              ))}
            </tr>

            {updatedTrades.map((trade: any[], index: number) => (
              <tr key={index} className="table-body" onContextMenu={(event) => handleContextMenu(event, trade)}>
                {tableTitlesTrades.map((title: string, index: number) => (
                  <CopyableCell key={index} text={isFinite(trade[tableTitlesTrades[index]]) && trade[tableTitlesTrades[index]] ? trade[tableTitlesTrades[index]].toLocaleString() : trade[tableTitlesTrades[index]]} />
                ))}
              </tr>
            ))}
            {contextMenuState.visible && (
              <div
                ref={contextMenuRef}
                style={{
                  position: "absolute",
                  top: contextMenuState.y,
                  left: contextMenuState.x,
                }}
                className="context-menu-container"
              >
                <div className="context-menu">
                  <div className="context-menue-row">
                    <p className="context-menu-text" onClick={(event) => setTradeInfoDisplay("block")}>
                      Edit Trade
                    </p>
                  </div>
                </div>
                <hr className="hr" />
                <div className="context-menu">
                  <div className="context-menue-row">
                    <p className="context-menu-text" onClick={(event) => handleDeleteTrade(event)}>
                      Delete Trade
                    </p>
                  </div>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
      {positionObject ? <ShowPosition position={positionObject} /> : ""}
      <button className="view-more-button" style={{ display: maxTradesBtnDisplay }} onClick={(event: any) => handleIncreaseLimit(event)}>
        View More Trades
      </button>
      <button id="download-btn" onClick={downloadCSV} className="btn upload-btn">
        Download CSV
      </button>
      <div className="edit-info-container-1" style={{ display: tradeInfoDisplay }} onDoubleClick={(event: any) => cancelTradeInfo(event)}>
        <EditTrade trade={tradeInfo} authStatus={authStatus} unEditableParams={unEditableParams} editTableTitles={tableTitlesTrades} handleEditTrade={handleEditTrade} cancelTradeInfo={cancelTradeInfo} />
      </div>
    </div>
  );
}
export default ViewTrades;
