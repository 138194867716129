import { CopyableCell } from "../../../../common/functions";
import { longTitlesFO, mediumTitlesFO, smallTitlesFO } from "../../tools/titlesSummary";
import { TableFrontOffice } from "../../tools/tool";
import CouponComponent from "./couponComponent";
function UstTableByCoupon(props: any) {
  let table = props.analysis.ustTableByCoupon;
  let params = Object.keys(table).filter((param, index) => !param.includes("Aggregated") && !param.includes("Total"));
  let tableData = new TableFrontOffice("Total");
  let allTotalSum: any = tableData.calculateTotal(table, "Total", "#F9F4D2", null);

  let titlesWithIdents = ["Total", "Coupon Rate", ...props.titles];

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
      }}
      className=" fadeIn table-container-custom"
    >
      <table id="" className="table table-summary">
        <tbody>
          <tr className="sticky-top">
            {titlesWithIdents.map((title: any, titleIndex: any) => {
              // Check if the title is one of the specified strings

              // If you need to keep the table structure consistent, render an empty <td>
              return (
                <td
                  key={titleIndex}
                  className={
                    "copied-text " +
                    (titleIndex < 5 ? " higher-sticky-row " : " lower-sticky-row ") +
                    (longTitlesFO.includes(title) ? " wider-table-cell" : "") +
                    (titleIndex < 5 ? "higher-sticky-row" : "lower-sticky-row") +
                    (longTitlesFO.includes(title) ? " wider-table-cell" : "") +
                    (smallTitlesFO.includes(title) ? " small-table-cell" : "") +
                    (mediumTitlesFO.includes(title) ? " medium-table-cell" : "")
                  }
                >
                  {title}
                </td>
              );
            })}
          </tr>
          <tr className=" sum-row borders" style={{ backgroundColor: `${allTotalSum["Color"]}` }}>
            {titlesWithIdents.map((title: any, index: any) => (
              <CopyableCell key={index} text={isFinite(allTotalSum[title]) && allTotalSum[title] ? allTotalSum[title].toLocaleString() : allTotalSum[title]} index={index} columns={5} type={"summary"} color={allTotalSum["Color"]} disabled={false} />
            ))}
          </tr>
          {params.map((param, index) => (
            <CouponComponent key={index} title={param} param={param} analysis={table} titles={titlesWithIdents} getTrades={props.getTrades} prefixId={props.prefixId + "_0"} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default UstTableByCoupon;
