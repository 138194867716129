function MarketsNAV(props: any) {
  let marketsNAV = props.marketsNAV;
  delete marketsNAV["Total"];
  let marketsNames = Object.keys(marketsNAV).sort((a, b) => {
    return parseFloat(marketsNAV[b]["percentage"]) - parseFloat(marketsNAV[a]["percentage"]); // For all other cases, sort alphabetically
  });

  return (
    <div className="col-12 fact-sheet-sub-container fact-sheet-sub-container-sectors">
      <table id="table-id" className="table table-hover table-fact-sheet table-fact-sheet-stats">
        <tbody>
          <tr className="sticky-top table-header">
            <th>Market Class</th>
            <th>NAV %</th>
          </tr>
          {marketsNames.map((market: string, index: any) => (
            <tr className="fact-sheet-row">
              <td className="fact-sheet-col-grey">{market}</td>
              <td className="fact-sheet-col-white">{(Math.round(marketsNAV[market].percentage * 100) / 100).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default MarketsNAV;
