import { CopyableCell } from "../../../../common/functions";
import { TableFrontOffice } from "../../../frontOffice/tools/tool";
function SectorAssetClass(props: any) {
  let table: any = props.analysis;
  let param = props.param;
  let title = props.title;
  let tableData = new TableFrontOffice(param + " Aggregated");
  let allTotalSum: any = tableData.calculateTotal(table, "", "white", "BB Ticker", title);
  table[param] = [allTotalSum, ...table[param]];

  return (
    <>
      {table[param].map((position: any, index: number) => (
        <tr
          key={index}
          className={"table-body " + (index == 0 ? "sum-row-risk" : "")}
          style={{ backgroundColor: `${position["Color"]}`, borderTop: !(position["L/S"] == "Short" || position["L/S"] == "Long") ? "2px solid grey" : "", borderBottom: !(position["L/S"] == "Short" || position["L/S"] == "Long") ? "2px solid grey" : "" }}
        >
          {props.titles.map((title: any, titleIndex: any) => (
            <CopyableCell
              key={titleIndex}
              text={isFinite(position[title]) && position[title] ? position[title].toLocaleString() : position[title]}
              color={title.includes("Test") ? position[title + " Color"] : ""}
             
              type={"summary"}
            />
          ))}
        </tr>
      ))}
    </>
  );
}
export default SectorAssetClass;
